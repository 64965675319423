export function getAllergens() {
  return [
    { label: 'Celery', value: 'celery' },
    { label: 'Cereals containing gluten', value: 'gluten' },
    { label: 'Crustaceans', value: 'crustaceans' },
    { label: 'Eggs', value: 'eggs' },
    { label: 'Fish', value: 'fish' },
    { label: 'Lupin', value: 'lupin' },
    { label: 'Milk', value: 'milk' },
    { label: 'Molluscs', value: 'molluscs' },
    { label: 'Mustard', value: 'mustard' },
    { label: 'Tree nuts', value: 'treeNuts' },
    { label: 'Peanuts', value: 'peanuts' },
    { label: 'Sesame seeds', value: 'sesameSeeds' },
    { label: 'Soybeans', value: 'soybeans' },
    { label: 'Sulphur dioxide and sulphites', value: 'sulphites' },
  ]
}

export function getDisplayTags() {
  return [
    { label: 'Wine - red', value: 'wine_red' },
    { label: 'Wine - white', value: 'wine_white' },
    { label: 'Wine - rosé', value: 'wine_rosé' },
    { label: 'Wine - sparkling', value: 'wine_sparkling' },
    { label: 'Soft drink', value: 'soft_drink' },
    { label: 'Beer', value: 'beer' },
    { label: 'Gin', value: 'gin' },
    { label: 'Rum', value: 'rum' },
    { label: 'Vodka', value: 'vodka' },
    { label: 'Alcohol free', value: 'alcohol-free' },
    { label: 'Cocktail', value: 'cocktail' },
    { label: 'Featured', value: 'featured' },
  ]
}

export const TagsDisplayLabels = {
  // Allergens
  'celery': 'Celery',
  'gluten': 'Cereals containing gluten',
  'crustaceans': 'Crustaceans',
  'eggs': 'Eggs',
  'fish': 'Fish',
  'lupin': 'Lupin',
  'milk': 'Milk',
  'molluscs': 'Molluscs',
  'mustard': 'Mustard',
  'treeNuts': 'Tree nuts',
  'peanuts': 'Peanuts',
  'sesameSeeds': 'Sesame seeds',
  'soybeans': 'Soybeans',
  'sulphites': 'Sulphur dioxide and sulphites',

  // Display tags
  'wine_red': 'Wine - red',
  'wine_white': 'Wine - white',
  'wine_rosé': 'Wine - rosé',
  'wine_sparkling': 'Wine - sparkling',
  'soft_drink': 'Soft drink',
  'beer': 'Beer',
  'gin': 'Gin',
  'rum': 'Rum',
  'vodka': 'Vodka',
  'alcohol-free': 'Alcohol free',
  'cocktail': 'Cocktail',
  'featured': 'Featured',
}
