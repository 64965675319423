import React, { useCallback, useContext, useEffect, useReducer, useRef, useState, } from 'react'
import { Amplify, Auth } from 'aws-amplify'
import { Switch, Redirect, Route } from "react-router-dom"
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { ToastContainer } from 'react-toastify'
import axios from 'axios'

import awsconfig from './aws-exports.js'
import KitchenViewPage from './components/kitchen-view/KitchenViewPage'
import SideNav from './components/common/SideNav'
import ProductsSearchPage from './components/products/ProductsSearchPage'
import getWindowDimensions from './util/getWindowDimensions'
import MAX_WINDOW_SIZES from './enums/windowSizes'
import UserContext from './components/context/userContext'
import HistoryPage from './components/history/HistoryPage'
import ProductDetailsPage from './components/product-details/ProductDetailsPage'
import UsersListPage from './components/users/UsersListPage'
import NewProductsPage from './components/products/NewProductPage'
import LoginPage from './components/login/LoginPage'
import MenuPage from './components/menus/MenuPage'
import MenuDetails from './components/menus/MenuDetails'
import { AddToMenuContextProvider } from './components/context/addToMenuContext'
import MenuNew from './components/menus/MenuNew.js'
import QRCodes from './components/qr-codes/QRCodes.js'
import CreateQR from './components/qr-codes/CreateQr.js'
import StatsPage from './components/stats/StatsPage.js'
import { LocationContextProvider } from './components/context/locationContext'
import Loading from './components/common/Loading.js'
import config from './config'
import PreorderPage from "./components/preorder/PreorderPage"
import userContext from './components/context/userContext'

import './assets/scss/app.scss'
import 'react-toastify/dist/ReactToastify.css'
import EventDetailsPage from './components/events/details/EventDetails.page.js'
import LocationsPage from "./components/locations/LocationsPage";
import { DialogsContextProvider } from './components/context/dialogs/DialogsContext.js'
import DialogContainer from './components/context/dialogs/DialogsContainer.js'

Amplify.configure(awsconfig)

function App() {
  const reducer = (state, action) => {
    if (action.type === 'setLocationIds') {
      //Expected format: location-${locationId}-${groupType} eg location-123-users
      //default locationId to first returned location. Probably need to handle an error here ie lg is empty or contains a group like "location000" (no hyphen)
      const locationIds = action.payload.filter(group => group.toLowerCase().startsWith('location')).map(g => g.split('-')[1])

      const currentActiveLocation = () => {
        if (locationIds.length === 1) return locationIds[0]
        if (window.location.pathname === '/') return null

        const potentialLocation = window.location.pathname.split('/')[1]
        if (locationIds.includes(potentialLocation)) return potentialLocation

        return null
      }

      return {
        locationIds,
        selectedLocationId: currentActiveLocation()
      }
    }

    if (action.type === 'setSelectedLocationId') {
      window.location.href = `${window.location.origin}/${action.payload}/orders`
      return { ...state, selectedLocationId: action.payload }
    }
  }

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [locationState, dispatch] = useReducer(reducer, { locationIds: [], selectedLocationId: null })

  function setLoggedInUser() {
    Auth.currentAuthenticatedUser().then(async () => {
      setIsLoggedIn(true)

      const sesh = await Auth.currentSession()
      const groups = sesh.getIdToken().payload['cognito:groups']
      if (groups !== undefined) {
        dispatch({ type: 'setLocationIds', payload: groups })
      }

      const userDetails = await axios.get(`${config.baseOrderUrl}/users/self`, { headers: { "Authorization": sesh.getIdToken().getJwtToken() } })
      setUser(userDetails.data)
      setIsLoading(false)
    }).catch(() => {
      setIsLoggedIn(false)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    setLoggedInUser()
  }, [])

  if (isLoading) return <Loading fullscreen />
  if (!isLoggedIn) return <LoginPage setLoggedInUser={setLoggedInUser} />
  return (
    <UserContext.Provider value={{
      user,
      locationIds: locationState.locationIds,
      selectedLocationId: locationState.selectedLocationId,
      setSelectedLocationId: (locationId) => dispatch({ type: 'setSelectedLocationId', payload: locationId })
    }}>
      <Body />
    </UserContext.Provider>
  )
}

const deviceSizeMap = { MOBILE: 'mobile', TABLET: 'tablet', DESKTOP: 'desktop' }

const Body = () => {
  const [isSideNavCollapsed, setIsSideNavCollapsed] = useState(shouldSideNavBeCollapsed())
  const [deviceSize, setDeviceSize] = useState(deviceSizeMap.DESKTOP)
  const sideNavRef = useRef()
  const { selectedLocationId } = useContext(userContext)

  function shouldSideNavBeCollapsed() {
    const { width } = getWindowDimensions()
    if (width > MAX_WINDOW_SIZES.TABLET) {
      return false
    }

    return true
  }

  const updateDeviceSize = useCallback(() => {
    const { width } = getWindowDimensions()
    let size = deviceSizeMap.MOBILE

    if (width > MAX_WINDOW_SIZES.MOBILE_LANDSCAPE) {
      size = deviceSizeMap.TABLET
    }

    if (width > MAX_WINDOW_SIZES.TABLET) {
      size = deviceSizeMap.DESKTOP
    }

    setDeviceSize(size)
    setIsSideNavCollapsed(shouldSideNavBeCollapsed())
  }, [])

  useEffect(() => {
    // Auto collapse the menu for mobile devices
    if (deviceSize === deviceSizeMap.MOBILE || deviceSize === deviceSizeMap.TABLET) {
      setIsSideNavCollapsed(true)
    }
  }, [deviceSize])

  useEffect(() => {
    // Collapse the menu is clicked outside of the ref
    if (!isSideNavCollapsed && getWindowDimensions().width < MAX_WINDOW_SIZES.TABLET) {
      function handleClickOutside(event) {
        if (sideNavRef.current && !sideNavRef.current.contains(event.target)) {
          setIsSideNavCollapsed(true)
        }
      }

      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }
  }, [sideNavRef, isSideNavCollapsed])

  useEffect(() => {
    // Check if the screen has been resized
    window.addEventListener('resize', updateDeviceSize)
    return () => {
      // Unbind the event listener on clean up
      window.addEventListener('resize', updateDeviceSize)
    }
  }, [updateDeviceSize])

  const overlayClass = classNames({
    'display-none': getWindowDimensions().width > MAX_WINDOW_SIZES.TABLET,
    'nav-overlay': !isSideNavCollapsed,
  })

  function toggleNav() {
    setIsSideNavCollapsed(!isSideNavCollapsed)
  }

  const MobileNavigationManager = () => {
    const { pathname } = useLocation()
    const currentPath = useRef(pathname)

    useEffect(() => {
      if (deviceSize === deviceSizeMap.MOBILE && currentPath.current !== pathname) {
        currentPath.current = pathname
        setIsSideNavCollapsed(true)
      }
    }, [pathname])

    return null
  }

  if (!selectedLocationId) return <LocationsPage />

  if (selectedLocationId && window.location.pathname === '/') {
    window.location.href = `${window.location.origin}/${selectedLocationId}/orders`
  }

  // As we've added /:locationId/ to the path this will redirect older saved URLs instead of crashing
  if (
    selectedLocationId &&
    !window.location.pathname.startsWith(`/${selectedLocationId}/`) &&
    !window.location.pathname.startsWith(`/sign-out`) &&
    !window.location.pathname.startsWith(`/locations`)
  ) {
    window.location.href = `${window.location.origin}/${selectedLocationId}/orders`
  }

  return (
    <LocationContextProvider>
      <AddToMenuContextProvider>
        <DialogsContextProvider>
          <DialogContainer />
          <SideNav sideNavRef={sideNavRef} isCollapsed={isSideNavCollapsed} />
          <div>
            <div className={overlayClass}></div>
            <MobileNavigationManager />
            <Switch>
              <Route exact path={`/:locationId/orders`}>
                <KitchenViewPage toggleNav={toggleNav} />
              </Route>
              <Route exact path={`/:locationId/history`}>
                <HistoryPage toggleNav={toggleNav} />
              </Route>
              <Route exact path={`/:locationId/preorders`}>
                <PreorderPage toggleNav={toggleNav} />
              </Route>
              <Route exact path={`/:locationId/products/new`}>
                <NewProductsPage toggleNav={toggleNav} />
              </Route>
              <Route exact path={`/:locationId/products/:id`}>
                <ProductDetailsPage toggleNav={toggleNav} />
              </Route>
              <Route exact path={`/:locationId/products`}>
                <ProductsSearchPage toggleNav={toggleNav} />
              </Route>
              <Route exact path={`/:locationId/users`}>
                <UsersListPage toggleNav={toggleNav} />
              </Route>
              <Route exact path={`/:locationId/menus/new`}>
                <MenuNew toggleNav={toggleNav} />
              </Route>
              <Route exact path={`/:locationId/menus/:id`}>
                <MenuDetails toggleNav={toggleNav} />
              </Route>
              <Route exact path={`/:locationId/menus`}>
                <MenuPage toggleNav={toggleNav} />
              </Route>
              <Route exact path={`/:locationId/events/:id`}>
                <EventDetailsPage toggleNav={toggleNav} />
              </Route>
              <Route exact path={`/:locationId/qr-codes/new`}>
                <CreateQR toggleNav={toggleNav} />
              </Route>
              <Route exact path={`/:locationId/qr-codes`}>
                <QRCodes toggleNav={toggleNav} />
              </Route>
              <Route exact path={`/:locationId/stats`}>
                <StatsPage toggleNav={toggleNav} />
              </Route>
              <Route exact path="/locations">
                <LocationsPage />
              </Route>
              <Redirect from="/" to={`/${selectedLocationId}/orders`} />
            </Switch>
          </div>

          <ToastContainer position="bottom-right" pauseOnHover autoClose={3000} />
        </DialogsContextProvider>
      </AddToMenuContextProvider>
    </LocationContextProvider>
  )
}

export default App
