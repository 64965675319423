import { createContext } from 'react'

const UserContext = createContext({
    user: null,
    locationIds: [],
    selectedLocationId: null,
    setSelectedLocationId: () => {},
})

export default UserContext
