import { faUtensils, faCalendarDay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { useRef, useState } from 'react'
import QRCode from "react-qr-code"

const PDFPage = ({ qr }) => {
  const [isPrinting, setIsPrinting] = useState(false)
  const areaToPrint = useRef()

  function print() {
    setIsPrinting(true)
    html2canvas(areaToPrint.current)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'JPEG', 0, 0);
        pdf.save("download.pdf");
        setIsPrinting(false)
      })
  }

  if (!qr) return null

  return (
    <div className="modal-qr">
      <div className="modal-qr" ref={areaToPrint}>
        <div className="header-svg">
          <FontAwesomeIcon icon={qr?.menuType?.toLowerCase() === 'event' ? faCalendarDay : faUtensils } />
        </div>

        <div className="location">
          <span className="branch">{`${qr?.menuType?.toLowerCase() === 'event' ? qr.menuName : ''}`}</span>
          <span className="table">{qr.area} {qr?.areaReference ?? ''}</span>
        </div>

        <div className="qr-code">
          <QRCode value={`https://order.servd.uk/checkin?orderDestination=${qr.orderDestinationId}`} />
          <p>If you do not have a QR code scanner, visit <span className="primary bold">www.order.servd.uk</span> and enter this code:</p>
          {qr.orderDestinationId.split('').map(c => {
            return <span className="qr-id-letter">{c}</span>
          })}
        </div>
      </div>
      {isPrinting ? 'Downloading...' : <button className="button-secondary" onClick={print}>Download PDF</button>}
      <div className='margin-top'>
        <label>Direct link</label>
        <span>{`https://order.servd.uk/checkin?orderDestination=${qr.orderDestinationId}`}</span>
      </div>
    </div>
  )
}

export default PDFPage
