import React, { useContext, useEffect, useState } from 'react'
import Timer from './Timer'

import UserContext from '../context/userContext'
import CardMenu from "./CardMenu";

const Card = ({ order, updateOrder }) => {
  const { user } = useContext(UserContext)
  const [processing, setProcessing] = useState(false)
  const [categoriesToShow, setCategoriesToShow] = useState()

  useEffect(() => {
    if (!user?.settings?.categoriesToShow || !Array.isArray(user.settings.categoriesToShow)) {
      return
    }
    setCategoriesToShow(user.settings.categoriesToShow.map(i => i.toLowerCase()))
  }, [user])

  if (typeof order.items !== 'object' || !categoriesToShow) {
    return null
  }

  const completeOrder = () => {
    setProcessing(true)
    updateOrder(order.orderId)
    setProcessing(false)
  }

  const basket = Object.values(order.items).map(item => {
    item.kitchenCategory = item.product?.category?.kitchenCategory.toLowerCase()
    return item
  })

  if (!basket.some(item => categoriesToShow.includes(item.kitchenCategory))) {
    return null
  }

  const BodyElement = ({ basket }) => {
    const itemsByCategory = basket.reduce((acc, item) => {
      //ignore other categories not listed above, eg drinks
      if (!categoriesToShow.includes(item.kitchenCategory)) {
        return acc
      }

      acc[item.kitchenCategory] ? acc[item.kitchenCategory].push(item) : acc[item.kitchenCategory] = [item]
      return acc
    }, {})
    return (
      Object.entries(itemsByCategory).map(([category, items]) => {
        return (
          <div className="card-body-section" key={category}>
            <span className="card-body-section-title">{category}</span>
            <ul>
              {items.map(item => {
                const displaySize = item.product.sizes.length > 1 ? `(${item.size.name})` : "";
                return (
                  <li key={item.product.name}>
                    <span>({item.quantity}x) {item.product.name} {displaySize}</span>
                  </li>
                )
              })}
            </ul>
          </div>
        )
      })
    )
  }

  return (
    <div className="card" data-cy={`order-${order.orderId}`}>
      <div className="card-heading">
        <span className="card-title" data-cy="order-title">{order.destination}</span>
        <span className="card-menu">
          <CardMenu order={order}  processing={processing} setProcessing={setProcessing}/>
        </span>
        <span className="card-timer">
          <Timer orderCreateTime={order.orderPlacedDateTimeUtc}/>
        </span>
      </div>

      <div className="card-body">
        <BodyElement basket={basket} />
      </div>

      <div className="card-action">
        <button className="full-width" disabled={processing} onClick={completeOrder}>Complete</button>
      </div>
    </div>
  )
}

export default Card
