import { faPrint, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify'

import { QrContext } from './QrContext'
import Loading from "../common/Loading"

const ListOfQRs = ({ openModal, filter }) => {
  const [qrsToShow, setQrs] = useState([])
  const { qrsForLocation, isLoading } = useContext(QrContext)

  useEffect(() => {
    if (!filter || Object.keys(filter).length === 0 || filter?.location.toLowerCase() === 'all') {
      setQrs(qrsForLocation)
    } else if (filter.location) {
      setQrs(qrsForLocation.filter(q => q?.area?.toLowerCase() === filter?.location?.toLowerCase()))
    }
  }, [filter, qrsForLocation])

  const tableColumns = [
    {
      name: 'QR codes',
      selector: 'id',
      cell: row => <TableLine row={row} openModal={openModal} />
    },
  ]

  if (isLoading) return <Loading />
  if (!qrsToShow || qrsToShow.length === 0) return <p data-cy="no-qrs-message">There are currently no active QR codes for this location.</p>
  return (
    <DataTable
      columns={tableColumns}
      data={qrsToShow}
      className="qr-table"
    />
  )
}

const TableLine = ({ row, openModal }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { deleteQr } = useContext(QrContext)

  function getName() {
    if (!row.area) return 'All areas'
    else return `${row.area}${row.areaReference ? ` - ${row.areaReference}` : ''}`
  }

  async function removeQr() {
    setIsLoading(true)
    const res = await deleteQr(row.orderDestinationId)
    if (res.status === 200) {
      toast.success('QR code removed.')
    } else {
      toast.error('Could not remove QR code. Try again.')
    }
  }

  const Skeleton = () => (
    <div className="qr-row">
      <div className="area-wrapper"><span className="skeleton skeleton-label"></span></div>
      <div>
        <button className="skeleton skeleton-button--icon-only"></button>
        <button className="skeleton skeleton-button--icon-only"></button>
      </div>
    </div>
  )

  if (isLoading) return <Skeleton />

  return (
    <div className="qr-row" data-cy={`qr-${row.orderDestinationId}`}>
      <div className="area-wrapper"><span className={`label area--${row.area}`}>{getName()}</span></div>
      <div>
        <button className="button--red" data-tip="Remove QR code" onClick={removeQr} data-cy="remove-qr"><FontAwesomeIcon icon={faTrash} /></button>
        <button data-tip="Print QR code" onClick={() => openModal(row)}><FontAwesomeIcon icon={faPrint} /></button>
      </div>
    </div>
  )
}

export default ListOfQRs
