import Select from 'react-select'
import useDialogsContext from '../DialogsContext'

import './dialogs.dialog.scss'

const EventFilterSalesItemDialog = () => {
    const { cancelDialogJourney, onSuccessCallback, data } = useDialogsContext()

    const handleSuccess = (e) => {
        onSuccessCallback(e)
        cancelDialogJourney()
    }

    return (
        <section className="generic-dialog">
            <h2>Item filter</h2>
            <p>Select an item you'd like to filter by:</p>
            <Select
                options={data?.eventItems}
                onChange={handleSuccess}
            />
            <button
                className='margin-top button button-secondary'
                onClick={cancelDialogJourney}
            >
                Cancel
            </button>
        </section>
    )
}

export default EventFilterSalesItemDialog
