import { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { LocationClient } from "@food2room/clients"
import { toast } from 'react-toastify'

import { getIdToken } from '../../../util/auth'
import userContext from '../../context/userContext'

const DeleteEvent = ({ id }) => {
  const [numberPressed, setNumberPressed] = useState(0)
  const [buttonText, setButtonText] = useState('Delete event')
  const [isDeleting, setIsDeleting] = useState(false)

  const history = useHistory()

  const { selectedLocationId } = useContext(userContext)

  async function deleteEvent() {
    if (numberPressed === 1) {
      setIsDeleting(true)
      try {
        await LocationClient.deleteMenu(selectedLocationId, id, await getIdToken())
        toast.success('Event deleted.')
        history.push(`/${selectedLocationId}/menus`)
      } catch (error) {
        console.log(error)
        toast.error('Could not delete this event. Try again.')
        setIsDeleting(false)
      }
    } else {
      toast.info('Click the button again to confirm deletion.')
      setButtonText(`Click again to confirm deletion`)
      setNumberPressed(numberPressed + 1)
    }
  }

  return <button onClick={deleteEvent} className={numberPressed > 0 ? 'button-warning' : ''} disabled={isDeleting}>{buttonText}</button>
}

export default DeleteEvent
