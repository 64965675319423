import { useContext, useState } from "react"
import Modal from 'react-modal'

import EventActive from './EditActive'
import DeleteEvent from './EditDeleteEvent'
import EventDescription from './EditDescription'
import EventTextValue from './EventTextValue'
import { EventContext } from "./EventContext"
import PDFPage from "../../qr-codes/PDFPage"
import EventHeroImage from "./EditHeroImage"
import CustomerInformation from "./CustomerInformation"

const EditDetails = () => {
  const {
    event,
    updateEventName,
    updateEventDescription,
    updateEventActive,
    updateEventContactAddress,
    updateEventContactName,
    updateEventContactPhone,
    updateEventContactEmail,
    updateEventEmailMessage,
  } = useContext(EventContext)

  if (!event || !event.eventDetails) return null

  const getDirectLinkToEvent = () => `https://order.servd.uk/checkin?orderDestination=${event.orderDestinationId}`

  return (
    <>
      <div className='event-details-description event-details-section__wrapper'>
        <h2 className='margin-bottom'>Availability</h2>
        <EventActive isActive={event.active} update={updateEventActive} />

        <h2 className='margin-bottom margin-top--2'>Accessing the event</h2>
        <div>
          <label>Direct link to event</label>
          <p><a href={getDirectLinkToEvent()} target="_blank" rel="noreferrer">{getDirectLinkToEvent()}</a></p>
        </div>
        <GetQRLink event={event} />

        <h2 className='margin-bottom margin-top--2'>Event details</h2>
        <EventTextValue value={event.name} label="Title" update={(newTitle) => updateEventName(newTitle)} />
        <EventDescription description={event.description} label="Description" update={(newDescription) => updateEventDescription(newDescription)} />
        <EventHeroImage heroImage={event?.image?.coverUrl} eventUrl={getDirectLinkToEvent()}  />
        <EventDescription description={event.emailMessage} label="Order confirmation email message" update={(newValue) => updateEventEmailMessage(newValue)} />

        <h2 className='margin-bottom margin-top--2'>Event contact details</h2>
        <EventTextValue value={event.eventDetails.eventLocation} label="Event address" update={(newValue) => updateEventContactAddress(newValue)} />
        <EventTextValue value={event.eventDetails.contactName} label="Event contact name" update={(newValue) => updateEventContactName(newValue)} />
        <EventTextValue value={event.eventDetails.contactPhone} label="Phone number" update={(newValue) => updateEventContactPhone(newValue)} />
        <EventTextValue value={event.eventDetails.contactEmail} label="Email address" update={(newValue) => updateEventContactEmail(newValue)} />

        <CustomerInformation customDataFields={event?.customDataFields} />

        <h2 className='margin-bottom margin-top--2'>Delete event</h2>
        <p className="p--info">
          <b>Important</b><br />
          Deleting an event cannot be undone.
        </p>
        <DeleteEvent id={event.id} />
      </div>


    </>
  )
}

const GetQRLink = ({ event }) => {
  const [isQrModalOpen, setIsQrModalOpen] = useState(false)
  const [qrToPrint, setQrToPrint] = useState()

  function openModal() {
    setQrToPrint({
      menuType: event.menuType,
      menuName: event.name,
      orderDestinationId: event.orderDestinationId,
    })
    setIsQrModalOpen(true)
  }

  function closeModal() {
    setQrToPrint(null)
    setIsQrModalOpen(false)
  }

  return (
    <>
      <div>
        <label>Get printable QR code</label>
        <button className="margin-top" onClick={openModal}>Click to view QR</button>
      </div>

      <Modal
        isOpen={isQrModalOpen}
        ariaHideApp={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <PDFPage qr={qrToPrint} />
      </Modal>
    </>
  )
}

export default EditDetails
