import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { AddToMenuContext } from '../context/addToMenuContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Card from '../common/Card'
import UserContext from '../context/userContext'

const ProductsCard = ({ product }) => {
  const { menu, addToMenu, isAddingToMenu, isProductInMenu } = useContext(AddToMenuContext)
  const [isLoading, setIsLoading] = useState(true)
  const [isInMenu, setIsInMenu] = useState(false)
  const [isAddingProductToMenu, setIsAddingProductToMenu] = useState(false)
  const { selectedLocationId } = useContext(UserContext)

  useEffect(() => {
    if (isAddingToMenu()) {
      if (isProductInMenu(product.id)) {
        setIsInMenu(true)
      }
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  async function addProductToMenu() {
    setIsAddingProductToMenu(true)
    const res = await addToMenu(product.id, product.category.kitchenCategory)
    if (res) {
      setIsInMenu(true)
      toast.success(`${product.name} added.`)
    } else {
      toast.error(`Could not add product. Try again.`)
    }
    setIsAddingProductToMenu(false)
  }

  const HeaderRightComponent = () => {
    if (!product.available) {
      return <span className='label warning'>Out of stock</span>
    }

    // TODO - add low stock warnings

    return null
  }

  const ActionButtons = () => {
    if (menu) {
      if (isInMenu) {
        return (
          <button className='button-secondary button-card margin-left' disabled>
            Is in menu
          </button>
        )
      } else {
        return (
          <button
            className='button-secondary button-card margin-left'
            onClick={!isAddingProductToMenu ? addProductToMenu : null}
            disabled={isAddingProductToMenu}
          >
            Add to menu <FontAwesomeIcon icon={faPlus} />
          </button>
        )
      }
    }

    return null
  }

  // TODO - skeleton
  if (isLoading) return null

  return (
    <Card
      key={product.id}
      title={product.name}
      subtitle={product.description}
      tag={product.category.displayCategory}
      viewLink={`/${selectedLocationId}/products/${product.id}`}
      headerRightComponent={<HeaderRightComponent />}
      actionButtons={<ActionButtons />}
    >
      <div>
        {product?.sizes?.length} size{product?.sizes?.length === 1 ? null : 's'}
      </div>
    </Card>
  )
}

export default ProductsCard
