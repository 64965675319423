import React, { useCallback, useEffect, useState, useContext } from 'react'
import useWebSocket from 'react-use-websocket'
import Switch from 'react-switch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import Card from './Card'
import UserContext from '../context/userContext'
import Loading from '../common/Loading'
import { OrderClient } from "@food2room/clients";
import { getIdToken } from '../../util/auth'
import config from '../../config'

function useForceUpdate() {
  const [, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}

const KitchenViewPage = ({ toggleNav }) => {
  const socketUrl = `${config.orderWebsocketsUrl}`
  const zone = 'kitchen'

  const forceUpdate = useForceUpdate()

  const { selectedLocationId } = useContext(UserContext)

  const [orders, setOrders] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [token, setToken] = useState(null)

  const { sendJsonMessage } = useWebSocket(socketUrl, {
    onMessage: (message) => handleWebsocketEvent(message),
    queryParams: {
      Authorizer: token,
      locationId: selectedLocationId
    },
    shouldReconnect: (_closeEvent) => true,
  })

  const orderEvents = {
    "order-update": (eventData) => processOrder(eventData)
  }

  useEffect(() => {
    OrderClient.setBaseUrl(config.baseOrderUrl)
    getIdToken().then(token => setToken(token))
  }, [])

  useEffect(() => {
    if (!token) { return }
    OrderClient.getOrdersForLocation(selectedLocationId, token, true)
      .then((activeOrders) => {
        if (activeOrders && activeOrders.length > 0) {
          const zoneActiveOrders = activeOrders.filter(order => !(order.hasOwnProperty(`${zone}CompletedDateTimeUtc`)))

          zoneActiveOrders.sort((a, b) => {
            if (!a.hasOwnProperty("orderPlacedDateTimeUtc")) {
              return -1
            }
            return a.orderPlacedDateTimeUtc - b.orderPlacedDateTimeUtc
          })
          setOrders(zoneActiveOrders)
        } else {
          setOrders(activeOrders)
        }
      })
      .finally(() => setIsLoading(false))
  }, [token, selectedLocationId])

  function handleWebsocketEvent(data) {
    const incomingOrderEvent = JSON.parse(data.data);
    const { eventName, eventData } = incomingOrderEvent

    if (!orderEvents.hasOwnProperty(eventName)) {
      console.error(`Event [${eventName}] not recognised.`)
      return;
    }

    if (eventData.hasOwnProperty("locationId") && eventData.locationId !== selectedLocationId) {
      return
    }

    orderEvents[eventName](eventData)
  }

  function processOrder(updatedOrder) {

    if (updatedOrder.hasOwnProperty(`${zone}CompletedDateTimeUtc`) || updatedOrder.orderStatus === 'CANCELLED') {
      setOrders(orders.filter(order => order.orderId !== updatedOrder.orderId))
      return
    }

    setOrders([...orders, { ...updatedOrder }])
  }

  function markOrderAsZoneComplete(orderId) {
    setOrders(orders.filter(order => order.orderId !== orderId))
    sendOrderZoneCompleteUpdate(orderId, zone)
  }

  function sendOrderZoneCompleteUpdate(orderId, zone) {
    sendWssMessage({
      action: "update-orderStatusForZone",
      orderId,
      locationId: selectedLocationId,
      zone,
      orderStatus: "complete",
    })
  }

  const sendWssMessage = useCallback((messageJson) =>
    sendJsonMessage(messageJson), [sendJsonMessage]);

  const Body = () => {
    if (!orders || isLoading || !token) {
      return <Loading fullscreen={true} />
    }

    return (
      <div className="page">
        <div className="header">
          <FontAwesomeIcon icon={faBars} id="btn-mobile-nav" onClick={() => toggleNav()} />

          <div className="float-right">
            <span className="switch-group margin-right">
              <TimerToggle forceUpdate={forceUpdate} />
            </span>

          </div>
        </div>

        <div className="body">
          {orders.length === 0 ? <p>There are no orders in your queue</p> : null}
          <div className="flex-grid flex-grid--column-dir flex-grid--fullscreen margin-top">
            {orders.map(order => {
              return <div key={order.orderId} className="col"><Card order={order} updateOrder={markOrderAsZoneComplete} /></div>
            })}
          </div>
        </div>
      </div>
    )
  }

  return <Body />
}

const TimerToggle = ({ forceUpdate }) => {
  const [checked, setChecked] = useState(true)
  const handleChange = nextChecked => {
    localStorage.setItem('showOrderTimer', nextChecked)
    setChecked(nextChecked)
    forceUpdate()
  }

  useEffect(() => {
    const currentState = localStorage.getItem('showOrderTimer')
    if (currentState?.toLowerCase() === 'false') setChecked(false)
  }, [])

  return (
    <>
      <Switch
        checked={checked}
        uncheckedIcon={false}
        checkedIcon={false}
        height={20}
        width={35}
        onColor={"#7ca870"}
        onChange={handleChange}
      />
      <span className="is-active">Timer</span>
    </>
  )
}

export default KitchenViewPage
