import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { useContext, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import Switch from 'react-switch'

import Loading from '../common/Loading'
import { QrContext, QrContextProvider } from './QrContext'
import ErrorMessage from '../common/ErrorMessage'
import UserContext from '../context/userContext'

const CreateQR = ({ toggleNav }) => {
  const { selectedLocationId } = useContext(UserContext)

  return (
    <QrContextProvider>
      <div className="page">
        <div className="header">
          <FontAwesomeIcon icon={faBars} id="btn-mobile-nav" onClick={() => toggleNav()} className="margin-right" />
          <Link to={`/${selectedLocationId}/qr-codes`}><FontAwesomeIcon icon={faArrowLeft} /> Back to QRs</Link>
        </div>

        <div className="body">
          <span className="title">New QR code</span>
          <span className="sub-title">Enter the details of the qr code</span>
          <CreateForm />
        </div>
      </div>
    </QrContextProvider>
  )
}

const CreateForm = () => {
  const [hasSelectedArea, setHasSelectedArea] = useState(false)
  const [isCreatingMultipleNumbers, setIsCreatingMultipleNumbers] = useState(false)

  const areaRef = useRef()
  const numberRef = useRef()
  const numberEndRef = useRef()

  const { createQr, isLoading, error } = useContext(QrContext)

  function selectArea(e) {
    if (e.toLowerCase() === 'all') setHasSelectedArea(false)
    else setHasSelectedArea(true)
  }

  async function createQrCode() {
    function getArea() {
      if (areaRef.current.value.toLowerCase() === 'all') return null
      else return areaRef.current.value
    }

    function getNumber() {
      if (!numberRef.current.value || numberRef.current.value.trim().length === 0 || areaRef.current.value.toLowerCase() === 'all') return null
      else return numberRef.current.value
    }

    const res = await createQr(getArea(), getNumber())
    if (res?.orderDestinationId) {
      toast.success('QR code created.')
      areaRef.current.value = "all"
      numberRef.current.value = ""
      setHasSelectedArea(false)
    } else {
      toast.error('Could not create QR code.')
    }
  }

  const NumberInput = () => {
    if (isCreatingMultipleNumbers) {
      return (
        <>
          <div className="label label--wip warning">Creating multiple is a work in progress</div>
          <div className="multiple-numbers-wrapper">
            <input type="number" id="tNumber" name="tNumber" disabled={!hasSelectedArea || isLoading} ref={numberRef} placeholder="Start" />
            <input type="number" id="tNumber" name="tNumber" disabled={!hasSelectedArea || isLoading} ref={numberEndRef} placeholder="End" />
          </div>
        </>
      )
    }

    return <input type="text" id="tNumber" name="tNumber" disabled={!hasSelectedArea || isLoading} ref={numberRef} />
  }

  return (
    <form className="new-product-form margin-top">
      <ErrorMessage error={error} />

      <p className="p--info">
        <b>Area</b><br />
        Selecting an area will restrict which menus customers will be allowed to order from after scanning the QR code.
      </p>

      <label htmlFor="areas">Choose an area:</label>
      <select name="areas" id="areas" onChange={(e) => selectArea(e.target.value)} disabled={isLoading} ref={areaRef}>
        <option value="all">All areas</option>
        <option value="restaurant">Restaurant</option>
        <option value="bar">Bar</option>
        <option value="room">Room</option>
      </select>

      <p className="p--info">
        <b>Table/room number</b><br />
        Selecting a number will allow the ordering website to pre-populate the table/room number for the customer.<br /><br />
        You have to select an area to be able to specify a table/room number.<br /><br />
        Toggling to multiple will create individual QR codes for each of the number.
      </p>

      <div className="number-label-wrapper">
        <label htmlFor="tNumber">Table/room number:</label>
        <MultipleNumbersToggle checked={isCreatingMultipleNumbers} setChecked={setIsCreatingMultipleNumbers} disabled={!hasSelectedArea} />
      </div>
      <NumberInput />

      {isLoading ? <Loading /> : <button onClick={createQrCode}>Create QR code</button>}
    </form>
  )
}

const MultipleNumbersToggle = ({ checked, setChecked, disabled }) => {
  const handleChange = nextChecked => {
    setChecked(nextChecked)
  }

  return (
    <div className="switch-wrapper">
      <Switch
        checked={checked}
        uncheckedIcon={false}
        checkedIcon={false}
        height={20}
        width={35}
        onColor={"#7ca870"}
        onChange={handleChange}
        disabled={disabled}
      />
      <span>{checked ? 'Multiple' : 'Single'}</span>
    </div>
  )
}

export default CreateQR
