import { faAdd, faLock, faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useContext } from "react"
import useDialogsContext, { DIALOG_JOURNEYS } from "../../context/dialogs/DialogsContext"
import { EventContext } from "./EventContext"

const CustomerInformation = ({ customDataFields = [] }) => {
  const { startDialogJourney } = useDialogsContext()
  const { addEventCustomDataField, deleteEventCustomDataField } = useContext(EventContext)

  return (
    <section className="customer-info">
      <h2 className='margin-bottom margin-top--2'>
        <span>Customer information</span>
        <button
          onClick={() => startDialogJourney(DIALOG_JOURNEYS.EVENT_ADD_DATA_FIELD, addEventCustomDataField)}
        >
          <FontAwesomeIcon icon={faAdd} />
        </button>
      </h2>

      <ul className="customer-info-list">
        <li><span><strong>Name</strong>, required</span><FontAwesomeIcon icon={faLock} /></li>
        <li><span><strong>Email</strong>, required</span><FontAwesomeIcon icon={faLock} /></li>
        <li><span><strong>Phone</strong>, required</span><FontAwesomeIcon icon={faLock} /></li>
        <li><span><strong>Notes</strong>, required</span><FontAwesomeIcon icon={faLock} /></li>
        {customDataFields.map(f => {
          return (
            <li>
              <span>
                <strong>{f.label}</strong>
                {f.isRequired ? ', required' : null}
              </span>
              <div className="customer-info-list-icons">
                {/* <button><FontAwesomeIcon icon={faPencilAlt} /></button> */}
                <button
                  onClick={() => startDialogJourney(DIALOG_JOURNEYS.EVENT_DELETE_DATA_FIELD, deleteEventCustomDataField, f)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default CustomerInformation
