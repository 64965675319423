import { useContext, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { EventContext } from './EventContext'
import { toast } from 'react-toastify'

const EventHeroImage = ({ heroImage, eventUrl }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const { updateEventHeroImage } = useContext(EventContext)

  const inputRef = useRef()

  async function uploadImage() {
    setIsUpdating(true)

    if (!inputRef.current.files[0]) {
      toast.error('Select a file to upload.')
      setIsUpdating(false)
      return
    }

    try {
      await updateEventHeroImage(inputRef.current.files[0])
      toast.success('Image uploaded.')
      setIsUpdating(false)
    } catch (error) {
      // TODO - improve this error, toasts are easy to miss
      if (error.message === 'Unsupported file format.') {
        toast.error('Unsupported file format.')
        setIsUpdating(false)
        return
      } else {
        toast.error('Failed to upload the image. Please try again.')
        setIsUpdating(false)
        return
      }
    }
  }

  return (
    <div>
      <div className='event-details__heading-with-edit'>
        <label>Banner image</label>
        <button onClick={() => setIsEditing(!isEditing)} title='edit'><FontAwesomeIcon icon={faPencilAlt} /></button>
      </div>
      <p className="p--info">
        <b>Note</b><br />
        The uploaded image will usually not be displayed in full - this aims to serve as a background image, not for providing information to the customers.
        <br /><br />
        You can see what the image looks like by going to the <a href={eventUrl} target="_blank" rel="noreferrer">sales page</a>.
      </p>
      {isEditing && <UploadImage inputRef={inputRef} uploadImage={uploadImage} isUpdating={isUpdating} />}
      {!isEditing && <DisplayImage heroImage={heroImage} />}
    </div>
  )
}

const DisplayImage = ({ heroImage }) => {
  if (!heroImage) return <p>No image uploaded</p>
  else return <img src={heroImage} alt="Banner for event" className="event-details__edit__image" />
}

const UploadImage = ({ inputRef, uploadImage, isUpdating }) => {
  return (
    <>
      <p>Click below to upload an image. Only .PNG, .JPG, .JPEG and .GIF file formats are supported.</p>
      <div className='event-details__edit__image-upload__wrapper'>
        <input type='file' ref={inputRef} disabled={isUpdating} />
        <button onClick={uploadImage} disabled={isUpdating}>Upload image</button>
      </div>
    </>
  )
}

export default EventHeroImage
