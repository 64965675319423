import React from 'react'
import classNames from 'classnames'


const Username = ({ userNameRef, noUsername, isProcessing}) => {
    const errorClass = classNames('input-error', 'margin-top--half', {
      'display-none': !noUsername,
    })
  
    return (
      <>
        <label htmlFor="username">Username</label>
        <input type="text" id="username" name="username" className="margin-bottom" data-cy="sign-in-username-input" ref={userNameRef} disabled={isProcessing} />
        <span className={errorClass} data-cy="no-username-error">Username is required</span>
      </>
    )
  }

  export default Username