import convertPriceToString from '../../../../util/convertPriceToString'
import useDialogsContext from '../DialogsContext'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/free-solid-svg-icons"

import './dialogs.dialog.scss'
import './eventSalesPayment.dialog.scss'

const EventSalesPaymentsDialog = () => {
    const { cancelDialogJourney, data } = useDialogsContext()
    const { order, downloadInvoice } = data

    if (!order) cancelDialogJourney()

    const PaymentLines = () => {
        if (order.payments && Object.values(order.payments)?.length > 0) {
            return (
                <>
                    {Object.values(order.payments).map(p => (
                        <PaymentLine
                            key={p.paymentId}
                            date={new Date(p.paymentDate).toLocaleDateString()}
                            amount={convertPriceToString(p.totalIncVat)}
                            invoiceId={p.invoiceId}
                            downloadInvoice={downloadInvoice}
                        />
                    ))}
                </>
            )
        }



        return (
            <PaymentLine
                date={new Date(order.orderPlacedDateTimeUtc * 1000).toLocaleDateString()}
                amount={convertPriceToString(order.totalPaid || order.totalCost)}
                invoiceId={order.invoiceId}
                downloadInvoice={downloadInvoice}
            />
        )
    }

    return (
        <section className="generic-dialog">
            <h2>Payments and invoices</h2>
            <p>Below are all payments and invoices available for this order</p>
            <PaymentLines />
            <button
                className='margin-top button button-secondary'
                onClick={cancelDialogJourney}
            >
                Close
            </button>
        </section>
    )
}

const PaymentLine = ({ date, amount, invoiceId, downloadInvoice }) => (
    <div className='event-sales__payment-line'>
        <span>{date}</span>
        <div className='event-sales__payment-line__rhs'>
            <span>{amount}</span>
            {!invoiceId && <span className='event-sales__payment-line__invoice'>[Invoice not available]</span>}
            {!!invoiceId && <span className='event-sales__payment-line__invoice'><a href={`invoice/${invoiceId}`} className="event-sales-expander__invoice" onClick={(e) => downloadInvoice(e, invoiceId)}><FontAwesomeIcon icon={faDownload} />Invoice</a></span>}
        </div>
    </div>
)

export default EventSalesPaymentsDialog
