import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { useContext, useEffect, useState } from 'react'

import { LocationContext } from '../context/locationContext'
import IncomeGraph from './IncomeGraph'

const StatsPage = ({ toggleNav }) => {
  const [orders, setOrders] = useState([])

  const location = useContext(LocationContext)

  useEffect(() => {
    location.getOrdersAndPreorders()
      .then(res => {
        setOrders(res)
      })
  }, [location])

  return (
    <div className="page">
      <div className="header">
        <FontAwesomeIcon icon={faBars} id="btn-mobile-nav" onClick={() => toggleNav()} />
      </div>

      <div className="body">
        <IncomeGraph orders={orders} />
      </div>
    </div>
  )
}

export default StatsPage
