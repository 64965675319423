import React, { useEffect, useState, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useParams, Link } from 'react-router-dom'
import { LocationClient } from "@food2room/clients"

import LocalNav from '../products/LocalNav'
import Loading from '../common/Loading'
import Product from '../../models/product.model'
import Sizes from './Sizes'
import UserContext from '../context/userContext'
import config from '../../config'
import ProductsTags from './ProductsTags'
import EditProduct from './EditProduct'
import Hero from '../common/Hero'
import './product_details.scss'

const PAGES = { DETAILS: 'details', SIZES: 'sizes' }

const ProductDetailsPage = ({ toggleNav }) => {
  const [product, setProduct] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [viewingPage, setViewingPage] = useState(PAGES.SIZES)
  const { selectedLocationId } = useContext(UserContext)

  function forceDOMUpdate() {
    if (id) {
      setIsLoading(true)
      LocationClient.setBaseUrl(config.baseOrderUrl)
      LocationClient.getProduct(selectedLocationId, id)
        .then(res => {
          setProduct(new Product(res, selectedLocationId))
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const { id } = useParams()

  useEffect(() => {
    if (id) {
      LocationClient.setBaseUrl(config.baseOrderUrl)
      LocationClient.getProduct(selectedLocationId, id)
        .then(res => {
          setProduct(new Product(res, selectedLocationId))
        })
        .catch(error => {
          // TODO
          console.error(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [id, selectedLocationId])

  const links = [
    { title: 'Product details' },
    { title: 'Sizes' },
  ]

  function navigateLocal(e) {
    if (e?.category.toLowerCase() === 'product_details') setViewingPage(PAGES.DETAILS)
    else setViewingPage(PAGES.SIZES)
  }

  if (isLoading) return <Loading fullscreen={true} />

  return (
    <div className="page">
      <div className="header">
        <FontAwesomeIcon icon={faBars} id="btn-mobile-nav" onClick={() => toggleNav()} className="margin-right" />
      </div>

      <section className='event-hero'>
        <Hero
          section="product"
          title={product.getProduct().name}
          subtitle={product.getProduct().description ?? "No description provided"}
        />
      </section>

      <div className="body events-page">
        <Link to={`/${selectedLocationId}/products`}><FontAwesomeIcon icon={faArrowLeft} /> Back to products</Link>
        <ProductsTags tags={product.getProduct().category.tags ?? {}} />
        <LocalNav sections={links} updateFilter={navigateLocal} hideAllLink={true} defaultLink="sizes" />
        <Body viewingPage={viewingPage} product={product} forceDOMUpdate={forceDOMUpdate} />
      </div>
    </div>
  )
}

const Body = ({ viewingPage, product, forceDOMUpdate }) => {
  if (viewingPage === PAGES.DETAILS) {
    return <EditProduct product={product} forceDOMUpdate={forceDOMUpdate} />
  }

  return <Sizes sizes={product.getProduct().sizes} createSizeFn={product.addSize} removeSize={product.removeSize} forceDOMUpdate={forceDOMUpdate} />
}

export default ProductDetailsPage
