import { faBars } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import Modal from 'react-modal'
import { useContext, useState } from "react"

import ListOfQRs from "./ListOfQRs"
import { QrContextProvider } from "./QrContext"
import PDFPage from "./PDFPage"
import LocalNav from "../products/LocalNav"

import './qr.scss'
import UserContext from "../context/userContext"

const QRCodes = ({ toggleNav }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [qrToPrint, setQrToPrint] = useState()
  const [qrFilter, setQrFilter] = useState({ location: 'all' })
  const { selectedLocationId } = useContext(UserContext)

  function openModal(qr) {
    setQrToPrint(qr)
    setIsModalOpen(true)
  }

  function closeModal() {
    setQrToPrint(null)
    setIsModalOpen(false)
  }

  function updateQrFilter(e) {
    setQrFilter({ ...qrFilter, location: e.category })
  }

  return (
    <QrContextProvider>
      <div className="page">
        <div className="header">
          <FontAwesomeIcon icon={faBars} id="btn-mobile-nav" onClick={() => toggleNav()} />
          <div className="label label--wip warning">This page is a work in progress</div>
        </div>

        <div className="qr-page">
          <div className="body">
            <span className="title">QR codes</span>
            <span className="sub-title">QR codes can be used to restrict menus to specific areas</span>
            <Link to={`/${selectedLocationId}/qr-codes/new`}><button className="margin-top">Add QR code</button></Link>
            {/* TODO - hardcoded areas */}
            <LocalNav sections={[{ title: "Restaurant" }, { title: "Bar" }, { title: "Rooms" }]} updateFilter={updateQrFilter} allLinkTitle="All areas" />
            <ListOfQRs openModal={openModal} filter={qrFilter} />
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        ariaHideApp={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <PDFPage qr={qrToPrint} />
      </Modal>
    </QrContextProvider>
  )
}

export default QRCodes
