import React from 'react'
import classNames from 'classnames'


const Password = ({ passwordRef, noPassword, isProcessing }) => {
    const errorClass = classNames('input-error', 'margin-top--half', {
      'display-none': !noPassword,
    })
  
    return (
      <div className="margin-top">
        <label htmlFor="password">Password</label>
        <input type="password" id="password" name="password" className="margin-bottom" data-cy="sign-in-password-input" ref={passwordRef} disabled={isProcessing} />
        <span className={errorClass} data-cy="no-password-error">Password is required</span>
      </div>
    )
  }

  export default Password