import React, { useState } from 'react'
import { uniqueId } from 'lodash'

import SizeCard from './SizeCard'
import NewSizeForm from './NewSizeForm'

const Sizes = ({ sizes, createSizeFn, removeSize, forceDOMUpdate }) => {
  const [isAddingNewSize, setIsAddingNewSize] = useState(false)

  function cancelSizeAdd() { setIsAddingNewSize(false) }

  if (isAddingNewSize) return <NewSizeForm cancelSizeAdd={() => setIsAddingNewSize(false)} createSizeFn={createSizeFn} forceDOMUpdate={forceDOMUpdate} />

  return (
    <>
      <div className="margin-top">
        <button onClick={() => setIsAddingNewSize(true)}>Add size</button>
      </div>
      <div className="grid grid__1 margin-top">
        {isAddingNewSize ? <SizeCard isAddingNewSize={isAddingNewSize} cancelSizeAdd={cancelSizeAdd} createSizeFn={createSizeFn} forceDOMUpdate={forceDOMUpdate} /> : null}
        <SizesList sizes={sizes} isAddingNewSize={isAddingNewSize} removeSize={removeSize} forceDOMUpdate={forceDOMUpdate} />
      </div>
    </>
  )
}

const SizesList = ({ sizes, isAddingNewSize, removeSize, forceDOMUpdate }) => {
  if ((!sizes || sizes.length === 0) && !isAddingNewSize) return <p>No sizes to show</p>
  return (
    <>
      {sizes.map(s => {
        return <SizeCard key={uniqueId('size-')} size={s} removeSize={removeSize} forceDOMUpdate={forceDOMUpdate} />
      })}
    </>
  )
}

export default Sizes
