import { useContext, useRef, useState } from "react"
import Select from 'react-select'
import { toast } from 'react-toastify'
import { useHistory, Link } from 'react-router-dom'
import ReactSwitch from 'react-switch'

import { ServdErrors } from "@food2room/clients"

import { getProductCategories } from "../../util/productCategories"
import { getAllergens, getDisplayTags } from "../../util/productTags"
import UserContext from "../context/userContext"

const EditProduct = ({ product, forceDOMUpdate }) => {
  const [isUpdating, setIsUpdating] = useState(false)

  const nameRef = useRef()
  const descRef = useRef()
  const categoryRef = useRef()
  const tagsRef = useRef()
  const allergensRef = useRef()
  const vatRateRef = useRef()
  const stockRef = useRef()

  function getCurrentTagsForSelect() {
    const returnValue = []
    if (!product.getProduct().category.tags.display) return returnValue
    Object.values(product.getProduct().category.tags.display).forEach(t => {
      returnValue.push(getDisplayTags()[getDisplayTags().findIndex(c => c.value === t)])
    })
    return returnValue
  }

  function getCurrentAllergensForSelect() {
    const returnValue = []
    if (!product.getProduct().category.tags.allergens) return returnValue
    for (const [key, value] of Object.entries(product.getProduct().category.tags.allergens)) {
      if (value === true) returnValue.push(getAllergens()[getAllergens().findIndex(c => c.value === key)])
    }
    return returnValue
  }

  function getAllergenTags() {
    let allergens = {
      celery: false, gluten: false, crustaceans: false, eggs: false,
      fish: false, lupin: false, milk: false, molluscs: false, mustard: false,
      treeNuts: false, peanuts: false, sesameSeeds: false, soybeans: false, sulphites: false,
    }

    allergensRef.current.getValue().forEach(a => {
      allergens[a.value] = true
    })

    return allergens
  }

  async function updateProduct() {
    setIsUpdating(true)
    const res = await product.updateProduct({
      name: nameRef.current.value,
      description: descRef.current.value,
      stock: parseInt(stockRef.current.value) ? parseInt(stockRef.current.value) : undefined,
      vatPercent: parseFloat(vatRateRef.current.value),
      category: {
        displayCategory: categoryRef.current.getValue()[0].value,
        kitchenCategory: categoryRef.current.getValue()[0].value,
        tags: {
          display: tagsRef.current.getValue().map(a => a.value),
          allergens: getAllergenTags(),
        },
      },
    })

    if (res) {
      forceDOMUpdate()
      toast.success('Product updated.')
    } else {
      toast.error('Could not update product. Try again.')
    }

    setIsUpdating(false)
  }

  async function toggleProductAvailability(newState) {
    setIsUpdating(true)
    
    const res = await product.updateProduct({
      available: newState,
    })

    if (res) {
      forceDOMUpdate()
      toast.success('Availability updated.')
    } else {
      toast.error('Could not update availability. Try again.')
    }

    setIsUpdating(false)
  }

  return (
    <div className="margin-top new-product-form">
      <h2>Availability</h2>
      <EditActive isActive={product.getProduct().available} update={toggleProductAvailability} />

      <h2>Product details</h2>

      <label htmlFor="fname">Name</label>
      <input type="text" id="fname" name="fname" ref={nameRef} disabled={isUpdating} defaultValue={product.getProduct().name ?? ''} />

      <label htmlFor="fdescription">Description</label>
      <textarea id="fdescription" name="fdescription" rows="4" ref={descRef} disabled={isUpdating} defaultValue={product.getProduct().description ?? ''} />

      <label htmlFor="stock">Stock</label>
      <input id="stock" name="stock" type="number" disabled={isUpdating} ref={stockRef} defaultValue={product.getProduct().stock ?? ''} placeholder="Leave blank for untracked stock" />

      <label htmlFor="vat">VAT rate</label>
      <div className="input-group-symbol-after">
        <input id="vat" name="vat" type="number" disabled={isUpdating} ref={vatRateRef} defaultValue={product.getProduct().vatPercent ?? ''} />
        <div className="symbol-wrapper"><span>%</span></div>
      </div>

      <label htmlFor="tags">Category</label>
      <div className="multi-select">
        <Select
          id="category"
          ref={categoryRef}
          isDisabled={isUpdating}
          options={getProductCategories()}
          defaultValue={getProductCategories()[getProductCategories().findIndex(c => c.value === product.getProduct().category.displayCategory)]}
        />
      </div>

      <label htmlFor="tags">Tags</label>
      <div className="multi-select">
        <Select
          isMulti
          id="tags"
          ref={tagsRef}
          isDisabled={isUpdating}
          options={getDisplayTags()}
          defaultValue={getCurrentTagsForSelect()}
        />
      </div>

      <label htmlFor="allergens">Allergens</label>
      <div className="multi-select">
        <Select
          isMulti
          id="allergens"
          ref={allergensRef}
          isDisabled={isUpdating}
          options={getAllergens()}
          defaultValue={getCurrentAllergensForSelect()}
        />
      </div>

      <button onClick={updateProduct}>Update product</button>

      <DeleteSection product={product} />
    </div>
  )
}

const DeleteSection = ({ product }) => {
  const [numberPressed, setNumberPressed] = useState(0)
  const [buttonText, setButtonText] = useState('Delete product')
  const history = useHistory()
  const [errorMenus, setErrorMenus] = useState()
  const { selectedLocationId } = useContext(UserContext)

  async function deleteProduct() {
    // TODO - needs a loading indicator

    if (numberPressed === 1) {
      const res = await product.deleteProduct()

      if (!res) {
        toast.error('Could not delete product. Please try again')
      } else if (res.errorCode === ServdErrors.PRODUCT_EXISTS_IN_MENU.errorCode) {
        toast.error('Cannot delete a product which exists on a menu.')
        setErrorMenus(res.data)
      } else {
        //could check for res.success === true here
        toast.success('Product deleted.')
        history.push(`/${selectedLocationId}/products`)
      }

    } else {
      toast.info('Click the button again to confirm deletion.')
      setButtonText(`Click again to confirm deletion`)
      setNumberPressed(numberPressed + 1)
    }
  }

  return (
    <>
      <h2 className="margin-top">Delete product</h2>
      {
        errorMenus
          ?
          <p className="p--error">
            <b>Error</b><br />
            Remove the product from the following menus before deleting it:
            <ul>
              {Object.values(errorMenus).map(e => {
                return <li><Link to={`/${selectedLocationId}/menus/${e.id}`}>{e.name}</Link></li>
              })}
            </ul>
          </p>
          :
          <p className="p--info">
            <b>Important</b><br />
            Deleting a product cannot be undone.
          </p>
      }
      <button onClick={deleteProduct} className={numberPressed > 0 ? 'button-warning' : ''}>{buttonText}</button>
    </>
  )
}

const EditActive = ({ isActive, update }) => {
  const [checked, setChecked] = useState(isActive)
  const [isUpdating, setIsUpdating] = useState(false)

  const handleChange = async (nextChecked) => {
    setIsUpdating(true)

    try {
      await update(nextChecked)
      setChecked(nextChecked)
      setIsUpdating(false)
    } catch (error) {
      console.error(error)
      setIsUpdating(false)
    }
  }

  return (
    <div className="event-details__heading-with-edit margin-bottom">
      <label>Active</label>
      <ReactSwitch
        checked={checked}
        uncheckedIcon={false}
        checkedIcon={false}
        height={20}
        width={35}
        onColor={"#7ca870"}
        onChange={handleChange}
        disabled={isUpdating}
      />
    </div>
  )
}

export default EditProduct
