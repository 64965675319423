import React, { createContext, useState, useContext, useEffect } from 'react'
import { OrderDestinationClient } from '@food2room/clients'

import UserContext from '../context/userContext'
import { getIdToken } from '../../util/auth'
import config from '../../config'

const Context = createContext({})

const Provider = ({ children }) => {
  const [qrsForLocation, setQrs] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const { selectedLocationId } = useContext(UserContext)

  useEffect(() => {
    OrderDestinationClient.setBaseUrl(config.baseOrderUrl)
    getIdToken()
      .then(token => {
        OrderDestinationClient.getAllByLocation(selectedLocationId, token)
          .then(res => {
            setQrs(res)
          })
          .catch(err => {
            // TODO - error catching
            console.error(err)
          })
          .finally(() => {
            setIsLoading(false)
          })
      })


  }, [selectedLocationId])

  async function createQr(area, number) {
    setIsLoading(true)
    return await OrderDestinationClient
      .create({ locationId: selectedLocationId, area: area, areaReference: number }, await getIdToken())
      .finally(() => setIsLoading(false))
  }

  async function deleteQr(id) {
    const res = await OrderDestinationClient.remove(id, await getIdToken())
    if (res.status === 200) {
      const qrsCopy = [...qrsForLocation]
      const indexToRemove = qrsCopy.findIndex(q => q.orderDestinationId === id)
      if (indexToRemove > -1) qrsCopy.splice(indexToRemove, 1)
      setQrs(qrsCopy)
    }
    return res
  }

  const qrContext = {
    qrsForLocation,
    isLoading,
    createQr,
    deleteQr,
  }

  return <Context.Provider value={qrContext}>{children}</Context.Provider>
}

const { Consumer } = Context

export const QrContext = Context
export const QrContextProvider = Provider
export const QrContextConsumer = Consumer