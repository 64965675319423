import React, { useState } from 'react'
import { toast } from 'react-toastify'

import Loading from '../common/Loading'
import convertPriceToString from '../../util/convertPriceToString'
import { getIdToken } from '../../util/auth'
import Card from '../common/Card'

const SizeCard = ({ size, removeSize, forceDOMUpdate, isAddingNewSize }) => {
  const [isUpdating, setIsUpdating] = useState(false)

  async function remove() {
    setIsUpdating(true)
    await removeSize(size, await getIdToken())
    // TODO - error catching
    toast.success(`${size.name} size removed.`)
    forceDOMUpdate()
    setIsUpdating(false)
  }

  const ActionButtons = () => {
    return (
      <button className='button-secondary button-card'>
        Edit size
      </button>
    )
  }

  if (isUpdating) return <Loading />
  return (
    <div className="product-ticket__card">
      <Card
        title={size?.name}
        subtitle={size?.description ?? 'No description provided'}
        deleteFunction={remove}
        // actionButtons={<ActionButtons />}
      >
        <section>
          <span className="price">{convertPriceToString(size?.price)}<span className="price-vat"> inc. VAT</span></span>
        </section>
      </Card>
    </div>
  )
}

export default SizeCard
