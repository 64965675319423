import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { AddToMenuContext } from '../context/addToMenuContext'
import Loading from '../common/Loading'
import userContext from '../context/userContext'
import UserContext from '../context/userContext'

const MenuDetailsProducts = ({ products, menu, updateMenu }) => {
  const history = useHistory()
  const addMenuContext = useContext(AddToMenuContext)
  const { selectedLocationId } = useContext(UserContext)

  function goToAddProduct() {
    addMenuContext.setMenuToAdd(menu)
    history.push(`/${selectedLocationId}/products`)
  }

  return (
    <div className="margin-top">
      <div className="margin-bottom">
        <button onClick={goToAddProduct}>Add product</button>
      </div>
      <Section title='Tickets' items={products.filter(p => p.section.toLowerCase() === 'tickets')} menuId={menu.id} updateMenu={updateMenu} />
      <Section title='Starters' items={products.filter(p => p.section.toLowerCase() === 'starters')} menuId={menu.id} updateMenu={updateMenu} />
      <Section title='Mains' items={products.filter(p => p.section.toLowerCase() === 'mains')} menuId={menu.id} updateMenu={updateMenu} />
      <Section title='Desserts' items={products.filter(p => p.section.toLowerCase() === 'desserts')} menuId={menu.id} updateMenu={updateMenu} />
      <Section title='Drinks' items={products.filter(p => p.section.toLowerCase() === 'drinks')} menuId={menu.id} updateMenu={updateMenu} />
    </div>
  )
}

const Section = ({ title, items = [], menuId, updateMenu }) => {
  if (items.length === 0) return null

  return (
    <section>
      <span className="menu-details-prods-section-title">{title}</span>
      <div className="menu-products-grid margin-bottom">
        {items.map(item => {
          return <ProductCard item={item} menuId={menuId} updateMenu={updateMenu} key={item.productId} />
        })}
      </div>
    </section>
  )
}

const ProductCard = ({ item, menuId, updateMenu }) => {
  const STATES = { NORMAL: 'normal', LOADING: 'loading' }
  const [currentState, setCurrentState] = useState(STATES.NORMAL)
  const { product } = item
  const { removeFromMenu } = useContext(AddToMenuContext)
  const { selectedLocationId } = useContext(userContext)

  async function removeProductFromMenu() {
    setCurrentState(STATES.LOADING)
    await removeFromMenu(menuId, item.id)
    // TODO - not sure if res is being passed to here
    // if (res?.status === 200) {
    toast.success(`Removed ${product.name} from this menu.`)
    updateMenu({})
    // }
  }

  if (currentState === STATES.LOADING) {
    return (
      <div className="menu-product-card">
        <Loading />
      </div>
    )
  }
  return (
    <div className="menu-product-card">
      <div className="placeholder-img"></div>
      <div className="prod-details">
        {product.available ? null : <span className="prod-warning">Out of stock</span>}
        <span className="prod-category">{item.section}</span>
        <span className="prod-name">{product.name}</span>

        <div className="prod-action-wrapper">
          <Link to={`/${selectedLocationId}/products/${product.id}`}><button><FontAwesomeIcon icon={faEye} />view</button></Link>
          <button className="prod-action-remove" onClick={async () => await removeProductFromMenu()}><FontAwesomeIcon icon={faTrash} />remove</button>
        </div>
      </div>
    </div>
  )
}

export default MenuDetailsProducts
