import React, { createContext, useState, useContext } from 'react'
import { LocationClient } from "@food2room/clients"

import UserContext from './userContext'
import { getIdToken } from '../../util/auth'

const Context = createContext({})

const Provider = ({ children }) => {
  const [menu, setMenu] = useState()

  const { selectedLocationId } = useContext(UserContext)

  function setMenuToAdd(menu) {
    setMenu({ ...menu })
  }

  function clearMenuToAdd() {
    setMenu(null)
  }

  function isAddingToMenu() {
    return menu?.id !== null
  }

  async function addToMenu(productId, section) {
    const res = await LocationClient.createMenuItem(selectedLocationId, menu.id, { productId: productId, section: section }, await getIdToken())
    return res
  }

  function isProductInMenu(productId) {
    if (!menu?.items) return false
    return Object.values(menu.items).some(i => i.productId === productId)
  }

  // Note - menu id cannot be taken from menu.id as menu only gets populated when pressing the add to menu button
  async function removeFromMenu(menuId, productId) {
    const res = await LocationClient.deleteMenuItem(selectedLocationId, menuId, productId, await getIdToken())
    return res
  }

  const addToMenuContext = {
    menu,
    setMenuToAdd,
    clearMenuToAdd,
    isAddingToMenu,
    addToMenu,
    isProductInMenu,
    removeFromMenu,
  }

  return <Context.Provider value={addToMenuContext}>{children}</Context.Provider>
}

const { Consumer } = Context

export const AddToMenuContext = Context
export const AddToMenuContextProvider = Provider
export const AddToMenuContextConsumer = Consumer