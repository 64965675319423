import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

const PreorderPage = ({ toggleNav }) => {
  return (
    <div className="page">
      <div className="header">
        <FontAwesomeIcon icon={faBars} id="btn-mobile-nav" onClick={() => toggleNav()} />
      </div>

      <div className="body padding-bottom--none">
        <div className="history-page-wrapper">
          <div>
            <p className='p--info'>
              <b>Improved sales view</b><br /><br />
              You can view sales for a single event in the event's details page. To do that, go to the <a href="/menus" rel="noreferrer">events list page</a>, select the event you wish to view and head over to the sales tab.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PreorderPage
