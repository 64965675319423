import { TagsDisplayLabels } from "../../util/productTags"

const ProductsTags = ({ tags }) => {
  if (!tags) return null

  return (
    <div className="margin-top">
      {tags?.allergens ? <Allergens allergens={tags.allergens} /> : null}
      {tags?.display ? tags?.display.map(t => <Tag tag={t} />) : null}
    </div>
  )
}

const Tag = ({ tag, className }) => {
  return <span className={`display_tag ${className}`}>{TagsDisplayLabels[tag]}</span>
}

const Allergens = ({ allergens }) => {
  if (!allergens) return null

  const allergenTags = []
  for (const [key, value] of Object.entries(allergens)) {
    if (value === true) allergenTags.push(<Tag tag={key} className="display_tag--alert" />)
  }

  return allergenTags
}

export default ProductsTags
