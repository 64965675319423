import React from 'react'

const ErrorMessage = ({ error, headingText="Error" }) => {
  if (!error) return null
  return (
    <p className="p--error" data-cy="error-message">
      <b>{headingText}</b><br />
      {error}
    </p>
  )
}

export default ErrorMessage
