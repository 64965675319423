import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faClock, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { LocationClient } from "@food2room/clients"
import moment from 'moment'

import Loading from '../common/Loading'
import userContext from '../context/userContext'
import config from '../../config'
import Card from '../common/Card'
import Hero from '../common/Hero'

const MenuPage = ({ toggleNav }) => {
  const [menus, setMenus] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const { selectedLocationId } = useContext(userContext)

  useEffect(() => {
    LocationClient.setBaseUrl(config.baseOrderUrl)
    LocationClient.getLocation(selectedLocationId).then(res => {
      setMenus(Object.values(res.menus))
      setIsLoading(false)
    })
    // eslint-disable-next-line
  }, [])

  if (isLoading) return <Loading fullscreen={true} />
  return (
    <div className="page">
      <div className="header">
        <FontAwesomeIcon icon={faBars} id="btn-mobile-nav" onClick={() => toggleNav()} />
      </div>

      <Hero
        section="planner"
        title="Menus and events"
        subtitle="Organise and manage your table-service menus and events here."
      />

      <div className="body menus-page">
        <div className="menus-sub-nav-wrapper">
          <Link to={`/${selectedLocationId}/menus/new`}><button id="btn-new-menu">New menu</button></Link>
        </div>

        <MenusList menus={menus} />
      </div>
    </div>
  )
}

const MenusList = ({ menus }) => {
  if (!menus || menus.length === 0) return <p>No menus to show</p>

  return (
    <div className="grid margin-top">
      {menus.map(m =>
        <Card
          key={m.id}
          title={m.name}
          subtitle={m.description}
          tag={m.menuType}
          viewLink={`${m.menuType?.toLowerCase() === 'event' ? 'events' : 'menus'}/${m.id}`}
          headerIcon={m.active ? faCheckCircle : faTimesCircle}
        >
          <div>
            {
              m.menuType?.toLowerCase() === 'event'
                ?
                <EventDates eventDates={m.eventDetails?.eventDateTime ?? []} />
                :
                null
            }
          </div>
        </Card>
      )}
    </div>
  )
}

const EventDates = ({ eventDates }) => {
  if (!eventDates || eventDates.length === 0) {
    return (
      <div>
        <span>This event has no specified dates.</span>
      </div>
    )
  }

  let moments = []
  eventDates.forEach(d => {
    moments.push(moment(d.start))
    moments.push(moment(d.end))
  })

  const minDate = moment.min(moments)
  const maxDate = moment.max(moments)

  return (
    <div>
      <FontAwesomeIcon icon={faClock} /><span>{minDate.format('DD MMM YYYY')} - {maxDate.format('DD MMM YYYY')}</span>
    </div>
  )
}

export default MenuPage
