import React from 'react'

import ProductsCard from './ProductsCard'

const ProductsList = ({ products }) => {
  if (!products || products.length === 0) {
    return <p data-cy="no-products-info">No products to show</p>
  }

  return (
    <>
      <div className="grid">
        {products.map(p => {
          return <ProductsCard product={p} key={p.id} />
        })}
      </div>
    </>

  )
}

export default ProductsList
