import { useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'

const EventDescription = ({ description, update, label }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  const inputRef = useRef()

  async function updateValue() {
    setIsUpdating(true)

    if (inputRef.current?.value?.length > 2000) {
      toast.error(`${label} cannot be longer than 2000 characters. You entered ${inputRef.current.value.length} characters.`)
      setIsUpdating(false)
      return
    }

    try {
      await update(inputRef.current.value)
      toast.success(`Event ${label.toLowerCase()} updated.`)
      setIsEditing(false)
    } catch (error) {
      console.log(error)
      toast.error(`Failed to update event ${label.toLowerCase()}. Try again.`)
    }

    setIsUpdating(false)
  }

  return (
    <>
      <div className='event-details__heading-with-edit' title='edit'>
        <label>{label}</label>
        <button onClick={() => setIsEditing(!isEditing)}><FontAwesomeIcon icon={faPencilAlt} /></button>
      </div>
      {isEditing && <EditDescription description={description} cancelEditing={() => setIsEditing(false)} inputRef={inputRef} updateValue={updateValue} isUpdating={isUpdating} />}
      {!isEditing && <p className='white-space--break-spaces'>{description}</p>}
    </>
  )
}

const EditDescription = ({ description, cancelEditing, inputRef, updateValue, isUpdating }) => {
  return (
    <div className='margin-bottom'>
      <textarea
        className='margin-top margin-bottom'
        rows={10}
        defaultValue={description}
        ref={inputRef}
        disabled={isUpdating}
      />
      <button onClick={updateValue} disabled={isUpdating}>Update</button>
      <button className='button-secondary margin-left' onClick={cancelEditing} disabled={isUpdating}>Cancel</button>
    </div>
  )
}

export default EventDescription
