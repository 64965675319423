import React, { useEffect, useState, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faArrowLeft, faQrcode } from '@fortawesome/free-solid-svg-icons'
import { Link, useParams } from 'react-router-dom'
import { LocationClient } from "@food2room/clients"
import Modal from 'react-modal'

import LocalNav from '../products/LocalNav'
import MenuDetailsProducts from './MenuDetailsProducts'
import userContext from '../context/userContext'
import Loading from '../common/Loading'
import MenuSettings from './MenuSettings'
import config from '../../config'
import { getIdToken } from '../../util/auth'
import PDFPage from "../qr-codes/PDFPage"

const PAGES = { DETAILS: 'details', PRODUCTS: 'products' }

const MenuDetails = ({ toggleNav }) => {
  const [menu, setMenu] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [viewingPage, setViewingPage] = useState(PAGES.PRODUCTS)
  const [warnings, setWarnings] = useState([])

  const params = useParams()
  const { selectedLocationId } = useContext(userContext)

  useEffect(() => {
    setIsLoading(true)
    LocationClient.setBaseUrl(config.baseOrderUrl)

    getIdToken().then(token => {
      LocationClient.getMenu(selectedLocationId, params.id, token).then(menu => {
        setMenu(menu)
      })
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (Object.keys(menu).length > 0) {
      // Check for warnings
      const warnings = []

      const outOfStockItems = Object.values(menu.items).filter(i => i.product.available === false)
      if (outOfStockItems.length > 0) warnings.push(`${outOfStockItems.length} product${outOfStockItems.length > 1 ? 's' : ''} out of stock`)

      if (!menu.active) warnings.push('Inactive menu')

      if (menu.menuType?.toLowerCase() === 'event') {
        if (!menu?.eventDetails?.eventDateTime || menu?.eventDetails?.eventDateTime?.length === 0) {
          warnings.push('Event has no dates')
        }
      }

      setWarnings([...warnings])
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [menu])

  async function updateMenu() {
    setWarnings([])
    setIsLoading(true)
    const token = await getIdToken()
    LocationClient.getMenu(selectedLocationId, params.id, token).then(menu => {
      setMenu(menu)
    })
  }

  return (
    <div className="page">
      <div className="header">
        <FontAwesomeIcon icon={faBars} id="btn-mobile-nav" onClick={() => toggleNav()} className="margin-right" />
        <Link to={`/${selectedLocationId}/menus`}><FontAwesomeIcon icon={faArrowLeft} /> Back to menus</Link>
      </div>

      <Body isLoading={isLoading} menu={menu} viewingPage={viewingPage} setViewingPage={setViewingPage} warnings={warnings} updateMenu={updateMenu} />
    </div>
  )
}

const Body = ({ isLoading, menu, viewingPage, setViewingPage, warnings, updateMenu }) => {
  const localNavSection = [{ title: 'Menu details' }, { title: 'All products' }]

  function navigateLocal(e) {
    if (e?.category.toLowerCase() === 'menu_details') setViewingPage(PAGES.DETAILS)
    else setViewingPage(PAGES.PRODUCTS)
  }

  if (isLoading) return <Loading fullscreen={true} />
  return (
    <div className="body">
      <div className='menu__header__wrapper'>
        <div>
          <span className="title">{menu.name ?? 'No name provided'}</span>
          <span className="sub-title">{menu.description ?? "No description provided"}</span>
          <Warnings warnings={warnings} />
        </div>
        <GetLinkToMenu menu={menu} />
      </div>
      <LocalNav sections={localNavSection} updateFilter={navigateLocal} hideAllLink={true} defaultLink="all_products" />

      {viewingPage === 'details' ? <MenuSettings menu={menu} updateMenu={updateMenu} /> : <MenuDetailsProducts products={Object.values(menu.items)} menu={menu} updateMenu={updateMenu} />}
    </div>
  )
}

const GetLinkToMenu = ({ menu }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [qrToPrint, setQrToPrint] = useState()

  function openModal() {
    setQrToPrint({
      menuType: menu.menuType,
      menuName: menu.name,
      orderDestinationId: menu.orderDestinationId,
    })
    setIsModalOpen(true)
  }

  function closeModal() {
    setQrToPrint(null)
    setIsModalOpen(false)
  }

  if (!menu.orderDestinationId) return null

  return (
    <>
      <div>
        <button className='button-secondary menu__header__btn-link' onClick={openModal}>
          <FontAwesomeIcon icon={faQrcode} />
          <span>QR code/link</span>
        </button>
      </div>

      <Modal
        isOpen={isModalOpen}
        ariaHideApp={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <PDFPage qr={qrToPrint} />
      </Modal>
    </>
  )
}

const Warnings = ({ warnings = [] }) => {
  if (warnings.length === 0) return null
  return (
    <div>
      {warnings.map((warning, i) => {
        return (
          <span className="menu-warning" key={`menu-warning-${i}}`}>{warning}</span>
        )
      })}
    </div>
  )
}

export default MenuDetails
