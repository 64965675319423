import './hero.scss'

const Hero = ({ section, title, subtitle, imageUrl }) => {
  return (
    <div className='hero__wrapper'>
      <HeroImage imageUrl={imageUrl} />
      <div className='hero__content'>
        <span className='hero__section'>{section}</span>
        <h2 className='hero__title'>{title}</h2>
        <p className='hero__subtitle'>{subtitle}</p>
      </div>
    </div>
  )
}

const HeroImage = ({ imageUrl }) => {
  if (!imageUrl) return <svg viewBox="0 0 960 540" width="100%" height="100%" preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg" className="absolute inset-0 pointer-events-none"><g fill="none" strokeWidth="100" className="text-gray-700 opacity-25"><circle r="234" cx="196" cy="23"></circle><circle r="234" cx="790" cy="491"></circle></g></svg>
  else return <div
    className='hero__image'
    style={{ backgroundImage: `url(${imageUrl})` }}
  />
}

export default Hero
