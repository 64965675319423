import { useState, useContext, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Link, useParams } from 'react-router-dom'

import LocalNav from '../../products/LocalNav'
import './event_details.scss'
import EditDetails from './EditDetails'
import userContext from '../../context/userContext'
import Loading from '../../common/Loading'
import { EventContextProvider, EventContext } from './EventContext'
import EventDates from './EventDates'
import Tickets from './Tickets'
import Sales from './Sales'
import Hero from '../../common/Hero'

const PAGES = { DETAILS: 'details', TICKETS: 'tickets', DATES: 'dates', SALES: 'sales' }

const EventDetailsPage = ({ toggleNav }) => {
  const [viewingPage, setViewingPage] = useState(PAGES.TICKETS)

  const { selectedLocationId } = useContext(userContext)
  const params = useParams()

  useEffect(() => {
    if (window.location.hash.toLowerCase() === '#event_dates') setViewingPage(PAGES.DATES)
    else if (window.location.hash.toLowerCase() === '#event_details') setViewingPage(PAGES.DETAILS)
    else if (window.location.hash.toLowerCase() === '#tickets') setViewingPage(PAGES.TICKETS)
    else if (window.location.hash.toLowerCase() === '#sales') setViewingPage(PAGES.SALES)
    else setViewingPage(PAGES.TICKETS)
  }, [])

  return (
    <EventContextProvider eventId={params.id} locationId={selectedLocationId} >
      <Body
        viewingPage={viewingPage}
        setViewingPage={setViewingPage}
        toggleNav={toggleNav}
        selectedLocationId={selectedLocationId}
      />
    </EventContextProvider>
  )
}

const Body = ({ viewingPage, setViewingPage, toggleNav, selectedLocationId }) => {
  const { isLoading } = useContext(EventContext)

  const localNavSection = [{ title: 'Event details' }, { title: 'Event dates' }, { title: 'Tickets' }, { title: 'Sales' }]

  function navigateLocal(e) {
    if (e?.category.toLowerCase() === 'event_details') setViewingPage(PAGES.DETAILS)
    else if (e?.category.toLowerCase() === 'event_dates') setViewingPage(PAGES.DATES)
    else if (e?.category.toLowerCase() === 'sales') setViewingPage(PAGES.SALES)
    else setViewingPage(PAGES.TICKETS)
  }

  if (isLoading) return <Loading fullscreen={true} />

  return (
    <div className="page">
      <div className="header">
        <FontAwesomeIcon icon={faBars} id="btn-mobile-nav" onClick={() => toggleNav()} className="margin-right" />
        <Link to={`/${selectedLocationId}/menus`}><FontAwesomeIcon icon={faArrowLeft} /> Back to events</Link>
      </div>

      <EventHero />

      <section className='body events-page'>
        <Warnings />
        
        <div className="event-details-local-nav">
          <LocalNav sections={localNavSection} updateFilter={navigateLocal} hideAllLink={true} defaultLink="tickets" />
        </div>

        {viewingPage === PAGES.DETAILS && <EditDetails locationId={selectedLocationId} />}
        {viewingPage === PAGES.DATES && <EventDates />}
        {viewingPage === PAGES.TICKETS && <Tickets />}
        {viewingPage === PAGES.SALES && <Sales />}
      </section>
    </div>
  )
}

const EventHero = () => {
  const { event } = useContext(EventContext)
  return (
    <section className='event-hero'>
      <Hero
        section="event"
        title={event.name}
        // subtitle={event.description}
        imageUrl={event?.image?.coverUrl}
      />
    </section>
  )
}

const Warnings = () => {
  const { warnings } = useContext(EventContext)

  if (warnings.length === 0) return null
  return (
    <div className='event-details-warnings'>
      {warnings.map((warning, i) => {
        return (
          <span className="menu-warning" key={`menu-warning-${i}}`}>{warning}</span>
        )
      })}
    </div>
  )
}

export default EventDetailsPage
