import React, { useEffect, useState } from 'react'
import { AreaChart, XAxis, CartesianGrid, Tooltip, Area, ResponsiveContainer } from 'recharts'
import Select from 'react-select'
import DataTable from 'react-data-table-component'

import './stats.scss'

const TIME_FILTERS = { MONTH: 'month', YEAR: 'year' }

const IncomeGraph = ({ orders }) => {
  const [data, setData] = useState()
  const [timeFilter, setTimeFilter] = useState(TIME_FILTERS.MONTH)

  useEffect(() => {
    if (!orders) return

    // TODO - eventually we'll want to cache this data so we don't have to calculate it on the fly
    const year = new Date().getFullYear()
    const month = new Date().getMonth() + 1
    const daysInMonth = new Date(year, month, 0).getDate()

    const graphData = {
      2021: {
        totalValue: 0,
        months: {
          1: { totalValue: 0, days: {}, displayName: 'Jan', completed: 0, inProgress: 0, preOrders: 0 },
          2: { totalValue: 0, days: {}, displayName: 'Feb', completed: 0, inProgress: 0, preOrders: 0 },
          3: { totalValue: 0, days: {}, displayName: 'Mar', completed: 0, inProgress: 0, preOrders: 0 },
          4: { totalValue: 0, days: {}, displayName: 'Apr', completed: 0, inProgress: 0, preOrders: 0 },
          5: { totalValue: 0, days: {}, displayName: 'May', completed: 0, inProgress: 0, preOrders: 0 },
          6: { totalValue: 0, days: {}, displayName: 'Jun', completed: 0, inProgress: 0, preOrders: 0 },
          7: { totalValue: 0, days: {}, displayName: 'Jul', completed: 0, inProgress: 0, preOrders: 0 },
          8: { totalValue: 0, days: {}, displayName: 'Aug', completed: 0, inProgress: 0, preOrders: 0 },
          9: { totalValue: 0, days: {}, displayName: 'Sep', completed: 0, inProgress: 0, preOrders: 0 },
          10: { totalValue: 0, days: {}, displayName: 'Oct', completed: 0, inProgress: 0, preOrders: 0 },
          11: { totalValue: 0, days: {}, displayName: 'Nov', completed: 0, inProgress: 0, preOrders: 0 },
          12: { totalValue: 0, days: {}, displayName: 'Dec', completed: 0, inProgress: 0, preOrders: 0 },
        },
      },
      2022: {
        1: { totalValue: 0, days: {}, displayName: 'Jan', completed: 0, inProgress: 0, preOrders: 0 },
        2: { totalValue: 0, days: {}, displayName: 'Feb', completed: 0, inProgress: 0, preOrders: 0 },
        3: { totalValue: 0, days: {}, displayName: 'Mar', completed: 0, inProgress: 0, preOrders: 0 },
        4: { totalValue: 0, days: {}, displayName: 'Apr', completed: 0, inProgress: 0, preOrders: 0 },
        5: { totalValue: 0, days: {}, displayName: 'May', completed: 0, inProgress: 0, preOrders: 0 },
        6: { totalValue: 0, days: {}, displayName: 'Jun', completed: 0, inProgress: 0, preOrders: 0 },
        7: { totalValue: 0, days: {}, displayName: 'Jul', completed: 0, inProgress: 0, preOrders: 0 },
        8: { totalValue: 0, days: {}, displayName: 'Aug', completed: 0, inProgress: 0, preOrders: 0 },
        9: { totalValue: 0, days: {}, displayName: 'Sep', completed: 0, inProgress: 0, preOrders: 0 },
        10: { totalValue: 0, days: {}, displayName: 'Oct', completed: 0, inProgress: 0, preOrders: 0 },
        11: { totalValue: 0, days: {}, displayName: 'Nov', completed: 0, inProgress: 0, preOrders: 0 },
        12: { totalValue: 0, days: {}, displayName: 'Dec', completed: 0, inProgress: 0, preOrders: 0 },
      },
    }

    // Breakdown data by day for this month
    for (let index = 0; index < daysInMonth; index++) {
      graphData[year]['months'][month]['days'][index] = {
        name: `${index + 1}`,
        displayName: new Date(year, month, index + 1).toDateString(),
        completed: 0,
        inProgress: 0,
        preOrders: 0,
        totalValue: 0,
      }
    }

    orders.forEach(o => {
      // TODO - for now we exclude unpaid pre-orders
      if (o.type === 'preorder' && !o.paymentReceived) return

      const oDay = new Date(o.orderPlacedDateTimeUtc * 1000).getDate() - 1
      const oMonth = new Date(o.orderPlacedDateTimeUtc * 1000).getMonth() + 1

      if (o.type === 'preorder') {
        if (oMonth === month) {
          graphData[year]['months'][oMonth]['days'][oDay].preOrders += (o.totalCost / 100)
          graphData[year]['months'][oMonth]['days'][oDay].total += (o.totalCost / 100)
        }
        graphData[year]['months'][oMonth].preOrders += (o.totalCost / 100)
        graphData[year]['months'][oMonth].totalValue += (o.totalCost / 100)
        graphData[year].totalValue += (o.totalCost / 100)
      } else if (o.orderStatus.toLowerCase() === 'completed') {
        if (oMonth === month) {
          graphData[year]['months'][oMonth]['days'][oDay].completed += (o.totalCost / 100)
          graphData[year]['months'][oMonth]['days'][oDay].total += (o.totalCost / 100)
        }
        graphData[year]['months'][oMonth].completed += (o.totalCost / 100)
        graphData[year]['months'][oMonth].totalValue += (o.totalCost / 100)
        graphData[year].totalValue += (o.totalCost / 100)
      }
      else if (o.orderStatus.toLowerCase() === 'in progress') {
        if (oMonth === month) {
          graphData[year]['months'][oMonth]['days'][oDay].inProgress += (o.totalCost / 100)
          graphData[year]['months'][oMonth]['days'][oDay].total += (o.totalCost / 100)
        }
        graphData[year]['months'][oMonth].inProgress += (o.totalCost / 100)
        graphData[year]['months'][oMonth].totalValue += (o.totalCost / 100)
        graphData[year].totalValue += (o.totalCost / 100)
      }
    })

    setData(graphData)
  }, [orders, timeFilter])

  function updateTimeFilter(e) {
    if (e.toLowerCase() === 'month') setTimeFilter(TIME_FILTERS.MONTH)
    else setTimeFilter(TIME_FILTERS.YEAR)
  }

  if (!data) return null

  return (
    <div>
      <div className="stats-income-header-wrapper">
        <div>
          <h2>Gross amount</h2>
          <span className="income-total-subtitle">£{timeFilter === TIME_FILTERS.MONTH ? (parseInt(data[2021]['months'][new Date().getMonth() + 1].totalValue)).toFixed(2) : (parseInt(data[2021].totalValue)).toFixed(2)}</span>
        </div>
        <div className="filter-wrapper">
          <label>Date span</label>
          <Select
            options={[{ value: 'month', label: 'This month' }, { value: 'year', label: 'This year' }]}
            defaultValue={{ value: 'month', label: 'This month' }}
            onChange={(e) => updateTimeFilter(e?.value)}
          />
        </div>
      </div>
      <section>
        <ResponsiveContainer width="100%" height={250}>
          {
            timeFilter === TIME_FILTERS.MONTH
              ?
              <AreaChart data={Object.values(data[2021]['months'][new Date().getMonth() + 1].days)} className="income-graph">
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#3C63BF" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#3C63BF" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#FEBD8E" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#FEBD8E" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#7ca870" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#7ca870" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name" />
                <CartesianGrid strokeDasharray="0.2 0.2" />
                <Tooltip />
                <Area type="monotone" dataKey="completed" stroke="#3C63BF" fillOpacity={0.5} fill="url(#colorUv)" />
                <Area type="monotone" dataKey="inProgress" stroke="#FEBD8E" fillOpacity={0.5} fill="url(#colorPv)" />
                <Area type="monotone" dataKey="preOrders" stroke="#7ca870" fillOpacity={0.5} fill="url(#colorPv)" />
              </AreaChart>
              :
              <AreaChart data={Object.values(data[2021]['months'])} className="income-graph">
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#3C63BF" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#3C63BF" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="displayName" />
                <CartesianGrid strokeDasharray="0.2 0.2" />
                <Tooltip />
                <Area type="monotone" dataKey="totalValue" stroke="#3C63BF" fillOpacity={0.5} fill="url(#colorUv)" />
              </AreaChart>
          }
        </ResponsiveContainer>
      </section>
      <section>
        <DataTable
          columns={[
            {
              name: 'Date',
              selector: 'displayName',
            },
            {
              name: 'Completed',
              selector: 'completed',
              cell: row => <span>£{(parseInt(row.completed)).toFixed(2)}</span>
            },
            {
              name: 'In progress',
              selector: 'inProgress',
              cell: row => <span>£{(parseInt(row.inProgress)).toFixed(2)}</span>
            },
            {
              name: 'Preorders',
              selector: 'preOrders',
              cell: row => <span>£{(parseInt(row.preOrders)).toFixed(2)}</span>
            },
            {
              name: 'Total',
              selector: 'total',
              cell: row => <span>£{(parseInt(row.total ?? row.totalValue)).toFixed(2)}</span>
            },
          ]}
          data={timeFilter === TIME_FILTERS.MONTH ? Object.values(data[2021]['months'][new Date().getMonth() + 1].days) : Object.values(data[2021]['months'])}
          pagination={true}
          paginationPerPage={9}
        />
      </section>
    </div>
  )
}

export default IncomeGraph
