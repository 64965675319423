import { useRef, useState } from 'react'
import ReactSwitch from 'react-switch'
import { v4 } from 'uuid'
import { toast } from 'react-toastify'

import useDialogsContext from '../DialogsContext'
import './eventAddDataField.dialog.scss'

const EventAddDataFieldDialog = () => {
  const [isRequired, setIsRequired] = useState(false)
  const [includeInInvoice, setIncludeInInvoice] = useState(false)

  const [hasNameError, setHasNameError] = useState(false)
  const [isMakingRequest, setIsMakingRequest] = useState(false)

  const fieldNameRef = useRef()

  const { cancelDialogJourney, onSuccessCallback } = useDialogsContext()

  const onSubmit = async (e) => {
    e.preventDefault()

    const nameValue = fieldNameRef.current.value
    if (!nameValue || nameValue.length < 1) {
      setHasNameError(true)
      return
    }

    setIsMakingRequest(true)
    try {
      await onSuccessCallback({
        id: v4(),
        label: nameValue,
        isRequired: isRequired,
        type: 'text',
        includeInInvoice: includeInInvoice,
      })

      toast.success(`${nameValue} added.`)
      cancelDialogJourney()
    } catch (error) {
      toast.error(`Could not add ${nameValue}. Try again.`)
    } finally {
      setIsMakingRequest(false)
    }
  }

  return (
    <section className="event-add-data-field-dialog">
      <h1>Add Event Field</h1>
      <p>Here you can create additional fields to gather data from the customers at the point of purchase.</p>

      <form onSubmit={onSubmit} className="margin-top--2">
        <label htmlFor="fieldName">Field name</label>
        <input
          type="text"
          name="fieldName"
          id="fieldName"
          placeholder="Label to be displayed to the user"
          ref={fieldNameRef}
          disabled={isMakingRequest}
        />
        {hasNameError && <span className='input-error'>Field name is required</span>}

        <div className='margin-top group-switch'>
          <label>Is this field mandatory?</label>
          <ReactSwitch
            checked={isRequired}
            onChange={() => setIsRequired(!isRequired)}
            disabled={isMakingRequest}
          />
        </div>

        <div className='margin-top group-switch'>
          <label>Include in invoice?</label>
          <ReactSwitch
            checked={includeInInvoice}
            onChange={() => setIncludeInInvoice(!includeInInvoice)}
            disabled={isMakingRequest}
          />
        </div>

        <div className='dialog-buttons-group'>
          <button disabled={isMakingRequest}>Create</button>
          <button className='button button-secondary' onClick={cancelDialogJourney} disabled={isMakingRequest}>Cancel</button>
        </div>
      </form>
    </section>
  )
}

export default EventAddDataFieldDialog
