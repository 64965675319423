import { LocationClient } from '@food2room/clients'
import config from '../config'
import { getIdToken } from '../util/auth'

export default class Product {
  constructor(product, locationId) {
    this.product = product
    this.locationId = locationId
    if (!this.product.sizes) this.product.sizes = []
    LocationClient.setBaseUrl(config.baseOrderUrl)
  }

  getProduct = () => {
    return this.product
  }

  addSize = async (name, price, token) => {
    const newSizes = [...this.product.sizes]
    newSizes.push({
      name: name,
      price: price,
    })
    const res = await LocationClient.updateProduct(this.locationId, this.product.id, { sizes: newSizes }, token)
    // TODO - error catching
    this.product = res
    return res
  }

  removeSize = async (size, token) => {
    const index = this.product.sizes.findIndex(s => size === s)
    if (index > -1) {
      const newSizes = [...this.product.sizes]
      newSizes.splice(index, 1)
      const res = await LocationClient.updateProduct(this.locationId, this.product.id, { sizes: newSizes }, token)
      // TODO - error catching
      this.product = res
      return res
    }
    return false
  }

  updateProduct = async (changes) => {
    const res = await LocationClient.updateProduct(this.locationId, this.product.id, { ...this.getProduct(), ...changes }, await getIdToken())
    return res
  }

  deleteProduct = async () => {
    const res = await LocationClient.deleteProduct(this.locationId, this.product.id, await getIdToken())
      .catch(err => {
        console.log(err);
        return err
      })
    return res
  }
}
