import { useState, useRef, useContext } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css"
import Modal from 'react-modal'
import { toast } from 'react-toastify'

import ErrorMessage from '../../common/ErrorMessage'
import { EventContext } from './EventContext'

const localizer = momentLocalizer(moment)

const EventDatePlanner = ({ menu }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(true)
  const [dateBeingDeleted, setDateBeingDeleted] = useState()

  const { updateEventDeleteDate } = useContext(EventContext)

  function openDeleteModal(date) {
    setDateBeingDeleted(date)
    setIsDeleteModalOpen(true)
  }

  function closeDeleteModal() {
    setDateBeingDeleted(null)
    setIsDeleteModalOpen(false)
  }

  function getEventDates() {
    if (!menu.eventDetails || !menu.eventDetails.eventDateTime) return []

    return menu.eventDetails.eventDateTime.map(event => {
      return { ...event, title: menu.name }
    })
  }

  async function deleteDate() {
    try {
      await updateEventDeleteDate(dateBeingDeleted)
      closeDeleteModal()
      toast.success('Date deleted.')
    } catch (error) {
      console.error(error)
      toast.error('Could not delete the date, try again.')
    }
  }

  return (
    <>
      <div className='margin-bottom'>
        <EventDate />
        <p className='p--info'>To remove a date, click on it in calendar view and confirm deletion.</p>
      </div>
      <Calendar
        localizer={localizer}
        events={getEventDates()}
        startAccessor="start"
        endAccessor="end"
        defaultDate={new Date()}
        defaultView="month"
        style={{ height: "50vh" }}
        views={{ month: true, agenda: true }}
        onSelectEvent={(e) => openDeleteModal(e)}
      />

      <Modal
        isOpen={isDeleteModalOpen}
        ariaHideApp={false}
        onRequestClose={closeDeleteModal}
        shouldCloseOnOverlayClick={true}
      >
        <DeleteDateModal closeModal={closeDeleteModal} dateBeingDeleted={dateBeingDeleted} deleteDate={deleteDate} />
      </Modal>
    </>
  )
}

const EventDate = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { updateEventAddDate } = useContext(EventContext)

  async function addNewDate(startDateTime, endDateTime) {
    try {
      await updateEventAddDate(startDateTime, endDateTime)
      toast.success('Date added.')
      setIsModalOpen(false)
    } catch (error) {
      console.error(error)
      toast.error('Could not add the date, try again.')
    }
  }

  return (
    <>
      <div className='menu__event-date-heading__layout'>
        <h2 className="margin-top">Event dates</h2>
        <button onClick={() => setIsModalOpen(true)}>Add a date</button>
      </div>

      <Modal
        isOpen={isModalOpen}
        ariaHideApp={false}
        onRequestClose={() => setIsModalOpen(false)}
        shouldCloseOnOverlayClick={true}
      >
        <AddDateModal closeModal={() => setIsModalOpen(false)} addNewDate={addNewDate} />
      </Modal>
    </>
  )
}

const AddDateModal = ({ closeModal, addNewDate }) => {
  const [isAdding, setIsAdding] = useState(false)
  const [error, setError] = useState()

  const startDateRef = useRef()
  const endDateRef = useRef()

  const startTimeRef = useRef()
  const endTimeRef = useRef()

  function checkFieldsAreValid() {
    setError(null)
    let isValid = true
    const errors = []

    if (!startDateRef.current.value || startDateRef.current.value.length === 0) {
      isValid = false
      errors.push('start date')
    }

    if (!endDateRef.current.value || endDateRef.current.value.length === 0) {
      isValid = false
      errors.push('end date')
    }

    if (!startTimeRef.current.value || startTimeRef.current.value.length === 0) {
      isValid = false
      errors.push('start time')
    }

    if (!endTimeRef.current.value || endTimeRef.current.value.length === 0) {
      isValid = false
      errors.push('end time')
    }

    // Check that the endDate is after startDate
    if (startDateRef.current.value && endDateRef.current.value && startTimeRef.current.value && endTimeRef.current.value) {
      const endIsAfterStart = moment(`${endDateRef.current.value}T${endTimeRef.current.value}`).isAfter(moment(`${startDateRef.current.value}T${startTimeRef.current.value}`))
      if (!endIsAfterStart) {
        isValid = false
        errors.push('a start date which is before the end date')
      }
    }

    if (!isValid) setError(`Please provide ${errors.join(', ').replace(/, ([^,]*)$/, ' and $1')}.`)
    return isValid
  }

  async function addDate(e) {
    e.preventDefault()
    setIsAdding(true)

    if (!checkFieldsAreValid()) {
      setIsAdding(false)
      return null
    }

    const startDateTime = moment(`${startDateRef.current.value}T${startTimeRef.current.value}`).toDate()
    const endDateTime = moment(`${endDateRef.current.value}T${endTimeRef.current.value}`).toDate()

    await addNewDate(startDateTime, endDateTime)
  }

  return (
    <div className='events__modal__add__wrapper'>
      <h2>Add a new event date</h2>

      <p className='p--info'>
        If your event has non-consecutive dates, add them as separate dates.
      </p>

      <form onSubmit={addDate}>
        <div className='margin-top input--two'>
          <div>
            <label className='margin-top' htmlFor='startDate'>Start date</label>
            <input type="date" ref={startDateRef} id='startDate' name='startDate' disabled={isAdding} />
          </div>

          <div>
            <label className='margin-top' htmlFor='endDate'>End date</label>
            <input type="date" ref={endDateRef} id='endDate' name='endDate' disabled={isAdding} />
          </div>
        </div>

        <div className='margin-top input--two'>
          <div>
            <label className='margin-top' htmlFor='startTime'>Start time</label>
            <input type="time" ref={startTimeRef} id='startTime' name='startTime' disabled={isAdding} />
          </div>

          <div>
            <label className='margin-top' htmlFor='endTime'>End time</label>
            <input type="time" ref={endTimeRef} id='endTime' name='endTime' disabled={isAdding} />
          </div>
        </div>

        {!!error && <ErrorMessage error={error} />}

        <div className='margin-top--2'>
          {isAdding === true ? <button disabled>Add date</button> : <input type="submit" value="Add date" className='button' />}
          <button className='button-secondary margin-left' onClick={closeModal} disabled={isAdding}>Cancel</button>
        </div>
      </form>
    </div>
  )
}

const DeleteDateModal = ({ closeModal, dateBeingDeleted, deleteDate }) => {
  const [isDeleting, setIsDeleting] = useState(false)

  if (!dateBeingDeleted || !dateBeingDeleted.start || !dateBeingDeleted.end) closeModal()

  const startDate = moment(dateBeingDeleted?.start).format("DD MMM YYYY HH:mm")
  const endDate = moment(dateBeingDeleted?.end).format("DD MMM YYYY HH:mm")

  async function removeDate() {
    setIsDeleting(true)
    await deleteDate()
  }

  return (
    <div className='events__modal__add__wrapper'>
      <h2>Delete an event date</h2>
      <p>Are you sure you would like to remove <strong>{startDate} - {endDate}</strong> from this event?</p>
      <button onClick={removeDate} disabled={isDeleting}>Delete</button>
      <button className='button-secondary margin-left' onClick={closeModal} disabled={isDeleting}>Cancel</button>
    </div>
  )
}

export default EventDatePlanner
