import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'

const LocalNav = ({ sections, updateFilter, hideAllLink, defaultLink, allLinkTitle='All products' }) => {
  const [links, setLinks] = useState()
  const { hash: tabToView } = useLocation()

  useEffect(() => {
    const linksFromSections = sections.map(s => {
      return {
        display_name: s.title,
        href: s.title.toLowerCase().split(' ').join('_'),
      }
    })

    if (hideAllLink) {
      setLinks([...linksFromSections])
    } else {
      const allProductsLink = {
        display_name: allLinkTitle,
        href: 'all'
      }
  
      setLinks([allProductsLink, ...linksFromSections])
    }
  }, [sections, hideAllLink, allLinkTitle])

  function changeViewingTab(category) {
    window.location.hash = category
    updateFilter({ category: category })
  }

  if (!links) {
    return null
  }

  function getLinkClass(link) {
    return classNames({
      'is-active': link === tabToView.replace('#', '') || (!tabToView.replace('#', '') && (link === 'all' || link === defaultLink)),
    })
  }

  return (
    <nav className="local-nav margin-top--2">
      <ul>
        {links.map(l => {
          return <li key={l.href} onClick={() => changeViewingTab(l.href)} className={getLinkClass(l.href)}>{l.display_name}</li>
        })}
      </ul>
    </nav>
  )
}

export default LocalNav
