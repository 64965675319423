import React, { useRef, useState } from 'react'
import { Auth } from 'aws-amplify'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'

import ErrorMessage from '../common/ErrorMessage'
import Password from './Password'
import Email from './Email'

const PasswordReset = ({ setLoggedInUser, amplifyUser, isFirstTimePasswordReset, hasForgottenPassword, setHasForgottenPassword}) => {

  const [noPasswordResetCode, setNoPasswordResetCode] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [noUsername, setNoUsername] = useState(false)
  const [noPassword, setNoPassword] = useState(false)
  const [hasRequestedPasswordResetCode, setHasRequestedPasswordResetCode] = useState(false)
  const [hasInputPasswordResetCode, setHasInputPasswordResetCode] = useState(false)
  const [hasSuccessfullyResetPassword, setHasSuccessfullyResetPassword] = useState(false)
  const [passwordResetEmail, setPasswordResetEmail] = useState(false)
  const [passwordResetCode, setPasswordResetCode] = useState(false)
  const resetPasswordRef = useRef()
  const userNameRef = useRef()
  const passwordResetCodeRef = useRef()
  const [error, setError] = useState(null)
  const history = useHistory()


  const resetPassword = async () => {

    const isPasswordEmpty = (password) => {
      if (!password) {
        setNoPassword(true)
        setIsProcessing(false)
        return true
      }
      return false
    }

    if (hasForgottenPassword) {
      
      if (isPasswordEmpty(resetPasswordRef.current.value)) {
        return
      }

      return await Auth.forgotPasswordSubmit(passwordResetEmail, passwordResetCode, resetPasswordRef.current.value)
      .then(() => {
        setHasSuccessfullyResetPassword(true)
      })
      .catch(err => {
        console.log(err)
        if (err.code === "InvalidPasswordException") {
          setError("Your password must be at least 8 characters long, contain at least one number, one special character and have a mixture of uppercase and lowercase letters.")
        }
        setIsProcessing(false)
      })
    }

    if (isFirstTimePasswordReset) {

      if (isPasswordEmpty(resetPasswordRef.current.value)) {
        return
      }
      
      return await Auth.completeNewPassword(amplifyUser, resetPasswordRef.current.value)
      .then(() => {
        setLoggedInUser()
        history.push('/')
      })
      .catch(err => {
        if (err.code === "InvalidPasswordException") {
          setError("Your password must be at least 8 characters long, contain at least one number, one special character and have a mixture of uppercase and lowercase letters.")
        }
        setIsProcessing(false)
      })
    }
  }

  const   requestPasswordReset = () => {
    setError(null)
    const email = userNameRef.current.value
    if (!email) {
      setNoUsername(true)
      setIsProcessing(false)
      return
    }
    setPasswordResetEmail(email)

    Auth.forgotPassword(email)
    .then(() => {
      setHasRequestedPasswordResetCode(true)
    })
    .catch(err => {
      console.log(err)
      
      if (err.code === "UserNotFoundException") {
        setError("Email address not recognised")
      }
    });
  }

  const inputPasswordResetCode = () => {
    const code = passwordResetCodeRef.current.value
    if (!code) {
      setNoPasswordResetCode(true)
      setIsProcessing(false)
      return
    }
    setPasswordResetCode(code)
    setHasInputPasswordResetCode(true)
  }

  if (hasSuccessfullyResetPassword) {
    return (
      <>
        <h1 className="margin-bottom">Password reset successfully</h1>
        <div>
          <button onClick={() => setHasForgottenPassword(false)} >Go to login</button>
        </div>
      </>
    )
  }

  if (isFirstTimePasswordReset || hasInputPasswordResetCode) {
    return (
      <>
        <h1 className="margin-bottom">Reset your password</h1>
        <ErrorMessage error={error} headingText="Failed to reset your pasword" />
        <div className="login-form">
          <Password passwordRef={resetPasswordRef} noPassword={noPassword} isProcessing={isProcessing} />
          <button onClick={resetPassword} data-test="reset-password-button" disabled={isProcessing} >Reset password</button>
        </div>
      </>
    )
  }

  if (hasRequestedPasswordResetCode) {
    return (
      <>
        <h1 className="margin-bottom">Reset password</h1>
        <ErrorMessage error={error} headingText="Failed to reset your pasword" />
        <div className="login-form">
          <p>We sent you a code to reset your password. Please check your inbox and enter it here.</p>
          <PasswordResetCode passwordResetCodeRef={passwordResetCodeRef} noPasswordResetCode={noPasswordResetCode} isProcessing={isProcessing} />
          <button onClick={inputPasswordResetCode} data-test="submit-password-reset-button" disabled={isProcessing} >Submit password reset code</button>
        </div>
      </>
    )
  }

  return (
    <>
      <h1 className="margin-bottom">Reset your password</h1>
      <ErrorMessage error={error} headingText="Failed to request password reset" />
      <div className="request-password-reset-form">
        <Email emailRef={userNameRef} noEmail={noUsername} isProcessing={isProcessing} />
        <button onClick={requestPasswordReset} data-cy="request-password-reset-button" disabled={isProcessing} className='float-left' >Request password reset</button>
      </div>
    </>
  )

}

const PasswordResetCode = ({ passwordResetCodeRef, noPasswordResetCode, isProcessing }) => {
  const errorClass = classNames('input-error', 'margin-top--half', {
    'display-none': !noPasswordResetCode,
  })

  return (
    <>
      <label htmlFor="passwordResetCode">Password reset code</label>
      <input type="text" id="passwordResetCode" name="passwordResetCode" className="margin-bottom" data-cy="password-reset-code-input" ref={passwordResetCodeRef} disabled={isProcessing} />
      <span className={errorClass} data-cy="no-password-reset-code-error">Please enter your password reset code</span>
    </>
  )
}

export default PasswordReset
