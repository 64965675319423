import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"

const ButtonWithDropdown = ({ children, label, icon = faChevronDown }) => {
    const [isOpen, setIsOpen] = useState(false)

    const drop = useRef()

    function handleClick(e) {
        if (!e.target.closest(`.${drop.current.className}`) && isOpen) {
            setIsOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    });

    return (
        <div className="button-with-dropdown" ref={drop}>
            <div className="button-with-dropdown-group">
                <span>{label}</span>
                <button onClick={() => setIsOpen(!isOpen)}><FontAwesomeIcon icon={icon} /></button>
            </div>

            {isOpen ?
                (
                    <div className="button-with-dropdown-body">
                        {children}
                    </div>
                )
                :
                null
            }
        </div>
    )
}

export default ButtonWithDropdown
