import { Auth } from 'aws-amplify';

export const getIdToken = async () => {
    return (await Auth.currentSession()).getIdToken().getJwtToken()
}

export const refreshToken = async () => {
    return new Promise(async (resolve, reject) => {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const { refreshToken } = cognitoUser.getSignInUserSession();
        cognitoUser.refreshSession(refreshToken, (err, session) => {
            if (err) {
                reject(err)
            } else{
                resolve(session)
            }
        })
    })
}