import React from 'react'
import classNames from 'classnames'


const Email = ({ emailRef, noEmail, isProcessing}) => {
    const errorClass = classNames('input-error', 'margin-top--half', {
      'display-none': !noEmail,
    })
  
    return (
      <>
        <label htmlFor="email">Email</label>
        <input type="text" id="email" name="email" className="margin-bottom" data-cy="sign-in-email-input" ref={emailRef} disabled={isProcessing} />
        <span className={errorClass} data-cy="no-email-error">Email is required</span>
      </>
    )
  }

  export default Email