export function getRecommendedSizes(category) {
  const defaultTickets = ['One size']
  
  if (!category) return defaultTickets
  
  if (category.toLowerCase() === 'tickets') {
    return ['Adult', 'Senior', 'Student', 'Child', 'VIP', 'Early bird']
  } else if (category.toLowerCase() === 'drinks') {
    return ['Small', 'Medium', 'Large', 'Pint', 'Half pint', 'Bottle', 'Can']
  }

  return defaultTickets
}