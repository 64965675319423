import moment from "moment"
import convertPriceToString from "./convertPriceToString"

export const CSV_TYPES = {
    STANDARD: 'standard',
    XERO: 'xero',
}

export const generateCsv = (type, data) => {
    if (type === CSV_TYPES.STANDARD) return generateStandardCsv(data)
    if (type === CSV_TYPES.XERO) return generateXeroCsv(data)
    throw Error('Could not generate CSV - invalid type provided.')
}

const getTotalCost = (o) => {
    return o.bookingFee ? o.totalCost + o.bookingFee : o.totalCost
}

const getOutstandingBalance = (o) => {
    if (!o.totalPaid) return 0
    return getTotalCost(o) - o.totalPaid
}

const generateStandardCsv = (data) => {
    const csvData = []
    csvData.push(["Order ID", "Customer name", "Phone number", "Email", "Address", "Date booked", "Event date", "Item", "Quantity", "Total due", "Amount paid", "Booking fee paid", "Outstanding", "Balance paid"])
    data.forEach(o => {
        let isFirstInOrder = true

        Object.values(o.items).forEach(i => {
            csvData.push([
                o.preorderId, // Order ID
                isFirstInOrder ? o.customer.name : "", // Customer name
                isFirstInOrder ? o.customer.phone : "", // Phone number
                isFirstInOrder ? o.customer.email : "", // Email
                isFirstInOrder ? "" : "", // Address
                isFirstInOrder ? moment(new Date(o.orderPlacedDateTimeUtc * 1000)).format("DD/MM/YYYY") : "", // Date booked
                isFirstInOrder ? moment(new Date(o.eventDetails?.eventDateTime[0]?.start)).format("DD/MM/YYYY") : "", // Event date
                `${i.product.name} - ${i.size.name}`, // Item
                i.quantity, // Quantity
                isFirstInOrder ? convertPriceToString(getTotalCost(o)) : "", // Total due
                isFirstInOrder ? convertPriceToString(o.totalPaid ? o.totalPaid : getTotalCost(o)) : "", // Amount paid
                isFirstInOrder ? convertPriceToString(o.bookingFee ?? 0) : "", // Booking fee paid
                isFirstInOrder ? convertPriceToString(getOutstandingBalance(o)) : "", // Outstanding
                isFirstInOrder ? o.status === 'PAID' : "" // Balance paid
            ])

            isFirstInOrder = false
        });
    });

    return csvData
}

const generateXeroCsv = (data) => {
    const csvData = []
    csvData.push([
        "ContactName", "EmailAddress", "POAddressLine1", "POAddressLine2", "POAddressLine3", "POAddressLine4",
        "POCity", "PORegion", "POPostalCode", "POCountry", "InvoiceNumber", "Reference", "InvoiceDate", "DueDate",
        "Total", "InventoryItemCode", "Description", "Quantity", "UnitAmount", "Discount", "AccountCode", "TaxType",
        /*"TaxAmount", */"TrackingName1", "TrackingOption1", "TrackingName2", "TrackingOption2", "Currency", "BrandingTheme",
    ])

    const allPayments = []

    data.forEach(o => {
        const paymentsArray = o?.payments || []
        Object.values(paymentsArray).forEach(p => {
            Object.values(p.items).forEach(i => {
                // If this is a TOPUP payment, we need to offset the previously paid amount
                const amountPaidForItem = 
                    Object.values(p.items)
                        .filter(item => item.productId === i.productId)
                        .reduce((accumulator, currentValue) => accumulator + Number(currentValue.unitCostIncVat), 0)

                if (i.totalCostIncVat > 0 && amountPaidForItem > 0) {
                    allPayments.push({
                        contactName: "Christmas Party 2023",
                        emailAddress: o.customer.email ?? "",
                        invoiceId: p.invoiceId ?? "",
                        reference: o.customer.name ?? "",
                        invoiceDate: `${new Date(p.paymentDate).toLocaleDateString()}`,
                        dueDate: moment(new Date(o.eventDetails?.eventDateTime[0]?.start)).format("DD/MM/YYYY"),
                        total: convertPriceToString(p.totalIncVat),
                        description: i.description,
                        quantity: i.quantity,
                        unitAmount: convertPriceToString(amountPaidForItem),
                        accountCode: "13615", // TODO - hardcoded to TP
                        taxType: "20% (VAT on Income)", // TODO - hardcoded
                        currency: "GBP",
                        paymentDate: p.paymentDate, // Used to sort all the orders
                    })
                }
            })
        })
    })

    // Sort all the payments in order of date paid
    allPayments.sort((a, b) => b.paymentDate - a.paymentDate).reverse().forEach(p => {
        csvData.push(generateXeroRow(p))
    });

    return csvData
}

const generateXeroRow = (rowData) => {
    return [
        rowData.contactName, // Contact name
        rowData.emailAddress, // Email address
        "", // POAddressLine1
        "", // POAddressLine2
        "", // POAddressLine3
        "", // POAddressLine4
        "", // POCity
        "", // PORegion
        "", // POPostalCode
        "", // POCountry
        rowData.invoiceId, // InvoiceNumber
        rowData.reference, // Reference
        rowData.invoiceDate, // InvoiceDate
        rowData.dueDate, // DueDate
        "", // Total
        "", // InventoryItemCode
        rowData.description, // Description
        rowData.quantity, // Quantity
        rowData.unitAmount, // UnitAmount
        "", // Discount
        rowData.accountCode, // AccountCode
        rowData.taxType, // TaxType
        /*"", // TaxAmount*/
        "", // TrackingName1
        "", // TrackingOption1
        "", // TrackingName2
        "", // TrackingOption2
        rowData.currency, // Currency
        "", // BrandingTheme
    ]
}
