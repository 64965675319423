import { faEquals, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

const IncomeStats = ({ orders }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [todayIncome, setTodayIncome] = useState(0)
  const [weekIncome, setWeekIncome] = useState(0)
  const [monthIncome, setMonthIncome] = useState(0)

  useEffect(() => {
    let todayIncomeLocal = 0
    let weekIncomeLocal = 0
    let monthIncomeLocal = 0

    orders.forEach(order => {
      if (order.orderStatus.toLowerCase() === 'completed') {
        const ordersDatePlaced = new Date(order.orderPlacedDateTimeUtc * 1000)
        const isTodaysOrder = ordersDatePlaced.toDateString() === new Date().toDateString()
        if (isTodaysOrder) todayIncomeLocal += order.totalCost

        const date7DaysAgo = new Date().setDate(new Date().getDate() - 7)
        const date30DaysAgo = new Date().setDate(new Date().getDate() - 30)
        if (order.orderPlacedDateTimeUtc * 1000 > date7DaysAgo) weekIncomeLocal += order.totalCost
        if (order.orderPlacedDateTimeUtc * 1000 > date30DaysAgo) monthIncomeLocal += order.totalCost
      }
    })

    setTodayIncome(todayIncomeLocal)
    setWeekIncome(weekIncomeLocal)
    setMonthIncome(monthIncomeLocal)
    setIsLoading(false)
  }, [orders])

  return (
    <div className="income-stats-wrapper">
      <StatSection income={todayIncome} timeSpan="Today" isLoading={isLoading} />
      <StatSection income={weekIncome} timeSpan="Last 7 days" isLoading={isLoading} />
      <StatSection income={monthIncome} timeSpan="Last 30 days" isLoading={isLoading} />
    </div>
  )
}

const StatSection = ({ income = 0, timeSpan, isLoading }) => {
  function getIcon() {
    if (income === 0) return faEquals
    else if (income > 0) return faSortUp
    else return faSortDown
  }

  const iconClass = classNames('icon-bg', {
    'icon-bg--income': income > 0,
    'icon-bg--loss': income < 0,
    'icon-bg--zero': income === 0,
  })

  if (isLoading) {
    return (
      <div className="stat-section">
        <div className="icon-bg"></div>
        <div className="stats-wrapper">
          <span className="income--skeleton"></span>
          <span className="time-span--skeleton"></span>
        </div>
      </div>
    )
  }

  return (
    <div className="stat-section">
      <div className={iconClass}><FontAwesomeIcon icon={getIcon()} /></div>
      <div className="stats-wrapper">
        <span className="income">£{(parseInt(income) / 100).toFixed(2)}</span>
        <span className="time-span">{timeSpan}</span>
      </div>
    </div>
  )
}

export default IncomeStats
