import React, { useEffect, useState, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faBox, faCopy } from '@fortawesome/free-solid-svg-icons'
import { LocationClient } from "@food2room/clients"

import Loading from '../common/Loading'
import userContext from '../context/userContext'
import config from '../../config'
import Card from '../common/Card'
import Hero from '../common/Hero'
import './locations.scss'

const LocationsPage = ({ }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [locations, setLocations] = useState({})

  const { locationIds, setSelectedLocationId } = useContext(userContext)

  const getLocationData = (locationId) => {
    LocationClient.setBaseUrl(config.baseOrderUrl)
    return LocationClient.getLocation(locationId)
      .then(res => setLocations(prev => {
        const newLocation = { ...prev }
        newLocation[locationId] = res
        return newLocation
      }))
  }

  useEffect(() => {
    // Reset page URL to the origin
    if (window.location.pathname !== '/') {
      window.location.href = window.location.origin
    }
  }, [])

  useEffect(() => {
    Promise.all(locationIds.map(getLocationData)).then(() => setIsLoading(false))
  }, [locationIds])

  const LocationsList = () => {
    if (!locations || locations.length === 0) return <p>No locations to show</p>

    return (
      <div className="locations-list grid grid__1 margin-top">
        {Object.values(locations).map(l => {
          let button =
            <button
              className='button-secondary button-card'
              onClick={() => setSelectedLocationId(l.locationId)}
            >
              Select location
              <FontAwesomeIcon icon={faArrowRight} />
            </button>

          const getActiveMenus = () => {
            const allMenus = Object.values(l.menus)
            return allMenus.length > 0 ? allMenus.filter(menu => menu.active).length : 0
          }

          return (
            <Card
              key={l.locationId}
              title={l.name}
              actionButtons={[button]}
            >
              <div className='location-card-body'>
                <span><FontAwesomeIcon icon={faCopy} /> {getActiveMenus()} active menus</span>
                <span><FontAwesomeIcon icon={faBox} /> {Object.keys(l.products).length} products</span>
              </div>
            </Card>
          )
        }
        )}
      </div>
    )
  }

  if (isLoading) return <Loading fullscreen={true} />

  return (
    <div className="page location-page">
      <Hero
        section=""
        title="Your locations"
        subtitle="Select which location you want to manage."
      />

      <div className="body menus-page location-body">
        <LocationsList />
      </div>
    </div>
  )
}

export default LocationsPage
