import React, { createContext, useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const DialogsContext = createContext();
DialogsContext.displayName = 'DialogsContext';

export const DIALOG_JOURNEYS = {
    EVENT_ADD_DATA_FIELD: 'event-add-data-field',
    EVENT_DELETE_DATA_FIELD: 'event-delete-data-field',
    EVENT_FILTER_SALES_DATE: 'event-filter-sales-date',
    EVENT_FILTER_SALES_ITEM: 'event-filter-sales-item',
    EVENT_SALE_PAYMENTS: 'event-sale-payments',
}

const DialogsContextProvider = ({ children }) => {
    const [currentDialog, setCurrentDialog] = useState()

    const onSuccessCallback = useRef()
    const dataRef = useRef()

    const startDialogJourney = (journey, onSuccess, data) => {
        if (onSuccess) onSuccessCallback.current = onSuccess
        if (data) dataRef.current = data
        setCurrentDialog(journey);
    };

    const cancelDialogJourney = () => {
        onSuccessCallback.current = null
        setCurrentDialog(null)
    }

    return (
        <DialogsContext.Provider
            value={{
                currentDialog,
                startDialogJourney,
                cancelDialogJourney,
                onSuccessCallback: onSuccessCallback.current,
                data: dataRef.current,
            }}
        >
            {children}
        </DialogsContext.Provider>
    );
};

DialogsContextProvider.propTypes = {
    children: PropTypes.node.isRequired
};

const useDialogsContext = () => useContext(DialogsContext);

export { DialogsContext, DialogsContextProvider };
export default useDialogsContext;
