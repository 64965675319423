import { createContext, useEffect, useState } from 'react'
import { LocationClient } from "@food2room/clients"
import cloneDeep from 'lodash.clonedeep'

import { getIdToken } from '../../../util/auth'
import config from '../../../config'
import axios from 'axios'

const Context = createContext({})

const Provider = ({ eventId, locationId, children }) => {
  const [event, setEvent] = useState({})
  const [warnings, setWarnings] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    LocationClient.setBaseUrl(config.baseOrderUrl)

    getIdToken().then(token => {
      LocationClient.getMenu(locationId, eventId, token).then(eventDetails => {
        setEvent(eventDetails)
        setIsLoading(false)
      })
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (event && Object.keys(event).length > 0) {
      // Check for warnings
      const warnings = []

      const outOfStockItems = Object.values(event.items).filter(i => i.product.available === false)
      if (outOfStockItems.length > 0) warnings.push(`${outOfStockItems.length} product${outOfStockItems.length > 1 ? 's' : ''} out of stock`)

      if (!event.active) warnings.push('Inactive event')

      if (event.menuType?.toLowerCase() === 'event') {
        if (!event?.eventDetails?.eventDateTime || event?.eventDetails?.eventDateTime?.length === 0) {
          warnings.push('Event has no dates')
        }
      }

      setWarnings([...warnings])
    }
  }, [event])

  async function updateMenuDetails(detailsToUpdate) {
    try {
      await LocationClient.updateMenu(locationId, event.id, detailsToUpdate, await getIdToken())
    } catch (error) {
      throw new Error(error)
    }
  }

  async function updateEventName(newName) {
    try {
      await updateMenuDetails({ name: newName })
      setEvent({ ...event, name: newName })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function updateEventDescription(newDescription) {
    try {
      await updateMenuDetails({ description: newDescription })
      setEvent({ ...event, description: newDescription })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function updateEventEmailMessage(newMessage) {
    try {
      await updateMenuDetails({ emailMessage: newMessage })
      setEvent({ ...event, emailMessage: newMessage })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function updateEventActive(isActive) {
    try {
      await updateMenuDetails({ active: isActive })
      setEvent({ ...event, active: isActive })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function updateEventContactAddress(newValue) {
    // Note: contact details need to be sent as a whole
    const newContactDetails = cloneDeep(event.eventDetails)
    newContactDetails.eventLocation = newValue

    try {
      await updateMenuDetails({ eventDetails: newContactDetails })
      setEvent({ ...event, eventDetails: newContactDetails })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function updateEventContactName(newValue) {
    // Note: contact details need to be sent as a whole
    const newContactDetails = cloneDeep(event.eventDetails)
    newContactDetails.contactName = newValue

    try {
      await updateMenuDetails({ eventDetails: newContactDetails })
      setEvent({ ...event, eventDetails: newContactDetails })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function updateEventContactPhone(newValue) {
    // Note: contact details need to be sent as a whole
    const newContactDetails = cloneDeep(event.eventDetails)
    newContactDetails.contactPhone = newValue

    try {
      await updateMenuDetails({ eventDetails: newContactDetails })
      setEvent({ ...event, eventDetails: newContactDetails })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function updateEventContactEmail(newValue) {
    // Note: contact details need to be sent as a whole
    const newContactDetails = cloneDeep(event.eventDetails)
    newContactDetails.contactEmail = newValue

    try {
      await updateMenuDetails({ eventDetails: newContactDetails })
      setEvent({ ...event, eventDetails: newContactDetails })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function updateEventDeleteDate(dateBeingDeleted) {
    const eventDetailsCopy = cloneDeep(event.eventDetails)
    const indexOfDate = eventDetailsCopy.eventDateTime.findIndex(d => dateBeingDeleted.start === d.start && dateBeingDeleted.end === d.end)
    if (indexOfDate > -1) {
      eventDetailsCopy.eventDateTime.splice(indexOfDate, 1)
      try {
        await LocationClient.updateMenu(locationId, event.id, { eventDetails: eventDetailsCopy }, await getIdToken())
        setEvent({ ...event, eventDetails: eventDetailsCopy })
      } catch (error) {
        throw new Error(error)
      }
    }
  }

  async function updateEventAddDate(startDateTime, endDateTime) {
    const eventDetailsCopy = cloneDeep(event.eventDetails)
    if (!eventDetailsCopy.eventDateTime) eventDetailsCopy.eventDateTime = []
    eventDetailsCopy.eventDateTime.push({ start: startDateTime, end: endDateTime })

    try {
      await LocationClient.updateMenu(locationId, event.id, { eventDetails: eventDetailsCopy }, await getIdToken())
      setEvent({ ...event, eventDetails: eventDetailsCopy })
    } catch (error) {
      throw new Error(error)
    }
  }

  function removeProductFromEventLocally(productId) {
    delete event.items[productId]
  }

  async function updateEventHeroImage(file) {
    const allowedFileTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif']
    if (!file || !file.type || !allowedFileTypes.includes(file.type)) throw new Error('Unsupported file format.')

    try {
      const fileType = file.type
      const fileName = `${event.id}-hero.png`
      const assetDto = { contentType: fileType, fileName: fileName }
      const signedUrl = await LocationClient.createAssetUrl(locationId, assetDto, await getIdToken())

      await axios.put(
        signedUrl.uploadUrl,
        file,
        {
          headers: {
            'accept': 'application/json',
            'Content-Type': fileType,
          }
        }
      )

      const eventDetailsCopy = cloneDeep(event.image) || {}
      eventDetailsCopy.coverUrl = signedUrl.uploadUrl.split(fileName)[0] + fileName

      await updateMenuDetails({ image: eventDetailsCopy })
      setEvent({ ...event, image: eventDetailsCopy })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function getPreordersForEvent() {
    try {
      const res = await axios.get(`${config.baseOrderUrl}/locations/${locationId}/preorders?menuId=${event.id}`, { headers: { "Authorization": await getIdToken() } })
      return res.data
    } catch (error) {
      throw new Error(error)
    }
  }

  async function addEventCustomDataField(newField) {
    try {
      // Note: custom data fields need to be sent as a whole
      const dataFields = event.customDataFields ? cloneDeep(event.customDataFields) : []
      dataFields.push(newField)

      await updateMenuDetails({ customDataFields: dataFields })
      setEvent({ ...event, customDataFields: dataFields })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function deleteEventCustomDataField(id) {
    try {
      // Note: custom data fields need to be sent as a whole
      const dataFields = event.customDataFields ? cloneDeep(event.customDataFields) : []
      const fieldsToKeep = dataFields.filter(f => f.id !== id)

      await updateMenuDetails({ customDataFields: fieldsToKeep })
      setEvent({ ...event, customDataFields: fieldsToKeep })
    } catch (error) {
      throw new Error(error)
    }
  }

  const contextExports = {
    event,
    warnings,
    isLoading,
    updateEventName,
    updateEventDescription,
    updateEventEmailMessage,
    updateEventActive,
    updateEventContactAddress,
    updateEventContactName,
    updateEventContactPhone,
    updateEventContactEmail,
    updateEventDeleteDate,
    updateEventAddDate,
    removeProductFromEventLocally,
    updateEventHeroImage,
    getPreordersForEvent,
    addEventCustomDataField,
    deleteEventCustomDataField,
  }

  return <Context.Provider value={contextExports}>{children}</Context.Provider>
}

const { Consumer } = Context

export const EventContext = Context
export const EventContextProvider = Provider
export const EventContextConsumer = Consumer