import React, { useState, useContext, useRef } from 'react'
import { toast } from 'react-toastify'
import { LocationClient } from "@food2room/clients"
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import cloneDeep from 'lodash.clonedeep'

import userContext from '../context/userContext'
import { getIdToken } from '../../util/auth'
import ReactSwitch from 'react-switch'
import { getMenuTypes } from '../../util/menuTypes'

const MenuSettings = ({ menu, updateMenu }) => {
  return (
    <div className="margin-top">
      <div className='menu__settings__layout'>
        <div className='menu__settings__left'>
          <h2>Menu details</h2>
          <MenuDetails name={menu.name} menuId={menu.id} updateMenu={updateMenu} description={menu.description} menuType={menu.menuType} emailMessage={menu.emailMessage} />
          {
            menu?.menuType?.toLowerCase() !== 'event'
              ?
              <Availability availability={menu.activePeriods} updateMenu={updateMenu} menuId={menu.id} isMenuActive={menu.active} />
              :
              <div className='margin-top'>
                <h2>Active</h2>
                <ActiveToggle isActive={menu.active} updateMenu={updateMenu} menuId={menu.id} />
              </div>
          }
          {
            menu?.menuType?.toLowerCase() !== 'event'
              ?
              null
              :
              <div className='margin-top'>
                <CustomerContactDetails eventDetails={menu.eventDetails} menuId={menu.id} updateMenu={updateMenu} />
              </div>
          }
          <DeleteMenu menuId={menu.id} />
        </div>
      </div>
    </div>
  )
}

const MenuDetails = ({ name, description, menuType, menuId, updateMenu, emailMessage }) => {
  const { selectedLocationId } = useContext(userContext)
  const [isUpdating, setIsUpdating] = useState(false)

  const nameRef = useRef()
  const descriptionRef = useRef()
  const typeRef = useRef()
  const emailMessageRef = useRef()

  async function updateMenuDetails() {
    // TODO - max length
    setIsUpdating(true)

    const updatedMenu = {
      name: nameRef.current.value,
      description: descriptionRef.current.value,
      menuType: typeRef.current.getValue()[0].value,
      emailMessage: emailMessageRef.current.value,
    }

    await LocationClient.updateMenu(selectedLocationId, menuId, updatedMenu, await getIdToken())
      .catch(err => {
        console.error(err)
        toast.error('Could not update the menu, try again.')
        setIsUpdating(false)
        return
      })
    toast.success('Menu updated.')
    setIsUpdating(false)
    updateMenu()
  }

  return (
    <>
      <label htmlFor="type">Type</label>
      <div className="multi-select">
        <Select
          isMulti={false}
          id="type"
          ref={typeRef}
          isDisabled={isUpdating}
          options={getMenuTypes()}
          defaultValue={getMenuTypes()[getMenuTypes().findIndex(c => c.value === menuType)]}
        />
      </div>

      <label htmlFor="mname">Name</label>
      <input id="mname" name="mname" type="text" defaultValue={name} ref={nameRef} disabled={isUpdating} />

      <label htmlFor="fdescription">Description</label>
      <textarea id="fdescription" name="fdescription" defaultValue={description} ref={descriptionRef} disabled={isUpdating} />

      <label htmlFor="emailMessage">Order confirmation email message</label>
      <textarea id="emailMessage" name="emailMessage" defaultValue={emailMessage} ref={emailMessageRef} disabled={isUpdating} />

      <button onClick={updateMenuDetails} disabled={isUpdating}>Update menu</button>
    </>
  )
}

const CustomerContactDetails = ({ eventDetails, menuId, updateMenu }) => {
  const [isUpdating, setIsUpdating] = useState(false)
  const { selectedLocationId } = useContext(userContext)

  const { contactName, contactEmail, contactPhone, eventLocation } = eventDetails

  const contactNameRef = useRef()
  const contactPhoneRef = useRef()
  const contactEmailRef = useRef()
  const contactAddressRef = useRef()

  async function updateDetails() {
    setIsUpdating(true)

    const updatedMenu = {
      eventDetails: {
        contactName: contactNameRef.current.value,
        contactEmail: contactEmailRef.current.value,
        contactPhone: contactPhoneRef.current.value,
        eventLocation: contactAddressRef.current.value,
        eventDateTime: cloneDeep(eventDetails.eventDateTime)
      }
    }

    await LocationClient.updateMenu(selectedLocationId, menuId, updatedMenu, await getIdToken())
      .catch(err => {
        console.error(err)
        toast.error('Could not update the contact details, try again.')
        setIsUpdating(false)
        return
      })
    toast.success('Contact details updated.')
    setIsUpdating(false)
    updateMenu()
  }

  return (
    <>
      <h2>Customer contact details</h2>

      <p className="p--info">
        <b>Note</b><br />
        These fields will be visible to the users when they're placing their order.
      </p>

      <label htmlFor='faddress'>Event address</label>
      <input type="text" id="faddress" name="faddress" ref={contactAddressRef} defaultValue={eventLocation} disabled={isUpdating} />

      <label htmlFor='fcontactName'>Event contact name (optional)</label>
      <input type="text" id="fcontactName" name="fcontactName" ref={contactNameRef} defaultValue={contactName} disabled={isUpdating} />

      <label htmlFor='fcontactPhone'>Event contact phone number (optional)</label>
      <input type="text" id="fcontactPhone" name="fcontactPhone" ref={contactPhoneRef} defaultValue={contactPhone} disabled={isUpdating} />

      <label htmlFor='fcontactEmail'>Event contact email (optional)</label>
      <input type="text" id="fcontactEmail" name="fcontactEmail" ref={contactEmailRef} defaultValue={contactEmail} disabled={isUpdating} />

      <button className='margin-top' onClick={updateDetails} disabled={isUpdating}>Update details</button>
    </>
  )
}

const Availability = ({ availability, updateMenu, menuId, isMenuActive }) => {
  const monStart = useRef()
  const monEnd = useRef()
  const tueStart = useRef()
  const tueEnd = useRef()
  const wedStart = useRef()
  const wedEnd = useRef()
  const thuStart = useRef()
  const thuEnd = useRef()
  const friStart = useRef()
  const friEnd = useRef()
  const satStart = useRef()
  const satEnd = useRef()
  const sunStart = useRef()
  const sunEnd = useRef()

  const [isUpdating, setIsUpdating] = useState(false)
  const { selectedLocationId } = useContext(userContext)

  async function updateAvailability() {
    setIsUpdating(true)

    // TODO check if values have been removed
    // TODO - check that values have both a start and an end

    let updateObject = {}
    if (monStart.current.value && monEnd.current.value) { updateObject.monday = { start: monStart.current.value, end: monEnd.current.value } }
    if (tueStart.current.value && tueEnd.current.value) { updateObject.tuesday = { start: tueStart.current.value, end: tueEnd.current.value } }
    if (wedStart.current.value && wedEnd.current.value) { updateObject.wednesday = { start: wedStart.current.value, end: wedEnd.current.value } }
    if (thuStart.current.value && thuEnd.current.value) { updateObject.thursday = { start: thuStart.current.value, end: thuEnd.current.value } }
    if (friStart.current.value && friEnd.current.value) { updateObject.friday = { start: friStart.current.value, end: friEnd.current.value } }
    if (satStart.current.value && satEnd.current.value) { updateObject.saturday = { start: satStart.current.value, end: satEnd.current.value } }
    if (sunStart.current.value && sunEnd.current.value) { updateObject.sunday = { start: sunStart.current.value, end: sunEnd.current.value } }

    await LocationClient.updateMenu(selectedLocationId, menuId, { activePeriods: updateObject }, await getIdToken())
      .catch(err => {
        console.error(err)
        toast.error('Could not update the availability, try again.')
        setIsUpdating(false)
        return
      })
    toast.success('Availability updated.')

    setIsUpdating(false)
    updateMenu()
  }

  return (
    <>
      <h2 className="margin-top">Availability</h2>
      <ActiveToggle isActive={isMenuActive} updateMenu={updateMenu} menuId={menuId} />
      <DayAvailability day="Monday" startRef={monStart} startValue={availability?.monday?.start} endRef={monEnd} endValue={availability?.monday?.end} isUpdating={isUpdating} />
      <DayAvailability day="Tuesday" startRef={tueStart} startValue={availability?.tuesday?.start} endRef={tueEnd} endValue={availability?.tuesday?.end} isUpdating={isUpdating} />
      <DayAvailability day="Wednesday" startRef={wedStart} startValue={availability?.wednesday?.start} endRef={wedEnd} endValue={availability?.wednesday?.end} isUpdating={isUpdating} />
      <DayAvailability day="Thursday" startRef={thuStart} startValue={availability?.thursday?.start} endRef={thuEnd} endValue={availability?.thursday?.end} isUpdating={isUpdating} />
      <DayAvailability day="Friday" startRef={friStart} startValue={availability?.friday?.start} endRef={friEnd} endValue={availability?.friday?.end} isUpdating={isUpdating} />
      <DayAvailability day="Saturday" startRef={satStart} startValue={availability?.saturday?.start} endRef={satEnd} endValue={availability?.saturday?.end} isUpdating={isUpdating} />
      <DayAvailability day="Sunday" startRef={sunStart} startValue={availability?.sunday?.start} endRef={sunEnd} endValue={availability?.sunday?.end} isUpdating={isUpdating} />
      <button className='margin-top' onClick={updateAvailability} disabled={isUpdating}>Update availability</button>
    </>
  )
}

const ActiveToggle = ({ isActive, updateMenu, menuId }) => {
  const [checked, setChecked] = useState(isActive)
  const [isUpdating, setIsUpdating] = useState(false)
  const { selectedLocationId } = useContext(userContext)

  const handleChange = async (nextChecked) => {
    setIsUpdating(true)

    await LocationClient.updateMenu(selectedLocationId, menuId, { active: nextChecked }, await getIdToken())
      .catch(err => {
        console.error(err)
        toast.error('Could not update the availability, try again.')
        setIsUpdating(false)
        return
      })
    toast.success('Availability updated.')

    setChecked(nextChecked)
    setIsUpdating(false)
    updateMenu()
  }

  return (
    <div className="settings-card-wrapper margin-top">
      <div className="settings-card-header">
        <span>Is this menu active?</span>
        <ReactSwitch
          checked={checked}
          onColor={"#7ca870"}
          onChange={handleChange}
          disabled={isUpdating}
        />
      </div>
      <p>Setting a menu as inactive will hide it on the customer facing website, preventing them from being able to order from it.</p>
    </div>
  )
}

const DayAvailability = ({ day, startRef, startValue, endRef, endValue, isUpdating }) => {
  return (
    <>
      <label htmlFor={day}>{day}</label>
      <div className="time-wrapper">
        <label htmlFor={day}>Start</label>
        <input type="time" id={day} name={day} min="00:00" max="23:59" ref={startRef} defaultValue={startValue} disabled={isUpdating} />
      </div>
      <div className="time-wrapper">
        <label htmlFor={day}>End</label>
        <input type="time" id={`${day}-end`} name={`${day}-end`} min="00:00" max="23:59" ref={endRef} defaultValue={endValue} disabled={isUpdating} />
      </div>
    </>
  )
}

const DeleteMenu = ({ menuId }) => {
  const [numberPressed, setNumberPressed] = useState(0)
  const [buttonText, setButtonText] = useState('Delete menu')
  const history = useHistory()

  const { selectedLocationId } = useContext(userContext)

  async function deleteMenu() {
    // TODO - needs a loading indicator

    if (numberPressed === 1) {
      await LocationClient.deleteMenu(selectedLocationId, menuId, await getIdToken())
      // TODO - error handling
      toast.success('Menu deleted.')
      history.push(`/${selectedLocationId}/menus`)
    } else {
      toast.info('Click the button again to confirm deletion.')
      setButtonText(`Click again to confirm deletion`)
      setNumberPressed(numberPressed + 1)
    }
  }

  return (
    <>
      <h2 className="margin-top">Delete menu</h2>
      <p className="p--info">
        <b>Important</b><br />
        Deleting a menu cannot be undone.
      </p>
      <button onClick={deleteMenu} className={numberPressed > 0 ? 'button-warning' : ''}>{buttonText}</button>
    </>
  )
}

export default MenuSettings
