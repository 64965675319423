import Modal from 'react-modal'
import EventAddDataFieldDialog from './components/EventAddDataField.dialog';
import EventDeleteDataFieldDialog from './components/EventDeleteDataField.dialog';
import useDialogsContext from './DialogsContext';
import { DIALOG_JOURNEYS } from './DialogsContext';
import EventFilterSalesDateDialog from './components/EventFilterSalesDate.dialog';
import EventFilterSalesItemDialog from './components/EventFilterSalesItem.dialog';
import EventSalesPaymentsDialog from './components/EventSalesPayments.dialog';

const DialogContainer = () => {
    const { currentDialog } = useDialogsContext();

    const getDialog = () => {
        switch (currentDialog) {
            case DIALOG_JOURNEYS.EVENT_ADD_DATA_FIELD:
                return <EventAddDataFieldDialog />
            case DIALOG_JOURNEYS.EVENT_DELETE_DATA_FIELD:
                return <EventDeleteDataFieldDialog />
            case DIALOG_JOURNEYS.EVENT_FILTER_SALES_DATE:
                return <EventFilterSalesDateDialog />
            case DIALOG_JOURNEYS.EVENT_FILTER_SALES_ITEM:
                return <EventFilterSalesItemDialog />
            case DIALOG_JOURNEYS.EVENT_SALE_PAYMENTS:
                return <EventSalesPaymentsDialog />
            default:
                return null
        }
    }

    return (
        <Modal
            isOpen={!!currentDialog}
            ariaHideApp={false}
            // onRequestClose={closeDeleteModal}
            shouldCloseOnOverlayClick={true}
            className="dialogs--wrapper"
        >
            {getDialog()}
        </Modal>
    );
};

export default DialogContainer;
