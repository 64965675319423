const envs = {
    dev: {
        baseOrderUrl: "https://api.dev.servd.uk",
        orderWebsocketsUrl: "wss://ws.dev.servd.uk",
        cognitoUserPoolId: "eu-west-2_qs9EIgHRM",
        cognitoUserPoolClientId: "3jooi1qbkjufk3snlptvqrle96",
        mockClients: false,
    },
    prod: {
        baseOrderUrl: "https://api.prod.servd.uk",
        orderWebsocketsUrl: "wss://ws.prod.servd.uk",
        cognitoUserPoolId: "eu-west-2_4nL3Lfe7W",
        cognitoUserPoolClientId: "863g2qpgv3uptj8n3o6lu74s9",
        mockClients: false,
    },
    local: {
        baseOrderUrl: process.env.REACT_APP_BASE_ORDER_URL,
        orderWebsocketsUrl: process.env.REACT_APP_ORDER_WEBSOCKETS_URL,
        cognitoUserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        cognitoUserPoolClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
        mockClients: process.env.REACT_APP_MOCK_CLIENTS,
    },
    cypress: {
        baseOrderUrl: "http://localhost:3001",
        orderWebsocketsUrl: "wss://localhost:3002",
        cognitoUserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        cognitoUserPoolClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
        mockClients: false,
    }
}

let config
const stage = process.env.REACT_APP_STAGE

if (window.cypress || window.Cypress) {
    config = envs.cypress
} else if (stage && envs.hasOwnProperty(stage)) {
    config = envs[stage]
} else {
    config = envs.local
}

const configWithDefaults = {
    region: "eu-west-1",
    ...config
}

export default configWithDefaults