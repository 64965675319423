import React, { useContext, useState } from "react";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserContext from "../context/userContext";
import { getIdToken } from '../../util/auth'
import OutsideClickHandler from "react-outside-click-handler/esm/OutsideClickHandler";
import { OrderClient } from "@food2room/clients";

import config from '../../config'

export default function CardMenu({order, processing, setProcessing}) {
  const [showMenu, setShowMenu] = useState(false)
  const { selectedLocationId } = useContext(UserContext)

  const cancelOrder = async (orderId) => {
    if (processing) {
      return
    }
    setProcessing(true)
    OrderClient.setBaseUrl(config.baseOrderUrl)
    await OrderClient.cancelOrder(selectedLocationId, orderId, await getIdToken())
    setShowMenu(false)
  }

  return (
    <span>
      <FontAwesomeIcon icon={faEllipsisV} id='btn-order-options' onClick={() => setShowMenu(!showMenu)} />
      <OutsideClickHandler disabled={!showMenu} onOutsideClick={() => setShowMenu(false)}>
        <div className='actions' style={{ display: showMenu ? 'block' : 'none' }} >
          <div className='btn-action' onClick={() => cancelOrder(order.orderId)}>
            Cancel order
          </div>
          {/*  Edit order, message customer, refund separately? */}
        </div>
      </OutsideClickHandler>
    </span>
  )
}
