import { useState } from 'react'
import ReactSwitch from 'react-switch'
import { toast } from 'react-toastify'

const EventActive = ({ isActive, update }) => {
  const [checked, setChecked] = useState(isActive)
  const [isUpdating, setIsUpdating] = useState(false)

  const handleChange = async (nextChecked) => {
    setIsUpdating(true)

    try {
      await update(nextChecked)
      toast.success('Availability updated.')
      setChecked(nextChecked)
      setIsUpdating(false)
    } catch (error) {
      console.error(error)
      toast.error('Could not update the availability, try again.')
      setIsUpdating(false)
    }
  }

  return (
    <div className="event-details__heading-with-edit margin-bottom">
      <label>Active</label>
      <ReactSwitch
        checked={checked}
        uncheckedIcon={false}
        checkedIcon={false}
        height={20}
        width={35}
        onColor={"#7ca870"}
        onChange={handleChange}
        disabled={isUpdating}
      />
    </div>
  )
}

export default EventActive
