import { useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'

const EventTextValue = ({ value, label, update }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  const inputRef = useRef()

  async function updateValue() {
    setIsUpdating(true)

    if (inputRef.current?.value?.length > 255) {
      toast.error(`${label} cannot be longer than 255 characters. You entered ${inputRef.current.value.length} characters.`)
      setIsUpdating(false)
      return
    }

    try {
      await update(inputRef.current.value)
      toast.success(`${label} updated.`)
      setIsEditing(false)
    } catch (error) {
      console.log(error)
      toast.error(`Failed to update ${label}. Try again.`)
    }

    setIsUpdating(false)
  }

  return (
    <>
      <div className='event-details__heading-with-edit'>
        <label>{label}</label>
        <button onClick={() => setIsEditing(!isEditing)} title='edit'><FontAwesomeIcon icon={faPencilAlt} /></button>
      </div>
      {isEditing && <EditValue value={value} cancelEditing={() => setIsEditing(false)} inputRef={inputRef} updateValue={updateValue} isUpdating={isUpdating} />}
      {!isEditing && <p>{value || 'Not provided'}</p>}
    </>
  )
}

const EditValue = ({ value, cancelEditing, inputRef, updateValue, isUpdating }) => {
  return (
    <div className='margin-bottom'>
      <input type='text' defaultValue={value} className='margin-top margin-bottom' ref={inputRef} disabled={isUpdating} />
      <button onClick={updateValue} disabled={isUpdating}>Update</button>
      <button className='button-secondary margin-left' onClick={cancelEditing} disabled={isUpdating}>Cancel</button>
    </div>
  )
}

export default EventTextValue
