import { useState } from 'react'
import { toast } from 'react-toastify'
import useDialogsContext from '../DialogsContext'
import './eventDeleteDataField.dialog.scss'

const EventDeleteDataFieldDialog = () => {
    const [isMakingRequest, setIsMakingRequest] = useState(false)

    const { cancelDialogJourney, onSuccessCallback, data = {} } = useDialogsContext()

    const onSubmit = async () => {
        setIsMakingRequest(true)

        try {
            await onSuccessCallback(data.id)
            toast.success(`${data.label} removed.`)
            cancelDialogJourney()
        } catch (error) {
            toast.error(`Could not remove ${data.label}. Try again.`)
        } finally {
            setIsMakingRequest(false)
        }
    }

    return (
        <section className="event-delete-data-field">
            <h2>Remove Event Field</h2>
            <p>{data.label} will be removed from this event and customers will no longer be asked to provide this information.</p>
            <div className="dialog-buttons-group">
                <button onClick={onSubmit} disabled={isMakingRequest}>Delete</button>
                <button className="button button-secondary" onClick={cancelDialogJourney} disabled={isMakingRequest}>Cancel</button>
            </div>
        </section>
    )
}

export default EventDeleteDataFieldDialog
