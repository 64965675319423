import { useState } from 'react'
import { faArrowRight, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import './card.scss'
import Loading from './Loading'

const Card = ({
  title,
  subtitle,
  tag,
  viewLink,
  children,
  headerIcon,
  deleteFunction,
  headerRightComponent,
  actionButtons,
}) => {
  return (
    <div className='card-common card-common__wrapper'>
      <div className='card-common__body'>
        <div className='card-common__body__header'>
          {!!tag && <span className='label'>{tag}</span>}
          {!!headerIcon && <span><FontAwesomeIcon icon={headerIcon} /></span>}
          {!!headerRightComponent && (<>{headerRightComponent}</>)}
        </div>

        <span className='card-common__body__title'>{title}</span>
        <span className='card-common__body__subtitle'>{subtitle}</span>

        <div className='card-common__body__hr'></div>

        <div className='card-common__body__children'>{children}</div>
      </div>

      <div className='card-common__footer'>
        <div className='card-common__footer__hr'></div>
        <div className='card-common__footer__content'>
          {!!viewLink && <Link to={viewLink}><button className='button-secondary button-card'>View <FontAwesomeIcon icon={faArrowRight} /></button></Link>}
          {!!actionButtons && (<>{actionButtons}</>)}
          <DeleteButton deleteFunction={deleteFunction} />
        </div>
      </div>
    </div>
  )
}

const DeleteButton = ({ deleteFunction }) => {
  const [isDeleting, setIsDeleting] = useState(false)

  const removeFunction = async () => {
    setIsDeleting(true)
    try {
      await deleteFunction()
    } catch (error) {
      setIsDeleting(false)

    }
  }

  return (
    <>
      {!!deleteFunction && <button className='button-secondary button-card button-card__delete' onClick={removeFunction} disabled={isDeleting}>
        {isDeleting ? <Loading /> : <FontAwesomeIcon icon={faTrash} />}
      </button>}
    </>
  )
}

export default Card
