import React, { useState, useEffect } from 'react'

const Timer = ({ orderCreateTime }) => {
  const [timerSeconds, setTimerSeconds] = useState(0)
  const [hideTimer, setHideTimer] = useState(false)

  useEffect(() => {
    const showTimerVariable = localStorage.getItem('showOrderTimer')
    if (showTimerVariable?.toLowerCase() === 'false') setHideTimer(true)
  }, [])

  useEffect(() => {
    const timeNow = Math.floor((new Date()).getTime() / 1000)
    setTimerSeconds(timeNow - orderCreateTime)
  }, [orderCreateTime])

  useEffect(() => {
    const incrementTimer = (seconds) => {
      setTimerSeconds(seconds + 1)
    }

    const id = setInterval(() => incrementTimer(timerSeconds), 1000);
    return () => clearInterval(id);
  }, [timerSeconds]);

  const days = timerSeconds / 86400 | 0
  const hours = timerSeconds % 86400 / 3600 | 0
  const minutes = timerSeconds % 3600 / 60 | 0
  const seconds = timerSeconds % 60

  let displayTime
  if (days > 0) {
    displayTime = `${days} ${pluralize('day', days)} ${hours} h`
  } else if (hours > 0) {
    displayTime = `${hours} ${pluralize('hour', hours)} ${minutes} m`
  } else {
    displayTime = `${minutes} min ${seconds.toString().padStart(2, '0')} sec`
  }

  if (hideTimer) return null

  return <div>{displayTime}</div>
}

function pluralize(word, number) {
  if (number > 1) {
    return `${word}s`
  }
  return word
}

export default Timer