import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHamburger, faUser, faBox, faPowerOff, faHourglassEnd, faUsers, faCopy, faQrcode, faClock, faChartLine, faHome } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import { Link, useLocation } from "react-router-dom"
import { Auth } from 'aws-amplify'
import { LocationClient } from '@food2room/clients'

import userContext from '../context/userContext'
import config from '../../config'

const SideNav = ({ isCollapsed, sideNavRef }) => {
  const linksMap = { ORDERS: 'orders', PRODUCTS: 'products', HISTORY: 'history' }
  const [activeLink, setActiveLink] = useState(linksMap.ORDERS)
  const [currentLocation, setCurrentLocation] = useState()

  const { user, locationIds, selectedLocationId } = useContext(userContext)

  let match = useLocation()

  useEffect(() => {
    setActiveLink(`${selectedLocationId}/${match.pathname.split('/')[2]}`)
  }, [match])

  useEffect(() => {
    if (!selectedLocationId) return
    // TODO - we should probably have a location context and cache this
    LocationClient.setBaseUrl(config.baseOrderUrl)
    LocationClient.getLocation(selectedLocationId)
      .then(res => {
        setCurrentLocation(res.branchName)
      })
      .catch(error => {
        // TODO
        console.error(error)
      })
  }, [selectedLocationId])

  const links = [
    {
      display_name: ' ',
      links: [
        {
          display_name: "Change location",
          link: '/locations',
          icon: faHome,
          hidden: locationIds.length <= 1,
        },
        {
          display_name: "Sign out",
          link: '/sign-out',
          icon: faPowerOff,
          hidden: false,
          onClick: signOut
        },
      ],
    },
    {
      display_name: "orders",
      links: [
        {
          display_name: "Current orders",
          link: `/${selectedLocationId}/orders`,
          icon: faHamburger,
          hidden: false,
        },
        {
          display_name: "Preorders",
          link: `/${selectedLocationId}/preorders`,
          icon: faClock,
          hidden: false,
        },
        {
          display_name: "Past orders",
          link: `/${selectedLocationId}/history`,
          icon: faHourglassEnd,
          hidden: false,
        },
      ],
    },
    {
      display_name: "planner",
      links: [
        {
          display_name: "Menus & events",
          link: `/${selectedLocationId}/menus`,
          icon: faCopy,
        },
        {
          display_name: "Products",
          link: `/${selectedLocationId}/products`,
          icon: faBox,
        },
      ],
    },
    {
      display_name: 'admin',
      hidden: true,
      links: [
        {
          display_name: 'Users',
          link: `/${selectedLocationId}/users`,
          icon: faUsers,
          hidden: true,
        },
        {
          display_name: 'QR codes',
          link: `/${selectedLocationId}/qr-codes`,
          icon: faQrcode,
          hidden: true,
        },
        {
          display_name: 'Stats',
          link: `/${selectedLocationId}/stats`,
          icon: faChartLine,
          hidden: true,
        },
      ]
    }
  ]

  async function signOut() {
    await Auth.signOut()
    window.location.reload()
  }

  const wrapperClass = classNames('side-nav', {
    'display-none': isCollapsed,
  })

  return (
    <nav className={wrapperClass} ref={sideNavRef}>
      <div className="header">
        <span id="logo" className="logo">servd<span className="primary">.</span></span>
      </div>

      <div className="person">
        <span className="thumbnail"><FontAwesomeIcon icon={faUser} /></span>
        <span className="name" data-cy="user-greeting">Hello {user?.displayName?.split(' ')[0] || ''}</span>
        <span className="location-pre">Location: </span>
        <span className="location">{currentLocation ?? ''}</span>
      </div>

      {links.map(section => {
        if (section.hidden) return null

        return (
          <div className="nav-section" key={section.display_name}>
            <span className="title">{section.display_name}</span>
            <ul>
              {section.links.map(link => {
                if (link.hidden) return null

                const linkClass = classNames({
                  'is-active': `/${activeLink}`.startsWith(link.link.toString().toLowerCase()),
                })

                return (
                  <li className={linkClass} key={`link-${link.link}`}>
                    <Link to={link.link} onClick={link.onClick}>
                      <FontAwesomeIcon icon={link.icon} />
                      {link.display_name}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        )
      })}
    </nav>
  )
}

export default SideNav
