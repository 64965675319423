import React, { useEffect, useContext, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import DataTable from 'react-data-table-component'
import classNames from 'classnames'
import { OrderClient } from "@food2room/clients"

import LocalNav from '../products/LocalNav'
import UserContext from '../context/userContext'
import Loading from '../common/Loading'
import { getIdToken } from '../../util/auth'
import IncomeStats from './IncomeStats'
import config from '../../config'

// TODO - on mobile, have a modal for order details

const HistoryPage = ({ toggleNav }) => {
  const PAGE_STATES = { LOADING: 'loading', VIEWING_HISTORY: 'viewing-history' }
  const [pageState, setPageState] = useState(PAGE_STATES.LOADING)
  const [orders, setOrders] = useState([])
  const [selectedOrder, setSelectedOrder] = useState(null)

  const { selectedLocationId } = useContext(UserContext)


  useEffect(() => {
    getIdToken().then(token => {
      OrderClient.setBaseUrl(config.baseOrderUrl)
      OrderClient.getOrdersForLocation(selectedLocationId, token, false)
        .then(orders => {
          orders.sort((a, b) => b.orderPlacedDateTimeUtc - a.orderPlacedDateTimeUtc)
          setOrders(orders)
          setPageState(PAGE_STATES.VIEWING_HISTORY)
        })
    })
      .catch(() => { }) // TODO
  }, [selectedLocationId, PAGE_STATES.VIEWING_HISTORY])

  useEffect(() => {
    if (orders.length > 0) {
      setPageState(PAGE_STATES.VIEWING_HISTORY)
      setSelectedOrder(orders[0])
    }
  }, [orders, PAGE_STATES.VIEWING_HISTORY])

  const tableColumns = [
    {
      name: 'Order ID',
      selector: 'orderId',
      cell: row => <TableLine row={row} setSelectedOrder={setSelectedOrder} />
    },
  ]

  if (pageState === PAGE_STATES.LOADING) return <Loading fullscreen={true} />

  return (
    <div className="page">
      <div className="header">
        <FontAwesomeIcon icon={faBars} id="btn-mobile-nav" onClick={() => toggleNav()} />
      </div>

      <div className="body padding-bottom--none">
        <div className="history-page-wrapper">
          <div>
            <IncomeStats orders={orders} />
            <span className="title">Past orders</span>
            <span className="sub-title">Select an order to view its details</span>
            <DataTable
              columns={tableColumns}
              data={orders}
              className="table--selectable margin-top history-table"
              pagination={true}
              paginationPerPage={7}
            />
          </div>
          <OrderDetails order={selectedOrder} />
        </div>
      </div>
    </div>
  )
}

const OrderDetails = ({ order }) => {
  const detailsLocalNavSections = [
    { title: 'Items' },
  ]

  // TODO - this logic is a copy from KitchenView card - refactor to a reusable component

  const BodyElement = ({ items }) => {
    const itemsByCategory = Object.values(items).reduce((acc, item) => {
      const category = item.product?.category?.kitchenCategory || 'Other'
      acc[category] ? acc[category].push(item) : acc[category] = [item]
      return acc
    }, {})

    return (
      Object.entries(itemsByCategory).map(([category, items]) => {
        return (
          <div className="card-body-section" key={category}>
            <span className="card-body-section-title">{category}</span>
            <ul>
              {items.map(item => {
                return (
                  <li key={item.product?.name}>
                    <span>({item.quantity}x) {item.product?.name}</span>
                  </li>
                )
              })}
            </ul>
          </div>
        )
      })
    )
  }

  if (!order) return null
  return (
    <div className="history-details-wrapper hide-for-mobile">
      <span className="title">Order details</span>
      <span className="sub-title">Order #{order.orderId}</span>
      <LocalNav sections={detailsLocalNavSections} hideAllLink={true} defaultLink="items" updateFilter={() => { }} />
      <div>
        <BodyElement items={order.items} />
      </div>
    </div>
  )
}

const TableLine = ({ row, setSelectedOrder }) => {
  const { orderStatus, customer, orderId, totalCost, orderPlacedDateTimeUtc } = row

  const StatusLabel = ({ status }) => {
    let warningLevel;
    if (status.toUpperCase() === 'IN PROGRESS') {
      warningLevel = 'alert'
    } else if (status.toUpperCase() === 'CANCELLED') {
      warningLevel = 'warning'
    }
    const labelClass = classNames('label', warningLevel)

    return <span className={labelClass}>{status}</span>
  }

  const OrderDate = ({ timePlacedInUTC }) => {
    const date = new Date(timePlacedInUTC * 1000).toLocaleString("en-GB")
    return <span>{date.split(', ')[0]}</span>
  }

  return (
    <div className="history-table-line" onClick={() => setSelectedOrder(row)}>
      <div className="order-details-wrapper">
        <span className="destination">{customer.destination}</span>
        {/* TODO - location */}
        <span className="order-location label">restaurant</span>
      </div>
      <div className="customer-wrapper">
        <span className="customer-name">{customer?.name ?? 'No customer details'}</span>
        <span className="order-id hide-for-mobile">{orderId}</span>
      </div>
      <div className="height-wrapper hide-for-mobile"><OrderDate timePlacedInUTC={orderPlacedDateTimeUtc} /></div>
      <div className="height-wrapper"><span className="price">£{(parseInt(totalCost) / 100).toFixed(2)}</span></div>
      <div className="height-wrapper"><StatusLabel status={orderStatus} /></div>
    </div>
  )
}

export default HistoryPage
