import React, { useEffect, useRef, useState, useContext } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { LocationClient } from "@food2room/clients"

import ProductsList from './ProductsList'
import LocalNav from './LocalNav'
import UserContext from '../context/userContext'
import Loading from '../common/Loading'
import config from '../../config'
import { AddToMenuContext } from '../context/addToMenuContext'
import Hero from '../common/Hero'

const ProductsSearchPage = ({ toggleNav }) => {
  const [products, setProducts] = useState()
  const [filter, setFilter] = useState({})
  const { hash: tabToView } = useLocation()
  const searchRef = useRef('')
  const { selectedLocationId } = useContext(UserContext)

  useEffect(() => {
    if (selectedLocationId) {
      LocationClient.setBaseUrl(config.baseOrderUrl)
      LocationClient.getProducts(selectedLocationId)
        .then(products => { setProducts(products) })
        .catch(error => {
          // TODO
          console.error(error)
        })
    }
  }, [selectedLocationId])

  useEffect(() => {
    if (tabToView) {
      setFilter({ category: tabToView.replace('#', '') })
      searchRef.current = ''
    }
  }, [tabToView])

  const viewingProducts = () => {
    let filteredProducts = [...Object.values(products)]

    if (filter.category) {
      if (filter.category !== 'all') {
        // TODO - assumes the main category is kitchenCategory
        filteredProducts = filteredProducts.filter(p => p.category.kitchenCategory === filter.category)
      }
    }

    if (filter.query) {
      filteredProducts = filteredProducts.filter(p => p.name.toLowerCase().includes(filter.query.toLowerCase().trim()))
    }

    return filteredProducts
  }

  function updateFilter(newFilter) {
    setFilter({ ...filter, ...newFilter })
  }

  function updateSearchQuery(query) {
    searchRef.current = query
    updateFilter({ query: query })
  }

  // TODO - error
  if (!products) {
    return <Loading fullscreen={true} />
  }

  return (
    <div className="page">
      <div className="header">
        <FontAwesomeIcon icon={faBars} id="btn-mobile-nav" onClick={() => toggleNav()} />
      </div>

      <Hero
        section="planner"
        title="Products"
        subtitle="View and manage your products and tickets here."
      />

      <div className="body menus-page">
        <MenuAddingTo selectedLocationId={selectedLocationId} />
        <LocalNav sections={[{ title: 'Starters' }, { title: 'Mains' }, { title: 'Desserts' }, { title: 'Drinks' }, { title: 'Tickets' }]} updateFilter={updateFilter} />

        <div className="products-sub-nav-wrapper">
          <div className="products-search-wrapper">
            <div className="input-search-container">
              <FontAwesomeIcon icon={faSearch} />
              <input
                type="text"
                id="psearch"
                name="psearch"
                placeholder="Start typing..."
                onChange={e => updateSearchQuery(e.target.value)}
                ref={searchRef}
                value={searchRef.current}
              />
            </div>
          </div>
          <Link to={`/${selectedLocationId}/products/new`}><button id="btn-new-product">New product</button></Link>
        </div>

        <ProductsList products={viewingProducts()} />
      </div>
    </div>
  )
}

const MenuAddingTo = ({ selectedLocationId }) => {
  const { menu, clearMenuToAdd, isAddingToMenu } = useContext(AddToMenuContext)

  if (!isAddingToMenu() || !menu) return null

  return (
    <>
      <div className="adding-to-menu-warning">
        <div>You're adding a product to the <Link to={`/${selectedLocationId}/${menu.menuType?.toLowerCase() === 'event' ? 'events' : 'menus'}/${menu.id}`}>{menu.name}</Link> menu</div>
        <div className="cancel-add" data-tip data-for="adding-to-menu-cancel" onClick={() => clearMenuToAdd()}><FontAwesomeIcon icon={faTimes} /></div>
      </div>
    </>
  )
}

export default ProductsSearchPage
