import { useContext, useEffect, useState } from "react"
import DataTable from "react-data-table-component"
import classNames from 'classnames'
import { LocationClient } from "@food2room/clients"

import { EventContext } from "./EventContext"
import convertPriceToString from "../../../util/convertPriceToString"
import Loading from "../../common/Loading"
import { getIdToken } from '../../../util/auth'
import { LocationContext } from "../../context/locationContext";
import SalesFilter from "./SalesFilter"
import useDialogsContext, { DIALOG_JOURNEYS } from "../../context/dialogs/DialogsContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons"

const Sales = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [sales, setSales] = useState([])
  const [filteredSales, setFilteredSales] = useState([])
  const [salesFilter, setSalesFilter] = useState({})

  const { getPreordersForEvent, event } = useContext(EventContext)
  const { location } = useContext(LocationContext)

  useEffect(() => {
    setIsLoading(true)
    getSalesData().then(res => {
      setSales(res)
      setFilteredSales(res)
      setIsLoading(false)
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let filteredSales = sales;

    if (salesFilter?.date) {
      filteredSales = filteredSales.filter(s => s.deliveryDate.split('T')[0] === salesFilter.date.value.split('T')[0])
    }

    if (salesFilter?.item) {
      filteredSales = filteredSales.filter(s => s.items[salesFilter.item.value])
    }

    if (salesFilter?.email) {
      filteredSales = filteredSales.filter(s => s?.customer?.email?.startsWith(salesFilter.email) || s?.preorderId?.startsWith(salesFilter.email))
    }

    setFilteredSales(filteredSales)
  }, [sales, salesFilter])

  const tableColumns = [
    {
      name: 'Date ordered',
      selector: row => row.orderPlacedDateTimeUtc,
      cell: row => new Date(row.orderPlacedDateTimeUtc * 1000).toDateString()
    },
    {
      name: 'Name',
      selector: row => row.customer.name,
    },
    {
      name: 'Amount',
      selector: row => row.totalCost,
      cell: row => convertPriceToString(row.totalCost)
    },
    {
      name: 'Status',
      selector: row => row.status,
      cell: row => <StatusLabel paymentStatus={row.status} />
    },
  ]

  async function getSalesData() {
    const sales = await getPreordersForEvent()
    return sales.filter(preorder => preorder.status === "PAID" || preorder.status === "PART-PAID" || preorder.status === "CANCELLED")
  }

  function changeFilter(newFilter) {
    setSalesFilter({ ...salesFilter, [newFilter.type]: newFilter.value })
  }

  function removeFilter(filter) {
    const filters = { ...salesFilter }
    delete filters[filter]
    setSalesFilter({ ...filters })
  }

  if (isLoading) return <Loading />

  return (
    <>
      <div className="event-sales-actions__wrapper">
        <SalesFilter
          changeFilter={changeFilter}
          filteredSales={filteredSales}
          event={event}
          location={location}
          salesFilter={salesFilter}
          removeFilter={removeFilter}
        />
      </div>
      <div>
        <DataTable
          columns={tableColumns}
          data={filteredSales}
          pagination
          expandableRows
          expandOnRowClicked
          expandableRowsComponent={SalesExpandableComponent}
        />
      </div>
    </>
  )
}

const SalesExpandableComponent = ({ data }) => {
  const { startDialogJourney } = useDialogsContext()

  const tableColumns = [
    {
      name: 'Name',
      selector: row => row.product.name
    },
    {
      name: 'Size',
      selector: row => row.size.name
    },
    {
      name: 'Quantity',
      selector: row => row.quantity
    },
    {
      name: 'Total cost',
      selector: row => row.totalCost,
      cell: row => convertPriceToString(row.totalCost)
    },
  ]

  function getSalesForTable() {
    const salesForTable = Object.values(data.items)
    salesForTable.push({ product: { name: 'Total' }, totalCost: data.totalCost, size: { name: null }, quantity: null })
    if (data?.bookingFee > 0) {
      salesForTable.push({ product: { name: 'Booking fee' }, totalCost: data.bookingFee, size: { name: null }, quantity: null })
    }
    salesForTable.push({ product: { name: 'Amount paid' }, totalCost: data.totalPaid || data.totalCost, size: { name: null }, quantity: null })
    return salesForTable
  }

  const downloadInvoice = async (e, invoiceId) => {
    e.preventDefault();
    getIdToken()
      .then(token => LocationClient.getInvoiceUrl(data.locationId, invoiceId, token))
      .then(url => window.open(url, '_blank'))
  }

  return (
    <div className="event-sales-expander__wrapper">
      <div className="grid">
        <ExpandedInput label="Order ID" value={data.preorderId} />
        <ExpandedInput label="Customer name" value={data.customer.name ?? 'Not provided'} />
        <ExpandedInput label="Customer company" value={data.customer.company ?? 'Not provided'} />
        <ExpandedInput label="Customer email" value={data.customer.email ?? 'Not provided'} />
        <ExpandedInput label="Customer phone" value={data.customer.phone ?? 'Not provided'} />
        <ExpandedInput label="Date purchased" value={new Date(data.orderPlacedDateTimeUtc * 1000).toDateString()} />
        <ExpandedInput label="Event date" value={new Date(data.deliveryDate).toDateString()} />
        {!!data?.customDataFields && (
          data.customDataFields.map(f => {
            return <ExpandedInput label={f.label} value={f.value ?? 'Not provided'} />
          })
        )}
      </div>
      <div>
        <label>Notes from the customer</label>
        <textarea disabled defaultValue={data.notes ?? 'Not provided'} />
      </div>
      <div>
        <div className="event-sales-expander__items-header">
          <label>Items ordered</label>
          <div>
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            <button className="button--only-text" onClick={() => startDialogJourney(DIALOG_JOURNEYS.EVENT_SALE_PAYMENTS, () => { }, { order: data, downloadInvoice: downloadInvoice })}>View payments and invoices</button>
          </div>
        </div>
        <DataTable
          columns={tableColumns}
          data={getSalesForTable()}
          className="event-sales-expander__table"
        />
      </div>
    </div>
  )
}

const ExpandedInput = ({ label, value }) => {
  return (
    <div>
      <label>{label}</label>
      <input type="text" defaultValue={value} disabled />
    </div>
  )
}

const StatusLabel = ({ paymentStatus }) => {
  let warningLevel
  let message

  if (paymentStatus === 'PAID') {
    warningLevel = 'success'
    message = 'PAID'
  }

  if (paymentStatus === 'PART-PAID') {
    warningLevel = 'alert'
    message = 'DEPOSIT'
  }

  if (paymentStatus === 'CANCELLED') {
    warningLevel = 'warning'
    message = 'CANCELLED'
  }

  const labelClass = classNames('label', warningLevel)

  return <span className={labelClass}>{message}</span>
}


export default Sales
