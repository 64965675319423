import React, { useRef, useState, useContext, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { LocationClient } from "@food2room/clients"
import { useHistory, Link } from 'react-router-dom'
import Select from 'react-select'
import classNames from 'classnames'

import Loading from '../common/Loading'
import UserContext from '../context/userContext'
import ErrorMessage from '../common/ErrorMessage'
import { getIdToken } from '../../util/auth'
import config from '../../config'
import { getAllergens, getDisplayTags } from '../../util/productTags'
import { getRecommendedSizes } from '../../util/recommendedProductSizes'
import { uniqueId } from 'lodash'
import Hero from '../common/Hero'

const NewProductsPage = ({ toggleNav }) => {
  const [error, setError] = useState(null)
  const [isCreating, setIsCreating] = useState(false)
  const [recommendedSizes, setRecommendedSizes] = useState({})

  const { selectedLocationId } = useContext(UserContext)

  const nameRef = useRef()
  const descRef = useRef()
  const categoryRef = useRef()
  const tagsRef = useRef()
  const allergensRef = useRef()
  const vatRateRef = useRef()
  const stockRef = useRef()

  const history = useHistory()

  useEffect(() => {
    let startSizes = {}
    getRecommendedSizes().forEach(size => {
      const id = uniqueId('rs-')
      startSizes[id] = { id: id, name: size, enabled: false, price: 0 }
    })
    setRecommendedSizes(startSizes)
  }, [])

  // TODO - merge this method with above useEffect
  function updateRecommendedSizes() {
    let startSizes = {}
    getRecommendedSizes(categoryRef?.current?.value).forEach(size => {
      const id = uniqueId('rs-')
      startSizes[id] = { id: id, name: size, enabled: false, price: 0 }
    })
    setRecommendedSizes(startSizes)
  }

  function toggleRecommendedSize(id) {
    let sizesCopy = { ...recommendedSizes }
    sizesCopy[id].enabled = !sizesCopy[id].enabled
    setRecommendedSizes(sizesCopy)
  }

  function updateRecommendedSizePrice(id, price) {
    let sizesCopy = { ...recommendedSizes }
    sizesCopy[id].price = Math.round(parseFloat(price) * 100)
    setRecommendedSizes(sizesCopy)
  }

  async function createProduct(e) {
    e.preventDefault()
    setError(null)
    setIsCreating(true)

    if (!nameRef.current.value || !descRef.current.value || !vatRateRef.current.value || !categoryRef.current.value || categoryRef.current.value === 'none') {
      setError('Provide a name, description, VAT rate and category for your product.')
      setIsCreating(false)
      return
    }

    function getAllergenTags() {
      let allergens = {
        celery: false, gluten: false, crustaceans: false, eggs: false,
        fish: false, lupin: false, milk: false, molluscs: false, mustard: false,
        treeNuts: false, peanuts: false, sesameSeeds: false, soybeans: false, sulphites: false,
      }

      allergensRef.current.getValue().forEach(a => {
        allergens[a.value] = true
      })

      return allergens
    }

    const product = {
      name: nameRef.current.value,
      description: descRef.current.value,
      stock: parseInt(stockRef.current.value),
      category: {
        displayCategory: categoryRef.current.value,
        kitchenCategory: categoryRef.current.value,
        tags: {
          display: tagsRef.current.getValue().map(a => a.value),
          allergens: getAllergenTags(),
        }
      },
      available: true,
      vatPercent: parseFloat(vatRateRef.current.value),
      sizes: Object.values(recommendedSizes).filter(s => s.enabled).map(s => { return { name: s.name, price: s.price } }),
    }

    LocationClient.setBaseUrl(config.baseOrderUrl)
    const res = await LocationClient.createProduct(selectedLocationId, product, await getIdToken())
    // TODO - how do we know if it was successful?
    // TODO error catching
    if (res.id) {
      history.push(`/${selectedLocationId}/products/${res.id}`)
    } else {
      setError('An error has occurred. Try again.')
      setIsCreating(false)
    }
  }

  return (
    <div className="page">
      <div className="header">
        <FontAwesomeIcon icon={faBars} id="btn-mobile-nav" onClick={() => toggleNav()} className="margin-right" />
      </div>

      <Hero
        section="planner"
        title="Products"
        subtitle="View and manage your products and tickets here."
      />

      <div className="body body--split--2 menus-page">
        <div>
          <Link to={`/${selectedLocationId}/products`}><FontAwesomeIcon icon={faArrowLeft} /> Back to products</Link>
          <h1 className="title">New product</h1>
          <span className="sub-title">Enter the details of the product</span>
          <form className="new-product-form margin-top">
            <ErrorMessage error={error} />
            <label htmlFor="fname">Name</label>
            <input type="text" id="fname" name="fname" ref={nameRef} disabled={isCreating} />

            <label htmlFor="fdescription">Description</label>
            <textarea id="fdescription" name="fdescription" rows="4" ref={descRef} disabled={isCreating} />

            <label htmlFor="stock">Stock</label>
            <input id="stock" name="stock" type="number" disabled={isCreating} ref={stockRef} placeholder="Leave blank for untracked stock" />

            <label htmlFor="vat">VAT rate</label>
            <div className="input-group-symbol-after">
              <input id="vat" name="vat" type="number" disabled={isCreating} ref={vatRateRef} />
              <div className="symbol-wrapper"><span>%</span></div>
            </div>

            <label htmlFor="fcategory">Category</label>
            <select name="fcategory" id="fcategory" ref={categoryRef} onChange={() => updateRecommendedSizes()} disabled={isCreating}>
              <option value="none">Select a category</option>
              <option value="starters">Starter</option>
              <option value="mains">Main</option>
              <option value="desserts">Dessert</option>
              <option value="drinks">Drink</option>
              <option value="tickets">Ticket</option>
            </select>

            <label htmlFor="tags">Tags</label>
            <div className="multi-select">
              <Select
                isMulti
                id="tags"
                ref={tagsRef}
                isDisabled={isCreating}
                options={getDisplayTags()}
              />
            </div>

            <label htmlFor="allergens">Allergens</label>
            <div className="multi-select">
              <Select
                isMulti
                id="allergens"
                ref={allergensRef}
                isDisabled={isCreating}
                options={getAllergens()}
              />
            </div>

            <p className="p--info">
              <b>Note</b><br />
              Price, sizes and other details can be set after the product has been created or by using the recommendations.
            </p>

            <div className="hide-for-mobile">
              {isCreating ? <Loading /> : <button onClick={createProduct}>Create product</button>}
            </div>
          </form>
        </div>

        <div className="product-new-recommendations">
          <div className="hide-for-mobile"><span className="title">&nbsp;</span></div>
          <span className="sub-title">Recommendations</span>

          <span className="recommendation-subtitle">Sizes</span>
          {
            Object.values(recommendedSizes).map(s => {
              return <RecommendedSize key={s.id} size={s} toggleRecommendedSize={toggleRecommendedSize} updateRecommendedSizePrice={updateRecommendedSizePrice} isCreating={isCreating} />
            })
          }

          <div className="show-for-mobile-only">
            {isCreating ? <Loading /> : <button onClick={createProduct}>Create product</button>}
          </div>
        </div>
      </div>
    </div>
  )
}

const RecommendedSize = ({ size, toggleRecommendedSize, updateRecommendedSizePrice, isCreating }) => {
  const priceRef = useRef()

  function updatePrice() {
    updateRecommendedSizePrice(size.id, priceRef.current.value)
  }

  const wrapperClass = classNames('recommended-size-wrapper', {
    'recommended-size-wrapper--selected': size.enabled && !isCreating,
  })

  return (
    <div className={wrapperClass}>
      <div className="recommended-size-wrapper--select">
        <input type="checkbox" id={size.id} name={size.id} onChange={() => toggleRecommendedSize(size.id)} />
        <label htmlFor={size.id}>{size.name}</label>
      </div>
      <div className="input-with-prefix-icon">
        <span>£</span>
        <input type="number" className="recommended-size-price" disabled={!size.enabled || isCreating} onChange={updatePrice} ref={priceRef} />
      </div>
    </div>
  )
}

export default NewProductsPage
