import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faBars, faEllipsisV, faSearch, faUser } from '@fortawesome/free-solid-svg-icons'
import OutsideClickHandler from "react-outside-click-handler/esm/OutsideClickHandler";

const users = [
  { name: "Cobb, Daniel", id: 1, role: "Kitchen staff" },
  { name: "Charles, Prince", id: 5, role: "Kitchen staff" },
  { name: "Cage, Nicolas", id: 6, role: "Kitchen staff" },
  { name: "Dworzycki, Pawel", id: 2, role: "Kitchen staff" },
  { name: "Handley, Jack", id: 3, role: "Kitchen staff" },
  { name: "Stark, Tony", id: 4, role: "Kitchen staff" },
]

const UsersListPage = ({ toggleNav }) => {
  const [letters, setLetters] = useState([])
  const [selectedUser, setSelectedUser] = useState()

  useEffect(() => {
    const uniqueLetters = new Set()
    users.forEach(u => uniqueLetters.add(u.name.charAt(0)))
    setLetters(Array.from(uniqueLetters))
  }, [])

  return (
    <div className="page">
      <div className="header">
        <FontAwesomeIcon icon={faBars} id="btn-mobile-nav" onClick={() => toggleNav()} />
        <div className="label label--wip warning">This page is a work in progress</div>
      </div>

      <div className="users-page">
        <div className="body">
          <span className="title">Users</span>
          <span className="sub-title">View &amp; manage users</span>

          {/* <div className="margin-top">
            <button>Add user</button>
          </div> */}

          <div className="input-search-container">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              id="psearch"
              name="psearch"
              placeholder="Search"
            />
          </div>

          <div className="letters-wrapper">
            {letters.map(l => {
              return <LetterSection letter={l} users={users.filter(u => u.name.charAt(0) === l)} setSelectedUser={setSelectedUser} />
            })}
          </div>
        </div>

        <div className="hide-for-mobile">
          <UserProfile user={selectedUser} />
        </div>
      </div>
    </div>
  )
}

const LetterSection = ({ letter = "?", users = [], setSelectedUser }) => {
  return (
    <div className="user-letter-section-wrapper">
      <p className="letter">{letter}</p>
      {users.map(u => {
        return <User user={u} key={u.id} setSelectedUser={() => setSelectedUser(u)} />
      })}
    </div>
  )
}

const User = ({ user, setSelectedUser }) => {
  return (
    <div className="user" onClick={setSelectedUser}>
      <span className="img"></span>
      <div className="details">
        <span className="name">{user.name}</span>
        <span className="description">Kitchen staff</span>
      </div>
    </div>
  )
}

const UserProfile = ({ user }) => {
  const [showActions, setShowActions] = useState(false)

  if (!user) return null

  return (
    <div className="user-profile-wrapper">
      <div className="actions-wrapper" onClick={() => setShowActions(!showActions)}>
        <FontAwesomeIcon icon={faEllipsisV} />
      </div>
      <OutsideClickHandler disabled={!showActions} onOutsideClick={() => setShowActions(false)}>
        <div className='actions' style={{ display: showActions ? 'block' : 'none' }} >
          <div className='btn-action'>Reset password</div>
          <div className='btn-action'>Remove user</div>
        </div>
      </OutsideClickHandler>

      <div className="user-img"><FontAwesomeIcon icon={faUser} /></div>

      <div>
        <span className="user-name">{user.name}</span>
        <span className="user-role">{user.role}</span>
      </div>

      <div className="user-calendar">
        <div className="user-calendar-header">
          <FontAwesomeIcon icon={faArrowLeft} />
          <span>This week</span>
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
        <div className="days-wrapper">
          <div className="day"><span className="day-name">Mon</span><span className="working-hours">09:00 - 17:30</span></div>
          <div className="day"><span className="day-name">Tue</span><span className="working-hours">09:00 - 17:30</span></div>
          <div className="day"><span className="day-name">Wed</span><span className="working-hours">09:00 - 17:30</span></div>
          <div className="day"><span className="day-name">Thu</span><span className="working-hours">09:00 - 17:30</span></div>
          <div className="day"><span className="day-name">Fri</span><span className="working-hours">09:00 - 17:30</span></div>
          <div className="day"><span className="day-name">Sat</span><span className="working-hours working-hours--none">Not working</span></div>
          <div className="day"><span className="day-name">Sun</span><span className="working-hours working-hours--none">Not working</span></div>
        </div>
      </div>
    </div>
  )
}

export default UsersListPage
