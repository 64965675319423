import React, { createContext, useContext, useEffect, useState } from 'react'
import { LocationClient, OrderClient } from "@food2room/clients"
import axios from 'axios'

import UserContext from './userContext'
import { getIdToken } from '../../util/auth'
import config from '../../config'

const Context = createContext({})

const Provider = ({ children }) => {
  const [location, setLocation] = useState({})
  const { selectedLocationId } = useContext(UserContext)

  useEffect(() => {
    LocationClient.setBaseUrl(config.baseOrderUrl)
    OrderClient.setBaseUrl(config.baseOrderUrl)
  }, [])

  useEffect(() => {
    if (!selectedLocationId) return
    LocationClient.getLocation(selectedLocationId)
      .then(res => {
        setLocation(res)
      })
      .catch(error => {
        // TODO
        console.error(error)
      })
  }, [selectedLocationId])

  async function getOrders() {
    const orders = await OrderClient.getOrdersForLocation(selectedLocationId, await getIdToken(), false)
    return orders
  }

  async function getPreorders() {
    let url = `${config.baseOrderUrl}/locations/${selectedLocationId}/preorders`
    const res = await axios.get(url, { headers: { "Authorization": await getIdToken() } })

    res.data.forEach(order => {
      order.type = 'preorder'
    })

    return res.data
  }

  async function getOrdersAndPreorders() {
    const orders = await getOrders()
    const preorders = await getPreorders()
    return [...orders, ...preorders]
  }

  const locationContext = {
    getOrders,
    getPreorders,
    getOrdersAndPreorders,
    location
  }

  return <Context.Provider value={locationContext}>{children}</Context.Provider>
}

const { Consumer } = Context

export const LocationContext = Context
export const LocationContextProvider = Provider
export const LocationContextConsumer = Consumer