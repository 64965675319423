import { useState, useRef } from "react"
import { toast } from 'react-toastify'

import { getIdToken } from '../../util/auth'

const NewSizeForm = ({ cancelSizeAdd, createSizeFn, forceDOMUpdate }) => {
  const [isLoading, setIsLoading] = useState(false)

  const nameRef = useRef()
  // const descRef = useRef()
  const priceRef = useRef()

  async function addSize() {
    setIsLoading(true)
    if (!nameRef.current.value || !priceRef.current.value) {
      toast.error('Name and price not set')
      setIsLoading(false)
      return
    }

    const name = nameRef.current.value
    const price = priceRef.current.value

    function convertPriceStringToPennies() {
      return Math.round(parseFloat(price) * 100)
    }

    await createSizeFn(name, convertPriceStringToPennies(), await getIdToken())
    // TODO - error catching
    toast.success(`${name} size added.`)
    forceDOMUpdate()
    setIsLoading(false)
    cancelSizeAdd()
  }

  return (
    <section>
      <form onSubmit={(e) => e.preventDefault()}>
        <h2>New size</h2>

        <div className="margin-top margin-bottom">
          <label htmlFor="sName">Name</label>
          <input type="text" id="sName" name="sName" ref={nameRef} placeholder="Name of the size" disabled={isLoading} />
        </div>

        {/* <div className="margin-top margin-bottom">
          <label htmlFor="fdescription">Description</label>
          <textarea id="fdescription" name="fdescription" rows="4" ref={descRef} placeholder="Description of the size" disabled={isLoading} />
        </div> */}

        <div className="margin-top margin-bottom">
          <label htmlFor="sPrice">Price (inc. VAT)</label>
          <input type="number" id="sPrice" name="sPrice" ref={priceRef} placeholder="1.00" disabled={isLoading} />
        </div>

        <button className="button margin-right" onClick={addSize} disabled={isLoading}>Create</button>
        <button className="button button-secondary" onClick={cancelSizeAdd} disabled={isLoading}>Cancel</button>
      </form>
    </section>
  )
}

export default NewSizeForm
