import React, { useRef, useState } from 'react'
import { Auth } from 'aws-amplify'
import { useHistory } from 'react-router-dom'

import ErrorMessage from '../common/ErrorMessage'
import PasswordReset from './PasswordReset'
import Password from './Password'
import Username from './Username'

const LoginPage = ({ setLoggedInUser }) => {
  const [error, setError] = useState(null)
  const [noUsername, setNoUsername] = useState(false)
  const [noPassword, setNoPassword] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [amplifyUser, setAmplifyUser] = useState(null)
  const [isFirstTimePasswordReset, setIsFirstTimePasswordReset] = useState(false)
  const [hasForgottenPassword, setHasForgottenPassword] = useState(false)
  const userNameRef = useRef()
  const passwordRef = useRef()
  const history = useHistory()

  async function login(e) {
    e.preventDefault()

    setIsProcessing(true)
    const username = userNameRef.current.value
    const password = passwordRef.current.value

    setNoUsername(false)
    setNoPassword(false)
    setError(null)

    if (!username) setNoUsername(true)
    if (!password) setNoPassword(true)
    if (!username || !password) {
      setIsProcessing(false)
      return
    }

    try {
      const user = await Auth.signIn(username, password)
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setIsProcessing(false)
        setIsFirstTimePasswordReset(true)
        setAmplifyUser(user)
        return
      }
      setLoggedInUser()
      history.push('/')
    } catch (error) {
      if (error.code === 'UserNotFoundException') {
        setError('User does not exist')
      } else {
        setError(error.message)
      }
      setIsProcessing(false)
    }
  }

  const LoginForm = () => {
 
    return (
      <>
        <h1 className="margin-bottom">Log in</h1>
        <ErrorMessage error={error} headingText="Failed to log in" />
        <div className="login-form">
          <form>
            <Username userNameRef={userNameRef} noUsername={noUsername} isProcessing={isProcessing} />
            <Password passwordRef={passwordRef} noPassword={noPassword} isProcessing={isProcessing} />
            <button onClick={login} data-cy="sign-in-button" disabled={isProcessing} >Log in</button>
            <button onClick={() => setHasForgottenPassword(true)} data-cy="request-password-reset-button" disabled={isProcessing} className='float-right' >Forgotten password?</button>
          </form>
        </div>
      </>
    )
  }

  const Form = ({amplifyUser, setHasForgottenPassword}) => {
    if (hasForgottenPassword || isFirstTimePasswordReset) {
      return (<PasswordReset setLoggedInUser={setLoggedInUser} amplifyUser={amplifyUser} isFirstTimePasswordReset={isFirstTimePasswordReset} hasForgottenPassword={hasForgottenPassword} setHasForgottenPassword={setHasForgottenPassword} />)
    }
    return (<LoginForm />)
  }

  return (
    <div className="page-login">
      <div className="left">
        <span id="logo" className="logo logo--large">servd<span className="primary">.</span></span>
        <Form amplifyUser={amplifyUser} setHasForgottenPassword={setHasForgottenPassword}/>
      </div>
      <div className="right hide-for-mobile">
        <div>
          <span>Your kitchen.</span>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
