import React, { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faDownload, faFilter, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { CSVLink } from "react-csv"
import jsPDF from 'jspdf'
import 'jspdf-autotable'

import ButtonWithDropdown from "../../common/ButtonWithDropdown"
import { CSV_TYPES, generateCsv } from "../../../util/generateCsv"

import './sales.scss'
import useDialogsContext, { DIALOG_JOURNEYS } from '../../context/dialogs/DialogsContext'

const SalesFilter = ({
    changeFilter,
    filteredSales,
    event,
    location,
    salesFilter,
    removeFilter
}) => {
    return (
        <section>
            <section className='sales__filters__wrapper'>
                <SearchFilter changeFilter={changeFilter} />
                <div className='sales__filters__right'>
                    <FiltersButton event={event} changeFilter={changeFilter} />
                    <DownloadButton filteredSales={filteredSales} event={event} location={location} />
                </div>
            </section>
            <section>
                {Object.keys(salesFilter)?.map(k => {
                    if (k === 'email') return null

                    function getDisplayValue () {
                        if (k === 'date') return salesFilter[k].label
                        if (k === 'item') return salesFilter[k].label
                        return null
                    }

                    return (
                        <span className="menu-filter" key={`filters-${k}}`}>
                            {k} - {getDisplayValue()}
                            <FontAwesomeIcon icon={faTimesCircle} onClick={() => removeFilter(k)} />
                        </span>
                    )
                })}
            </section>
        </section>
    )
}

const SearchFilter = ({ changeFilter }) => {
    return (
        <div className="products-search-wrapper">
            <div className="input-search-container">
                <FontAwesomeIcon icon={faSearch} />
                <input
                    type="text"
                    id="psearch"
                    name="psearch"
                    placeholder="Search by email or order ID"
                    onChange={e => changeFilter({ type: 'email', value: e.target.value })}
                />
            </div>
        </div>
    )
}

const FiltersButton = ({ event, changeFilter }) => {
    const { startDialogJourney } = useDialogsContext()

    const eventDates = useMemo(() => {
        return event?.eventDetails?.eventDateTime?.map(event => ({ label: new Date(event.start).toDateString(), value: event.start }))
    }, [event])

    const eventItems = useMemo(() => {
        const allProducts = []
        Object.values(event.items)?.map(i => {
          i.product?.sizes.forEach(s => {
            allProducts.push({ label: `${i.product.name} - ${s.name}`, value: `${i.id}-${s.name}` })
          });
        })
        return allProducts
      }, [event])
    

    const handleDateFilter = () => {
        const onSuccess = (e) => {
            changeFilter({ type: 'date', value: e })
        }

        startDialogJourney(DIALOG_JOURNEYS.EVENT_FILTER_SALES_DATE, onSuccess, { eventDates })
    }

    const handleItemFilter = () => {
        const onSuccess = (e) => {
            changeFilter({ type: 'item', value: e })
        }

        startDialogJourney(DIALOG_JOURNEYS.EVENT_FILTER_SALES_ITEM, onSuccess, { eventItems })
    }

    return (
        <ButtonWithDropdown label="Filters" icon={faFilter}>
            <button onClick={handleDateFilter}>Event date filter</button>
            <button onClick={handleItemFilter}>Item filter</button>
        </ButtonWithDropdown>
    )
}

const DownloadButton = ({ filteredSales, event, location }) => {
    function generatePDF() {
        // noinspection JSPotentiallyInvalidConstructorUsage
        const pdf = new jsPDF()

        pdf.setFontSize(16)
        pdf.text(20, 20, event.name)

        const salesSortedByCustomerName = filteredSales.sort((a, b) => (a.customer.name > b.customer.name) ? 1 : ((b.customer.name > a.customer.name) ? -1 : 0))

        const salesDataToPrint = salesSortedByCustomerName.map(s => {
            let purchaseSummary = ''
            Object.values(s.items).forEach(item => {
                purchaseSummary += `${item.quantity} - ${item.product.name} (${item.size.name})\n`
            })

            return [s.customer.name, purchaseSummary, s.notes, new Date(s.deliveryDate).toDateString()]
        })

        pdf.autoTable({
            startY: 30,
            head: [['Name', 'Purchases', 'Notes', 'Date']],
            body: salesDataToPrint,
            theme: 'striped',
            headStyles: {
                fillColor: '#7ca870',
            }
        })

        pdf.save(`${event.name}.pdf`)
    }


    const XeroCsvButton = () => {
        let allowButton = false
        if (!location?.config?.enabledFeatures?.xeroCsv) return null

        if (window.location.origin === 'https://kitchen.servd.uk') {
            // TODO - while we clarify the button, it's hardcoded to his one event
            if (event.id === '8v66mh3o9eblaptbktw') {
                allowButton = true
            }
        } else {
            allowButton = true
        }

        if (allowButton) {
            return (
                <CSVLink
                    data={generateCsv(CSV_TYPES.XERO, filteredSales)}
                    filename={event.name}
                >
                    <button><FontAwesomeIcon icon={faDownload} /> Xero CSV</button>
                </CSVLink>
            )
        }

        return null
    }

    return (
        <div>
            <ButtonWithDropdown label="Download">
                <button onClick={generatePDF}><FontAwesomeIcon icon={faDownload} /> Standard PDF</button>
                <CSVLink
                    data={generateCsv(CSV_TYPES.STANDARD, filteredSales)}
                    filename={event.name}
                >
                    <button><FontAwesomeIcon icon={faDownload} /> Standard CSV</button>
                </CSVLink>
                <XeroCsvButton />
            </ButtonWithDropdown>
        </div>
    )
}

export default SalesFilter
