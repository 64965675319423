import { useContext, useEffect, useState } from "react"
import axios from "axios"
import { useHistory } from 'react-router-dom'
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import cloneDeep from 'lodash.clonedeep'

import { EventContext } from './EventContext'
import convertPriceToString from '../../../util/convertPriceToString'
import { getIdToken } from '../../../util/auth'
import userContext from '../../context/userContext'
import config from '../../../config'
import Loading from "../../common/Loading"
import { AddToMenuContext } from '../../context/addToMenuContext'
import Card from '../../common/Card'

const Tickets = () => {
  const { event, removeProductFromEventLocally } = useContext(EventContext)
  const { selectedLocationId } = useContext(userContext)
  const [orderedItemsCountBySize, setOrderedItemsCountBySize] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getIdToken().then(token => {
      // TODO - replace this once there's a filter on the preorders api
      axios.get(`${config.baseOrderUrl}/locations/${selectedLocationId}/preorders?menuId=${event.id}`, { headers: { "Authorization": token } })
        .then(orders => {
          const ordersForThisEvent = orders.data.filter(o => o?.menuId === event.id)
          const orderedItemsCountBySizeTmp = {}
          ordersForThisEvent.forEach(o => {
            if (o.status?.toLowerCase() === 'paid' || o.status?.toLowerCase() === 'part-paid') {
              Object.keys(o.items).forEach(itemKey => {
                if (orderedItemsCountBySizeTmp.hasOwnProperty(itemKey)) {
                  orderedItemsCountBySizeTmp[itemKey] += o.items[itemKey].quantity
                } else {
                  orderedItemsCountBySizeTmp[itemKey] = o.items[itemKey].quantity
                }
              })
            }
          })

          setOrderedItemsCountBySize(orderedItemsCountBySizeTmp)
          setIsLoading(false)
        })
    })
  }, [event.id, selectedLocationId])

  const history = useHistory()
  const addMenuContext = useContext(AddToMenuContext)

  function goToAddProduct() {
    addMenuContext.setMenuToAdd(event)
    history.push(`/${selectedLocationId}/products`)
  }

  async function removeProductFromMenu(itemId) {
    try {
      await addMenuContext.removeFromMenu(event.id, itemId)
      removeProductFromEventLocally(itemId)
      toast.success(`Removed item from menu.`)
    } catch (error) {
      console.log(error);
      toast.error(`Could not remove item from menu. Try again.`)
    }
  }

  if (isLoading) return <Loading />

  return (
    <div className="event-details-tickets__wrapper event-details-section__wrapper">
      <button onClick={goToAddProduct}>Add a ticket</button>
      <div className="margin-top grid grid__1">
        <ListOfTickets
          tickets={event.items}
          orderedItemsCountBySize={orderedItemsCountBySize}
          removeProductFromMenu={removeProductFromMenu}
          selectedLocationId={selectedLocationId}
        />
      </div>
    </div>
  )
}

const ListOfTickets = ({ tickets = {}, orderedItemsCountBySize, removeProductFromMenu, selectedLocationId }) => {
  const [items, setItems] = useState(tickets)

  async function deleteProduct(id) {
    try {
      await removeProductFromMenu(id)
      const newItems = cloneDeep(items)
      delete newItems[id]
      setItems(newItems)
    } catch (error) {
      console.log(error);
    }
  }

  if (Object.keys(items).length === 0) {
    return (
      <p>No tickets added to the event.</p>
    )
  }

  return (
    <>
      {Object.values(items).map(ticket => {
        const { product } = ticket
        return (
          <div className="event-ticket__card" key={ticket.productId}>
            <Card
              title={product.name}
              subtitle={product.description}
              tag={ticket.section}
              viewLink={`/${selectedLocationId}/products/${product.id}`}
              headerIcon={product.available ? faCheckCircle : faTimesCircle}
              deleteFunction={async () => await deleteProduct(ticket.id)}
            >
              {product.sizes.map(size => {
                return <div key={`${size.name.toLowerCase()}`} className="event-ticket__card__size__wrapper">
                  <span className="size-name">{size.name}</span>
                  <span>{convertPriceToString(size.price)}</span>
                  <span>{orderedItemsCountBySize[`${ticket.id}-${size.name}`] || 0} sold</span>
                </div>
              })}
            </Card>
          </div>
        )
      })}
    </>
  )
}

export default Tickets
