import React, { useState, useRef, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useHistory, Link } from 'react-router-dom'
import { LocationClient } from "@food2room/clients"
import { toast } from 'react-toastify'
import Select from 'react-select'

import Loading from '../common/Loading'
import ErrorMessage from '../common/ErrorMessage'
import { getIdToken } from '../../util/auth'
import UserContext from '../context/userContext'
import { getMenuTypes } from '../../util/menuTypes'
import Hero from '../common/Hero'

const MenuNew = ({ toggleNav }) => {
  const [error, setError] = useState(null)
  const [isCreating, setIsCreating] = useState(false)
  const [menuType, setMenuType] = useState()

  const { selectedLocationId } = useContext(UserContext)

  const nameRef = useRef()
  const descriptionRef = useRef()
  const typeRef = useRef()
  const contactName = useRef()
  const contactPhone = useRef()
  const contactEmail = useRef()
  const contactAddress = useRef()

  const history = useHistory()

  async function createMenu(e) {
    e.preventDefault()
    setError(null)
    setIsCreating(true)

    if (!typeRef.current?.getValue()[0]?.value) {
      setError('Select a type for your menu.')
      setIsCreating(false)
      return
    }

    if (!nameRef.current.value) {
      setError('Provide a name for your menu.')
      setIsCreating(false)
      return
    }

    if (!descriptionRef.current.value) {
      setError('Provide a description for your menu.')
      setIsCreating(false)
      return
    }

    if (menuType === 'event' && !contactAddress.current.value) {
      setError('Provide an address for your event.')
      setIsCreating(false)
      return
    }

    let menu = {
      name: nameRef.current.value,
      description: descriptionRef.current.value,
      menuType: typeRef.current.getValue()[0].value,
      available: true,
    }

    if (typeRef.current?.getValue()[0]?.value === 'event') {
      menu.eventDetails = {
        contactName: contactName.current.value,
        contactEmail: contactEmail.current.value,
        contactPhone: contactPhone.current.value,
        eventLocation: contactAddress.current.value,
        eventDateTime: [], // TODO
      }
    }

    const res = await LocationClient.createMenu(selectedLocationId, menu, await getIdToken())
    // TODO - how do we know if it was successful?
    // TODO error catching
    if (res.id) {
      toast.success('Menu created.')
      history.push(`/${selectedLocationId}/menus/${res.id}`)
    } else {
      setError('An error has occurred. Try again.')
      setIsCreating(false)
    }
  }

  return (
    <div className="page">
      <div className="header">
        <FontAwesomeIcon icon={faBars} id="btn-mobile-nav" onClick={() => toggleNav()} className="margin-right" />
      </div>

      <Hero
        section="planner"
        title="Menus and events"
        subtitle="Organise and manage your table-service menus and events here."
      />

      <div className="body menus-page">
        <Link to={`/${selectedLocationId}/menus`}><FontAwesomeIcon icon={faArrowLeft} /> Back to menus</Link>
        <h1 className="title">New menu</h1>
        <span className="sub-title">Enter the details of the menu</span>
        <form className="new-product-form margin-top">
          <ErrorMessage error={error} />
          <label htmlFor="type">Type</label>
          <div className="multi-select">
            <Select
              isMulti={false}
              id="type"
              ref={typeRef}
              isDisabled={isCreating}
              options={getMenuTypes()}
              onChange={(e) => setMenuType(e.value)}
            />
          </div>

          <label htmlFor="fname">Name</label>
          <input type="text" id="fname" name="fname" ref={nameRef} disabled={isCreating} />

          <label htmlFor="fdescription">Description</label>
          <textarea id="fdescription" name="fdescription" ref={descriptionRef} disabled={isCreating} placeholder='For an event include as much detail as possible, including a refund policy, link to your terms and conditions and any restrictions.' />

          <p className="p--info">
            <b>Note</b><br />
            Products, availability and other details can be set after the menu has been created.
          </p>

          {
            menuType === 'event'
              ?
              <>
                <h2>Customer contact details</h2>

                <p className="p--info">
                  <b>Note</b><br />
                  These fields will be visible to the users when they're placing their order.<br /><br />
                  Event dates can be set after the event has been created.
                </p>

                <label htmlFor='faddress'>Event address</label>
                <input type="text" id="faddress" name="faddress" ref={contactAddress} disabled={isCreating} />

                <label htmlFor='fcontactName'>Event contact name (optional)</label>
                <input type="text" id="fcontactName" name="fcontactName" ref={contactName} disabled={isCreating} />

                <label htmlFor='fcontactPhone'>Event contact phone number (optional)</label>
                <input type="text" id="fcontactPhone" name="fcontactPhone" ref={contactPhone} disabled={isCreating} />

                <label htmlFor='fcontactEmail'>Event contact email (optional)</label>
                <input type="text" id="fcontactEmail" name="fcontactEmail" ref={contactEmail} disabled={isCreating} />
              </>
              :
              null
          }

          {isCreating ? <Loading /> : <button onClick={createMenu}>Create menu</button>}
        </form>
      </div>
    </div>
  )
}

export default MenuNew
