import { useContext } from "react"
import EventDatePlanner from "./EventDatePlanner"
import { EventContext } from "./EventContext"

const EventDates = () => {
  const { event } = useContext(EventContext)

  return (
    <div className="event-details-dates event-details-section__wrapper">
      <EventDatePlanner menu={event} />
    </div>
  )
}

export default EventDates
